import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuMedicalDevices({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-medical-devices"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1375 4C9.60305 4 9.09464 4.14852 8.70437 4.41778C8.31645 4.68542 8 5.11143 8 5.64915V7H6V6H4V7H3.8705C2.83745 7 2 7.76428 2 8.70707V20H22V7H20V6H18V7H16V5.64915C16 5.11144 15.6836 4.68542 15.2956 4.41778C14.9054 4.14852 14.397 4 13.8625 4H10.1375ZM14.875 7V5.64915C14.875 5.58672 14.8389 5.46941 14.6568 5.34377C14.477 5.21974 14.1979 5.125 13.8625 5.125H10.1375C9.80211 5.125 9.52302 5.21974 9.34325 5.34377C9.16113 5.46942 9.125 5.58673 9.125 5.64915V7H14.875ZM12 18.5C14.7614 18.5 17 16.2614 17 13.5C17 10.7386 14.7614 8.5 12 8.5C9.23858 8.5 7 10.7386 7 13.5C7 16.2614 9.23858 18.5 12 18.5ZM13 14.5V17H11V14.5H8.5V12.5H11V10H13V12.5H15.5V14.5H13Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
