import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconLawnAndGarden({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-lawn-and-garden"
      className={className}
    >
      <path
        d="M14 48.8184C14 38.474 18.576 28.6595 26.4995 22.0093L42 9L57.5005 22.0093C65.4241 28.6595 70 38.474 70 48.8184V82H14V48.8184Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M31 98H52V141.5C52 147.299 47.299 152 41.5 152C35.701 152 31 147.299 31 141.5V98Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M52 98H31V111H52V98Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M42 98V52"
        stroke={iconColor ?? colors.SnapDarkBlue}
        strokeWidth="6"
      />
      <path
        d="M107 98H128V141.5C128 147.299 123.299 152 117.5 152C111.701 152 107 147.299 107 141.5V98Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M128 98H107V111H128V98Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M118 98V16"
        stroke={iconColor ?? colors.SnapDarkBlue}
        strokeWidth="6"
      />
      <path
        d="M91 16L91.0001 57C91.0001 65.2843 97.7158 72 106 72H119"
        stroke={iconColor ?? colors.SnapDarkBlue}
        strokeWidth="6"
      />
      <path
        d="M146 16V57C146 65.2843 139.284 72 131 72H118"
        stroke={iconColor ?? colors.SnapDarkBlue}
        strokeWidth="6"
      />
    </svg>
  );
}
