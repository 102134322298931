import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  forwardRef,
  useCallback,
} from 'react';
import cn from 'classnames';
import s from './button.module.scss';
import { colors } from '@snap/colors';
import { SpinnerRing } from '@ee-monorepo/shared/ui/spinner-ring';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    size?: 'normal' | 'compact';
    variant?: ButtonVariants;
    inverseMode?: boolean;
    fullWidth?: boolean;
    loading?: boolean;
    'data-testid'?: string;
  };

export type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'link';

const DEFAULT_PROPS: ButtonProps = {
  size: 'normal',
  variant: 'primary',
  inverseMode: false,
  loading: false,
};

export type ButtonRefType = HTMLButtonElement & HTMLAnchorElement;

export const Button = forwardRef<ButtonRefType, ButtonProps>(
  (
    {
      size,
      variant,
      inverseMode,
      className,
      fullWidth,
      children,
      type = 'button',
      loading,
      onClick,
      ...otherProps
    }: ButtonProps = DEFAULT_PROPS,
    ref
  ) => {
    const _variant = variant || 'primary';
    const Component = !otherProps.href && _variant !== 'link' ? 'button' : 'a';

    const _onClick = useCallback(
      (e: any) => {
        if (!loading && onClick) {
          onClick(e);
        }
      },
      [onClick, loading]
    );

    return (
      <Component
        ref={ref}
        type={Component === 'button' ? type : undefined}
        className={cn(
          {
            [cn(s.button, 'bodyHeavyFont')]: _variant !== 'link',
            [s.buttonVariantPrimary]: _variant !== 'link',
            [cn(s.buttonVariantLink, 'bodyHeavyFont')]: _variant === 'link',
            [s.buttonFullWidth]: fullWidth,
            [cn(s.buttonSizeCompact, 'secondaryHeavyFont')]: size === 'compact',
            [s.buttonVariantSecondary]: _variant === 'secondary',
            [s.buttonVariantTertiary]: _variant === 'tertiary',
            [s.buttonInversePrimary]: inverseMode,
            [s.buttonInverseSecondary]: inverseMode && _variant === 'secondary',
            [s.buttonInverseTertiary]: inverseMode && _variant === 'tertiary',
            [cn(s.buttonInverseLink, 'bodyHeavyFont')]:
              inverseMode && _variant === 'link',
            [s.buttonVariantSecondaryLoading]:
              _variant === 'secondary' && loading,
            [s.buttonVariantTertiaryLoading]:
              _variant === 'tertiary' && loading,
            [s.buttonInversePrimaryLoading]: inverseMode && loading,
            [s.buttonInverseSecondaryLoading]:
              inverseMode && _variant === 'secondary' && loading,
            [s.buttonInverseTertiaryLoading]:
              inverseMode && _variant === 'tertiary' && loading,
          },
          className
        )}
        {...otherProps}
        onClick={_onClick}
      >
        <span
          className={cn('relative justify-center items-center', {
            flex: Component === 'button',
          })}
        >
          <span
            className={cn('w-full flex items-center justify-center', {
              invisible: loading,
            })}
          >
            {children}
          </span>
          {loading && (
            <span className="absolute flex">
              <SpinnerRing
                color={
                  variant === 'tertiary' ? colors.SnapGreen : colors.SnapWhite
                }
              />
            </span>
          )}
        </span>
        <span className={s.outline}></span>
      </Component>
    );
  }
);
