import { createContext, useContext } from 'react';
import { Industry } from '@ee-monorepo/shared/utilities/types';

export const PillarPageIndustryContext = createContext<Industry>('ALL');

export const usePillarPageIndustryContext = () => {
  const industry = useContext(PillarPageIndustryContext);
  return {
    industry,
  };
};
