import { createContext, useContext, useMemo } from 'react';
import { ConditionalsArgs, FlowModel } from './types';

export interface FlowContextType {
  model?: FlowModel;
  launch: (args: ConditionalsArgs) => void;
  back: (stepName: string) => void;
  next: (stepName: string, args: ConditionalsArgs) => void;
}

export const FlowContext = createContext<FlowContextType>({
  launch: () => {},
  back: () => {},
  next: () => {},
});

export function useFlowContext(stepName?: string) {
  const stepNameFromContext = useContext(FlowStepContext);
  const _stepName = stepName || stepNameFromContext;
  const flow = useContext(FlowContext);
  return useMemo(
    () => ({
      model: flow.model,
      /**
       * goes to the first step of the flow
       * @returns
       */
      launch: (args: ConditionalsArgs = {}) => flow.launch(args),
      /**
       * goes back
       * @returns
       */
      back: () => flow.back(_stepName),
      /**
       * goes next
       * @returns
       */
      next: (args: ConditionalsArgs = {}) => flow.next(_stepName, args),
    }),
    [_stepName, flow]
  );
}

export const FlowStepContext = createContext<string>('');
