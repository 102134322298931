import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconTiresRims({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-tires-rims"
      className={className}
    >
      <path
        d="M80 89C84.9706 89 89 84.9706 89 80C89 75.0294 84.9706 71 80 71C75.0294 71 71 75.0294 71 80C71 84.9706 75.0294 89 80 89Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160 80C160 124.183 124.183 160 80 160C35.8172 160 0 124.183 0 80C0 35.8172 35.8172 0 80 0C124.183 0 160 35.8172 160 80ZM140 80C140 113.137 113.137 140 80 140C46.8629 140 20 113.137 20 80C20 46.8629 46.8629 20 80 20C113.137 20 140 46.8629 140 80Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140 80C140 113.137 113.137 140 80 140C46.8629 140 20 113.137 20 80C20 46.8629 46.8629 20 80 20C113.137 20 140 46.8629 140 80ZM80 134C87.6074 134 94.8475 132.427 101.413 129.588L90.7948 111.198C87.3389 105.231 79.7017 103.188 73.728 106.637C72.0652 107.597 70.7064 108.882 69.6854 110.364L58.5867 129.588C65.1519 132.427 72.3923 134 80 134ZM101.184 105.192L111.828 123.628C123.619 115.011 131.786 101.73 133.613 86.4998L111.02 86.4998C109.225 86.6427 107.434 87.1773 105.771 88.1373C99.7993 91.5851 97.7486 99.2161 101.184 105.192ZM111.021 74.4998L133.723 74.4998C132.158 59.0274 124.061 45.4843 112.232 36.6707L101.19 55.7975C97.7463 61.7746 99.7959 69.4126 105.771 72.8623C107.434 73.8223 109.226 74.3569 111.021 74.4998ZM90.314 50.6347L101.873 30.6135C95.1867 27.6477 87.7858 26 80 26C72.2139 26 64.8127 27.6479 58.1261 30.6139L69.2078 49.808C72.6653 55.7705 80.2993 57.8101 86.271 54.3623C87.9338 53.4023 89.293 52.1174 90.314 50.6347ZM26.2767 74.4998C27.8419 59.0277 35.9386 45.4849 47.7669 36.6712L58.8119 55.8018C62.2517 61.7783 60.2017 69.4134 54.228 72.8624C52.5653 73.8223 50.7738 74.3568 48.9794 74.4998H26.2767ZM48.1716 123.627C36.3807 115.011 28.2143 101.73 26.3872 86.4998L48.9792 86.4998C50.7737 86.6428 52.5653 87.1773 54.228 88.1373C60.2067 91.5891 62.2551 99.234 58.8033 105.213L48.1716 123.627ZM96 80C96 88.8366 88.8366 96 80 96C71.1634 96 64 88.8366 64 80C64 71.1634 71.1634 64 80 64C88.8366 64 96 71.1634 96 80Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
    </svg>
  );
}
