import MuiButtonGroup from '@mui/material/ButtonGroup';
import { colors } from '@snap/colors';
import { styled } from '@mui/material';

export const ButtonGroup = styled(MuiButtonGroup)(({ theme }) => ({
  boxShadow: 'none',
  '& svg': {
    color: colors.SnapGrey400,
  },
  '& .MuiButtonBase-root': {
    borderColor: colors.SnapGrey400,
  },
  '& .MuiButtonBase-root:first-child': {
    borderBottomColor: 'transparent',
  },
}));
