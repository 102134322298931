import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconElectronics({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-electronics"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 22.0005C3 15.3731 8.37259 10.0005 15 10.0005L145 10.0005C151.627 10.0005 157 15.3731 157 22.0005V108C157 114.628 151.627 120 145 120H100V138H116C122.627 138 128 143.373 128 150H32C32 143.373 37.3726 138 44 138H60V120H15C8.37259 120 3 114.628 3 108L3 22.0005ZM15 108L15 22.0005L145 22.0005V108L15 108Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 108.001L15 22.001L145 22.001V108.001L15 108.001ZM25 40.001H135V44.001H25V40.001ZM25 56.001H135V60.001H25V56.001ZM25 72.001H81V76.001H25V72.001Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
    </svg>
  );
}
