import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuCarAudio({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-car-audio"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7394 2C19.1692 2 20.4004 3.0091 20.681 4.41112L21.4804 8.4043H22.8525C23.3705 8.4043 23.8027 8.79974 23.8486 9.31562L23.9031 9.92755C23.9552 10.5125 23.4943 11.0162 22.907 11.0162H22.0001V21C22.0001 21.5523 21.5523 22 21.0001 22H20.0001C19.4478 22 19.0001 21.5523 19.0001 21V19H5.00005V21C5.00005 21.5523 4.55234 22 4.00005 22H3.00005C2.44777 22 2.00005 21.5523 2.00005 21V11.0162H1.0931C0.505808 11.0162 0.0449568 10.5125 0.0970354 9.92755L0.151514 9.31562C0.197441 8.79974 0.629654 8.4043 1.14757 8.4043H2.50247L3.27174 4.42991C3.54482 3.01905 4.78004 2 6.21708 2H17.7394ZM17.0321 5.48027L16.5064 4.94531L15.4365 5.99664L15.9622 6.5316C16.9296 7.51612 17.5371 8.90318 17.5371 10.4483C17.5371 11.9935 16.9296 13.3806 15.9622 14.3651L15.4365 14.9L16.5064 15.9514L17.0321 15.4164C18.272 14.1546 19.0371 12.3912 19.0371 10.4483C19.0371 8.50546 18.272 6.74211 17.0321 5.48027ZM14.0754 6.98228L13.5942 6.40698L12.4436 7.36933L12.9248 7.94463C13.4992 8.63142 13.8401 9.50175 13.8401 10.4485C13.8401 11.3953 13.4992 12.2657 12.9248 12.9525L12.4436 13.5278L13.5942 14.4901L14.0754 13.9148C14.8642 12.9717 15.3401 11.7645 15.3401 10.4485C15.3401 9.13262 14.8642 7.92538 14.0754 6.98228ZM10.7085 15.4941L7.1758 12.4981H5.00024V8.39832H7.17585L10.7085 5.40234V15.4941Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
