import { NextRouter } from 'next/router';

export const addParam = (
  name: string,
  value: string | boolean | number,
  router: NextRouter,
  clearOtherParams: boolean,
  givenPath: string
) => {
  const { [name]: param, ...rest } = router.query;
  let newQuery;
  const otherParams = clearOtherParams ? {} : rest;
  if (!param) {
    newQuery = { ...otherParams, [name]: value };
  } else {
    if (param === encodeURIComponent(value)) return;
    newQuery = {
      ...otherParams,
      [name]: param + `,${value}`,
    };
  }
  router.push({
    pathname: givenPath,
    query: newQuery,
  });
};

export const deselectParam = (
  name: string,
  value: string | boolean | number,
  router: NextRouter,
  clearOtherParams: boolean,
  givenPath: string
) => {
  const { [name]: param, ...rest } = router.query;
  const otherParams = clearOtherParams ? {} : rest;
  const newParams = typeof param === 'string' ? param.split(',') : param;
  const index = newParams?.findIndex((currentParam) => currentParam === value);
  newParams?.splice(index as number, 1);
  const newQuery =
    newParams.length > 0
      ? { ...otherParams, [name]: newParams?.join(',') }
      : { ...otherParams };
  router.replace({
    pathname: givenPath,
    query: newQuery,
  });
};

export const clearQuery = (
  queryToClear: string,
  router: NextRouter,
  clearOtherParams: boolean,
  givenPath: string
) => {
  const routerQuery = Object.assign({}, null, router.query);
  const params = new URLSearchParams(routerQuery);
  params.delete(queryToClear);
  const queryString = clearOtherParams ? null : params.toString();
  const path = givenPath + `${queryString ? `?${queryString}` : ''}`;
  router.push(path);
};
