import { useInView } from 'react-intersection-observer';

export const useInfiniteScroll = (
  condition: boolean,
  callback: () => Promise<void>
) => {
  const [lastEntity, inView, entry] = useInView({
    triggerOnce: true,
    onChange: async (inView) => {
      if (inView) {
        if (condition) await callback();
      }
    },
  });

  return { lastEntity };
};
