import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconInStore({ iconColor, size = 'lg', className }: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-in-store"
    >
      <path
        d="M84 56V48H12V56H16V80H56V56H72V80H80V56H84ZM48 72H24V56H48V72Z"
        fill={iconColor ?? colors.SnapBlue}
      />
      <path d="M16 16H80V24H16V16Z" fill={iconColor ?? colors.SnapDarkBlue} />
      <path
        d="M84 56V48L80 28H16L12 48V56H84Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
