import { useFetchWithErrorTracking } from './ErrorTrackingProvider';
import { PrismicDocument } from '@prismicio/types';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

export const pageSize = 10;

export function useDocsByUid(
  queryType: string,
  documentType: string,
  uid?: string,
  uids?: string[]
) {
  const [results, setResults] = useState<{
    status: string;
    docs: PrismicDocument[];
  }>(null);
  const router = useRouter();
  const { isReady } = router;
  const [isLoading, setIsLoading] = useState(true);
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();

  const fetchDocuments = useCallback(async () => {
    if (!uid && !uids) {
      return { status: 'error', docs: [] };
    }
    let url = `/api/getByUid?documentType=${documentType}&queryType=${queryType}`;
    if (uid) url += `&uid=${uid}`;
    if (uids) url += `&uids=${encodeURIComponent(JSON.stringify(uids))}`;

    const response = await fetchWithErrorTracking(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const docs = await response.json();
    if (response.status !== 200 || docs.error) {
      return { status: 'error', docs: [] };
    } else {
      return { status: 'success', docs };
    }
  }, [fetchWithErrorTracking, documentType, queryType, uid, uids]);

  useEffect(() => {
    if (isReady) {
      setIsLoading(true);
    }
  }, [isReady]);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const queryResult = await fetchDocuments();
        setIsLoading(false);
        setResults(queryResult);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    };

    fetchPageData();
  }, [fetchDocuments, setIsLoading, setResults]);

  return { results, isLoading };
}
