import { Button } from '@ee-monorepo/shared/ui/button';
import { FieldControl } from '@ee-monorepo/shared/ui/field-control';
import { TextField } from '@ee-monorepo/shared/ui/text-field';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import { useForm } from 'react-hook-form';
import { ChangeEmailFormSchemaData, formSchema } from './schema';
import EmailValidation from './email-validation-dialog';
import { useVerifyEmailCommand } from '../../commands/useVerifyEmailCommand';
import { useEffect, useState } from 'react';
import { useAppMessage } from '@ee-monorepo/shared/ui/app-message';
import {
  useFormFieldsKeyPress,
  useHandHeld,
} from '@ee-monorepo/shared/utilities/hooks';
import router from 'next/router';
import { useCreateCodeCommand } from '../../commands/useCreateCodeCommand';
import { useAuth } from '../auth-provider/auth-provider';
import { useFlowContext } from '@ee-monorepo/shared/context/flows';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { ErrorType } from '../../commands/types';

export const ChangeEmailForm = () => {
  const { user, setUserData } = useAuth();
  const createCode = useCreateCodeCommand();
  const t = useTranslations('account_takeover_form');

  const { showErrorMessage, closeMessage } = useAppMessage();
  const isMobile = useHandHeld();
  const [pageLoading, setPageLoading] = useState(false);
  const [isEmailVerificationOpen, setIsEmailVerificationOpen] = useState(false);
  const [apiErrorOnSubmit, setApiErrorOnSubmit] = useState(false);
  const verifyEmail = useVerifyEmailCommand();
  const { next } = useFlowContext();

  const onKeyPressed = () => {
    setApiErrorOnSubmit(false);
  };
  const { formRef } = useFormFieldsKeyPress(onKeyPressed);

  const [emailData, setEmailData] = useState<ChangeEmailFormSchemaData>({
    email: '',
  });
  const { tracking } = useTrackingContext();

  const handleIncorrectDataError = () => {
    setApiErrorOnSubmit(true);
    showErrorMessage({
      message: t('invalidEmailError'),
      showLabel: false,
      autoHideDuration: 30000,
      customStyle: { top: '2rem' },
      customAlertStyle: { width: isMobile ? '382px' : '528px', height: '48px' },
    });
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<any>({
    defaultValues: {
      email: user.emailId || '',
    },
    mode: 'all',
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    closeMessage();
  }, [watch('email')]);

  const createVerificationCode = async (data: ChangeEmailFormSchemaData) => {
    setUserData({ ...user, emailId: data.email });
    const response = await createCode.execute({
      token: user.token,
      value: data.email,
      optin: false,
    });

    if (response.success) {
      setApiErrorOnSubmit(false);
      next({ valid: true });
    } else if (response.errors?.includes(ErrorType.INVALID_DATA)) {
      handleIncorrectDataError();
    } else {
      //all other errors TOKEN_INVALID, APPLICATION_ERROR, CONNECTIVITY_ISSUE redirecting to the error page
      closeMessage();
      next({ valid: false });
    }
    router.events.on('routeChangeStart', () => setPageLoading(true));
    router.events.on('routeChangeComplete', () => setPageLoading(false));
  };

  const onSubmitHandler = async (data: ChangeEmailFormSchemaData) => {
    tracking?.logClickEvent({
      click_type: 'cta',
      placement: 'body',
      click_value: 'change',
    });
    const emailValidationResponse = await handleVerifyEmail({
      email: data.email,
    });
    if (emailValidationResponse?.success) {
      if (
        !emailValidationResponse.data?.data?.serviceEnabled ||
        emailValidationResponse.data?.data?.validEmail
      ) {
        await createVerificationCode(data);
      } else {
        setEmailData(data);
        openModal();
      }
    } else {
      //FIXME: specific error handling, after discuss with PO
      // redirecting to error page like other endpoints
      next({ valid: false });
    }
  };

  const handleVerifyEmail = async (data: ChangeEmailFormSchemaData) => {
    return await verifyEmail.execute({
      email: data.email,
    });
  };

  const openModal = () => {
    setIsEmailVerificationOpen(true);
    tracking?.logContentViewedEvent({
      content_name: 'email validation modal',
      content_value: 'email validation failed',
    });
  };

  const closeModal = () => {
    setIsEmailVerificationOpen(false);
  };

  const proceedHandler = async () => {
    if (emailData.email) {
      tracking?.logClickEvent({
        click_type: 'link',
        placement: 'email validation modal',
        click_value: 'proceed without changes',
      });
      await createVerificationCode({ email: emailData.email });
    }
  };

  const changeEmail = async (data: ChangeEmailFormSchemaData) => {
    tracking?.logClickEvent({
      click_type: 'cta',
      placement: 'email validation modal',
      click_value: 'save changes',
    });
    if (!data.email) {
      data = emailData;
    }
    await createVerificationCode(data);
    closeModal();
  };

  return (
    <div>
      <h3>{t('changeEmailHeading')}</h3>
      <p>{t('changeEmailText')}</p>
      <form
        className="mt-10"
        ref={formRef}
        data-testid="change-email-form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div>
          <FieldControl
            fieldName="email"
            control={control}
            render={(field, invalid) => (
              <TextField
                type="text"
                {...field}
                fullWidth
                label={t('emailLabel')}
                inputProps={{
                  pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
                }}
                error={invalid}
                data-testid="emailInput"
              />
            )}
          />
        </div>
        <div className="flex flex-col md:flex-row justify-end mt-10">
          <Button
            loading={createCode.executing || pageLoading}
            type="submit"
            disabled={
              !isValid ||
              createCode.executing ||
              pageLoading ||
              apiErrorOnSubmit
            }
            data-testid="change-email-submit"
          >
            {t('change')}
          </Button>
        </div>
      </form>
      <div>
        {isEmailVerificationOpen && (
          <EmailValidation
            onSubmit={changeEmail}
            onClose={closeModal}
            proceed={proceedHandler}
          />
        )}
      </div>
    </div>
  );
};
