import {
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps,
  styled,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  IconEyeOpen as Visibility,
  IconEyeClose as VisibilityOff,
} from '@ee-monorepo/shared/ui/icons';
import ErrorIcon from '@mui/icons-material/Error';
import { forwardRef } from 'react';
import { useState, FocusEvent } from 'react';
import { colors } from '@snap/colors';

export const CustomTextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiFilledInput-input': {
    padding: '27px 16px 10px',
    boxShadow: `0 0 0px 50px ${colors.SnapGrey100} inset !important`,
    WebkitBoxShadow: `0 0 0 50px ${colors.SnapGrey100} inset !important`,
  },
  '& .MuiFormLabel-root.MuiInputLabel-filled': {
    transform: 'translate(16px, 17px) scale(1)',
  },
  '& .MuiFormLabel-root.MuiInputLabel-filled.MuiInputLabel-shrink': {
    transform: 'translate(16px, 8px) scale(.75)',
  },
  '& .MuiInputBase-adornedEnd': {
    paddingRight: '16px',
  },
}));

export const TextField = forwardRef((props: TextFieldProps, ref) => {
  /* By default, hide value while prefilling if type is secured(partially visible) */
  const noSecuredValue = props?.inputProps?.['partialValueToggle']
    ? props?.value === ''
    : true;
  const [showValue, setShowValue] = useState(noSecuredValue);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    !showValue &&
      props?.inputProps?.['toggleSecuredValue']?.toggleSecuredValue();
    if (
      props?.inputProps?.['isPassword'] ||
      props?.inputProps?.['partialValueToggle']
    ) {
      setShowValue(true);
    }
    props?.onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    showValue &&
      props?.inputProps?.['toggleSecuredValue']?.toggleSecuredValue();
    if (
      props?.inputProps?.['isPassword'] ||
      props?.inputProps?.['partialValueToggle']
    ) {
      setShowValue(false);
    }
    props?.onBlur?.(e);
  };

  const handleClickShowValue = () => {
    props?.inputProps?.['toggleSecuredValue']?.toggleSecuredValue();
    setShowValue((showValue) => !showValue);
  };
  return (
    <CustomTextField
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      type={
        showValue || props?.inputProps?.['partialValueToggle']
          ? props.type || 'text'
          : 'password'
      }
      InputProps={{
        ...props?.InputProps,
        endAdornment: props.error ? (
          <>
            {/* consider other adornment coming from outer props */}
            <InputAdornment position="end">
              <ErrorIcon className="error-icon" />
            </InputAdornment>
            {props?.InputProps?.endAdornment}
          </>
        ) : props?.inputProps?.['isPassword'] ||
          props?.inputProps?.['partialValueToggle'] ? (
          <InputAdornment position="end">
            <IconButton
              className="!pr-0 !mr-0"
              aria-label="toggle password visibility"
              onClick={handleClickShowValue}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showValue ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ) : (
          props?.InputProps?.endAdornment
        ),
      }}
    />
  );
});
