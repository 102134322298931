import React from 'react';
import { Element } from '@prismicio/helpers';
import s from './full-width-block-serializer.module.scss';
import { wordify } from '@ee-monorepo/shared/utilities/functions';
import { richTextSerializer } from './rich-text-serializer';
import { JSXFunctionSerializer } from '@prismicio/react';
import { RTTextNodeBase } from '@prismicio/types';

const propsWithUniqueKey = (props = {}, key: string) => {
  return { ...props, key };
};

// Default rendering for prismic rich text field
export const fullWidthBlockSerializer: JSXFunctionSerializer = function (
  type,
  element,
  content,
  children,
  key
) {
  let props = {};
  const parsedElement = element as RTTextNodeBase;

  switch (type) {
    case Element.heading1: {
      props = {
        id: wordify(parsedElement.text),
        className: 'headingL md:headingXL',
      };
      return React.createElement(
        'h1',
        propsWithUniqueKey(props, key),
        children
      );
    }
    case Element.heading2: {
      props = { id: wordify(parsedElement.text), className: 'headingL' };
      return React.createElement(
        'h2',
        propsWithUniqueKey(props, key),
        children
      );
    }
    case Element.heading3: {
      props = { id: wordify(parsedElement.text), className: 'headingMHeavy' };
      return React.createElement(
        'h3',
        propsWithUniqueKey(props, key),
        children
      );
    }

    case Element.heading4: {
      props = {
        id: wordify(parsedElement.text),
        className: 'headingMHeavy md:headingL',
      };
      return React.createElement(
        'h4',
        propsWithUniqueKey(props, key),
        children
      );
    }
    case Element.heading5: {
      props = {
        id: wordify(parsedElement.text),
        className: 'bodyFont md:headingM',
      };
      return React.createElement(
        'h5',
        propsWithUniqueKey(props, key),
        children
      );
    }
    case Element.heading6: {
      props = { id: wordify(parsedElement.text), className: 'headingS' };
      return React.createElement(
        'h6',
        propsWithUniqueKey(props, key),
        children
      );
    }
    case Element.paragraph: {
      props = { id: wordify(parsedElement.text), className: 'bodyFont' };
      return React.createElement('p', propsWithUniqueKey(props, key), children);
    }
    // Return richTextSerializer as default
    default:
      return richTextSerializer(type, element, content, children, key);
  }
};
