import { useCallback } from 'react';
import { useVerifyToken } from '../api/account-takeover/useVerifyToken';
import { ErrorType } from './types';
import { createCommandHook } from '@ee-monorepo/shared/utilities/hooks';

export const useVerifyTokenCommand = createCommandHook(() => {
  const { verifyToken, verifyTokenLoading } = useVerifyToken();

  const execute = useCallback(
    async (params: { token: string; }) => {
      const response = await verifyToken(params.token);
      const errors = !response?.success ? [ErrorType.SMS_OTP_CHECK_ERROR] : [];

      return {
        errors,
        data: {
          ...response?.data
        },
      };
    },
    [verifyToken]
  );

  return {
    execute,
    executing: verifyTokenLoading,
  };
});
