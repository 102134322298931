// merchant list
export const merchantList = [
  490247693, 490255744, 490246158, 490260863, 490295596, 424171054, 490257894,
  490272254, 490286697, 490278987, 460294238, 490290126, 490282133, 490253542,
  490297077, 490297380, 490295513, 1010691362, 1036382956, 490234294,
  1037918530, 490242280, 490240237, 490285786, 490232852, 490260116, 490250084,
  490300704, 490253674, 490250084, 490278017, 1000416928, 490298860, 490258795,
  490290517, 490248720, 490236126, 490272712, 490275019, 490298786, 490298058,
  490299235, 490255398, 490278096, 490272729, 490254366, 490291789, 490252556,
  490236851, 1007157672, 490270386, 490273726, 490294602, 490268403, 490285584,
  490298362, 1036845122, 490277333, 490297252, 490264181, 490298502, 490266236,
  490263142, 490242694, 490290319, 56402, 490266496, 1007039154, 490291887,
  490231759, 1004268135, 1034948575, 490274879, 1015919782, 490232605,
  490270421, 490271646, 490278247, 490296578, 490299058, 490272316, 490264736,
  490258543, 490297672, 490296141, 490232467, 490277439, 490298277, 490282141,
  490298303, 490272375, 490278806, 490298514, 1011597810, 490293124, 490283968,
  490287309, 490291560, 490258805, 490298727, 490290088, 490247202, 490291098,
  490238236,
];

export function validateMerchantId(merchantId) {
  const mid = parseInt(merchantId);
  if (mid && merchantList.includes(mid)) {
    return true;
  } else if (mid && !merchantList.includes(mid)) {
    return false;
  } else {
    return null;
  }
}
