import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconOnline({ iconColor, size = 'lg', className }: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 96 96"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-online"
    >
      <path
        d="M32 72C27.6 72 24.04 75.6 24.04 80C24.04 84.4 27.6 88 32 88C36.4 88 40 84.4 40 80C40 75.6 36.4 72 32 72ZM72 72C67.6 72 64.04 75.6 64.04 80C64.04 84.4 67.6 88 72 88C76.4 88 80 84.4 80 80C80 75.6 76.4 72 72 72Z"
        fill={iconColor ?? colors.SnapBlue}
      />
      <path
        d="M8 8V16H16L30.4 46.36L25 56.16C24.36 57.28 24 58.6 24 60C24 64.4 27.6 68 32 68H80V60H33.68C33.12 60 32.68 59.56 32.68 59L32.8 58.52L36.4 52H66.2C69.2 52 71.84 50.36 73.2 47.88L87.52 21.92C87.84 21.36 88 20.68 88 20C88 17.8 86.2 16 84 16H24.84L21.08 8H8Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
