/**
 * Utility to create namespaced translations
 *
 * @param namespace The namespace module components will use to render the message, notice snake_case, e.g "feature_merchant_location_input.no_results"
 * @param resolve Callback to resolve translation json files
 * @returns getTranslations('en-US' | 'es-US')
 */
export function createTranslations<T>(
  namespace: string,
  resolve: (locale: string) => T
) {
  return function getTranslations(locale: string) {
    return { [namespace]: { ...resolve(locale) } };
  };
}
