import { useTranslations } from 'next-intl';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ImageWrapper } from '@ee-monorepo/prismic/shared/ui';

export const UpdateSuccessPage = () => {
  const t = useTranslations('account_takeover_form');

  return (
    <div>
      <div className="flex justify-center mt-2 mb-4">
        <ImageWrapper
          src="/assets/icons/account-takeover/thumbs-up.svg"
          alt={t('success_icon_alt')}
          width={160}
          height={160}
        />
      </div>

      <h3>{t('successHeading')}</h3>
      <p className="mt-3">{t('successContent')}</p>
    </div>
  );
};
