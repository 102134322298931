import { useContext, useMemo } from 'react';
import { useExperiments } from './useExperiments';
import { ExperimentsContext } from './experiments-context';

export interface FeatureFlags {
  // where null is used when useUnResolvedValue is true and no treatment ready yet
  [experimentName: string]: string | null;
}

interface UseFeatureFlagsOptions {
  useUnResolvedValue?: boolean; // return null for each flag if value not ready from split
}

export const useFeatureFlags = (options: UseFeatureFlagsOptions = {}) => {
  const { activeExperiments } = useContext(ExperimentsContext);
  const { experiments } = useExperiments(activeExperiments);
  const { useUnResolvedValue } = options;

  const featureFlags: FeatureFlags = useMemo(() => {
    if (!activeExperiments || activeExperiments.length === 0) {
      return {};
    }
    const experimentsKeys = Object.keys(experiments);

    // check if treatments not loaded yet
    if (
      !experimentsKeys.length ||
      experimentsKeys.some((experiment) => !experiments[experiment].treatment)
    ) {
      if (useUnResolvedValue) {
        // return null if values not resolved yet
        return activeExperiments.reduce(
          (prev, curr) => ({
            ...prev,
            [curr]: null,
          }),
          {}
        );
      }
      return null;
    }
    // put feature flags in object with its assigned treatment when its loaded
    return experimentsKeys.reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: getTreatmentOrConfig(experiments[curr]),
      };
    }, {});
  }, [activeExperiments, experiments, useUnResolvedValue]);

  return { featureFlags };
};

const getTreatmentOrConfig = (
  experimentTreatment: SplitIO.TreatmentWithConfig
) => {
  let treatment = experimentTreatment.treatment;
  if (experimentTreatment.config) {
    try {
      treatment = JSON.parse(experimentTreatment.config);
    } catch (e) {
      console.error(e);
    }
  }
  return treatment;
};
