import {
  getCookie,
  storeCookie,
} from '@ee-monorepo/shared/utilities/functions';
import {
  createUniqueId,
  SESSION_ID_KEY,
  USER_ID_KEY,
} from './create-unique-id';

export const TWO_YEARS = 6.307e10;

/**
 * Gets userId from cookie if available, sets localstorage
 * otherwise creates new value and sets both cookie and localstorage
 * @returns
 */
export function getUserId() {
  if (typeof window === 'undefined') {
    // if nodejs runtime
    return '';
  }
  const userIdInCookie = getCookie(USER_ID_KEY);
  const userIdInLocalStorage = localStorage.getItem(USER_ID_KEY);

  const userId = userIdInCookie || userIdInLocalStorage || createUniqueId();
  storeCookie(USER_ID_KEY, userId, TWO_YEARS, true);
  localStorage.setItem(USER_ID_KEY, userId);

  return userId;
}

/**
 * Gets session id from sessionStorage
 * @returns
 */
export function getSessionId() {
  if (typeof window === 'undefined') {
    // if nodejs runtime
    return '';
  }
  const sessionIdInSession = sessionStorage.getItem(SESSION_ID_KEY);
  const sessionId = sessionIdInSession || createUniqueId();
  storeCookie(SESSION_ID_KEY, sessionId, TWO_YEARS, true); // for analytics (??)
  sessionStorage.setItem(SESSION_ID_KEY, sessionId);

  return sessionId;
}
