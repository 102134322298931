import { ReactNode } from 'react';
import { TranslationValues, useTranslations } from 'next-intl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { SxProps } from '@mui/system';
import {
  Control,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  useController,
} from 'react-hook-form';

export interface FieldControlProps {
  fieldName: `${string}` | `${string}.${string}` | `${string}.${number}`;
  control: Control<any, any>;
  errorTranslationValues?: TranslationValues;
  externalError?: boolean;
  sx?: SxProps;
  render(
    field: ControllerRenderProps<FieldValues, FieldPath<FieldValues>>,
    invalid: boolean,
    error: string
  ): ReactNode;
}
export function FieldControl({
  fieldName,
  control,
  errorTranslationValues,
  render,
  externalError,
  sx = {},
}: FieldControlProps) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name: fieldName, control });
  const t = useTranslations();
  const errorMessage = invalid ? t(error?.message, errorTranslationValues) : '';
  return (
    <FormControl
      className="mb-5"
      error={!!error || externalError}
      fullWidth
      sx={sx}
    >
      {render(field, invalid, errorMessage as string)}
      {error ? <FormHelperText>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}
