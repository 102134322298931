import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconCollisionRepair({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-collision-repair"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.0902 36.8633L113.134 46.4259L117.137 41.555C118.89 39.4216 122.041 39.1134 124.175 40.8666C126.308 42.6198 126.616 45.7706 124.863 47.9041L116.073 58.6008L94.2547 49.1364L71.9597 78.0528C70.2736 80.2397 67.1339 80.6456 64.947 78.9595C62.7601 77.2734 62.3542 74.1337 64.0403 71.9468L91.0902 36.8633Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 134V23H10.7556C10.7556 23 22.4074 23 28.6815 29.216C34.9556 35.432 68.1185 77.168 68.1185 77.168L91.4222 70.064L104.867 79.832L116.519 72.728L121 81.608V131C121 132.657 119.657 134 118 134H93C93 147.807 82.031 159 68.5 159C54.969 159 44 147.807 44 134H0Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.948 27.2684C156.799 29.0326 156.086 31.1124 154.356 31.9138L136.342 40.2563C134.612 41.0577 132.519 40.2773 131.668 38.5131C130.817 36.749 131.53 34.6692 133.26 33.8678L151.274 25.5252C153.004 24.7238 155.097 25.5043 155.948 27.2684ZM130.313 52.8903C130.522 50.9715 132.246 49.6344 134.163 49.9038L156.379 53.026C158.296 53.2954 159.68 55.0693 159.471 56.9881C159.262 58.9069 157.538 60.2441 155.621 59.9747L133.405 56.8525C131.488 56.5831 130.103 54.8092 130.313 52.8903Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M133.461 9.45194C135.188 10.3661 135.894 12.4921 135.037 14.2006L129.582 25.0723C128.725 26.7807 126.63 27.4246 124.902 26.5105C123.175 25.5963 122.47 23.4703 123.327 21.7618L128.781 10.8902C129.638 9.1817 131.734 8.53779 133.461 9.45194Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.977 68.141C130.178 66.6625 132.374 66.4792 133.883 67.7317L143.789 75.953C145.297 77.2054 145.547 79.4193 144.347 80.8977C143.146 82.3762 140.949 82.5595 139.441 81.3071L129.535 73.0857C128.026 71.8333 127.776 69.6195 128.977 68.141Z"
        fill={iconColor ?? colors.SnapOrange}
      />
    </svg>
  );
}
