import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMap({ iconColor, className, size = 'xs' }: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-map"
    >
      <g id="maps/map_24px">
        <path
          id="icon/maps/map_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          fill={iconColor ?? colors.SnapGreen}
          d="M20.5 3L20.34 3.03L15 5.1L9 3L3.36 4.9C3.15 4.97 3 5.15 3 5.38V20.5C3 20.78 3.22 21 3.5 21L3.66 20.97L9 18.9L15 21L20.64 19.1C20.85 19.03 21 18.85 21 18.62V3.5C21 3.22 20.78 3 20.5 3ZM10 5.47L14 6.87V18.53L10 17.13V5.47ZM5 6.46L8 5.45V17.15L5 18.31V6.46ZM19 17.54L16 18.55V6.86L19 5.7V17.54Z"
        />
      </g>
    </svg>
  );
}
