import { useEffect, useRef } from 'react';

export function useFormFieldsKeyPress(callback) {
  const formRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      callback(event);
    };

    const form = formRef.current;

    if (form) {
      form.querySelectorAll('input, select').forEach((input: HTMLElement) => {
        input.addEventListener('keydown', handleKeyPress);
      });
    }

    return () => {
      if (form) {
        form.querySelectorAll('input, select').forEach((input: HTMLElement) => {
          input.removeEventListener('keydown', handleKeyPress);
        });
      }
    };
  }, [formRef, callback]);

  return { formRef };
}
