import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/**
 * Verifies if current page is dedicated to merchants
 * @returns boolean
 */
export function useIsPartnerPage() {
  const [isPartner, setIsPartner] = useState(false);
  const router = useRouter();
  useEffect(() => {
    const path = router.asPath;
    setIsPartner(
      path.startsWith('/partner') ||
        path === '/ecommerce-inquiry' ||
        path === '/merchant-apply/tell-us-about-you' ||
        path.includes('/merchant-apply/merchant-details')
    );
  }, [router]);
  return { isPartner };
}
