import { styled } from '@mui/material';
import { Checkbox as MuiCheckbox } from '@mui/material';
import cn from 'classnames';
import { forwardRef, useState } from 'react';
import {
  IconCheckboxChecked,
  IconCheckboxCheckedHovered,
  IconCheckboxUnCheckedHovered,
} from '@ee-monorepo/shared/ui/icons';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

export const CustomCheckbox = styled(MuiCheckbox as any)(({ theme }) => ({}));

export const Checkbox = forwardRef(({ ...props }: any, ref) => {
  const [hovered, setHovered] = useState(false);

  return (
    <CustomCheckbox
      {...props}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      ref={ref}
      inputProps={{
        ...props?.inputProps,
        className: cn(props?.inputProps?.className),
      }}
      className={cn('[&>svg]:-ml-3 left-[7px]', props.className)}
      icon={
        hovered ? (
          <IconCheckboxUnCheckedHovered />
        ) : (
          <CheckBoxOutlineBlankOutlinedIcon />
        )
      }
      checkedIcon={
        hovered ? <IconCheckboxCheckedHovered /> : <IconCheckboxChecked />
      }
    ></CustomCheckbox>
  );
});
