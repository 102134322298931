import { Button } from '@ee-monorepo/shared/ui/button';
import { FieldControl } from '@ee-monorepo/shared/ui/field-control';
import { FormattedTextField } from '@ee-monorepo/shared/ui/text-field';
import { FormControlLabel } from '@mui/material';
import { useTranslations } from 'next-intl';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox } from '@ee-monorepo/shared/ui/checkbox';
import styles from './change-phone.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChangePhoneFormSchemaData, formSchema } from './schema';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useAppMessage } from '@ee-monorepo/shared/ui/app-message';
import {
  useFormFieldsKeyPress,
  useHandHeld,
} from '@ee-monorepo/shared/utilities/hooks';
import { useAuth } from '../auth-provider/auth-provider';
import { useCreateCodeCommand } from '../../commands/useCreateCodeCommand';
import { useFlowContext } from '@ee-monorepo/shared/context/flows';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import router from 'next/router';
import { ErrorType } from '../../commands/types';

export const ChangePhoneForm = () => {
  const { user, setUserData } = useAuth();
  const createCode = useCreateCodeCommand();
  const t = useTranslations('account_takeover_form');
  const { showErrorMessage, closeMessage } = useAppMessage();
  const isMobile = useHandHeld();
  const [pageLoading, setPageLoading] = useState(false);
  const [apiErrorOnSubmit, setApiErrorOnSubmit] = useState(false);
  const { next } = useFlowContext();
  const { tracking } = useTrackingContext();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<any>({
    defaultValues: {
      phone: user.phoneNumber,
      communications: false,
    },
    mode: 'all',
    resolver: yupResolver(formSchema),
  });

  const handleIncorrectDataError = () => {
    setApiErrorOnSubmit(true);
    showErrorMessage({
      message: t('invalidPhoneError'),
      showLabel: false,
      autoHideDuration: 30000,
      customStyle: { top: '2rem' },
      customAlertStyle: { width: isMobile ? '382px' : '528px', height: '48px' },
    });
  };

  const onKeyPressed = () => {
    setApiErrorOnSubmit(false);
  };
  const { formRef } = useFormFieldsKeyPress(onKeyPressed);

  useEffect(() => {
    closeMessage();
  }, [watch('phone'), watch('communications')]);

  const onSubmitHandler = async (data: ChangePhoneFormSchemaData) => {
    tracking?.logClickEvent({
      click_type: 'cta',
      placement: 'body',
      click_value: 'change',
    });

    setUserData({ ...user, phoneNumber: data.phone });
    const response = await createCode.execute({
      token: user.token,
      value: data.phone,
      optin: data.communications,
    });
    if (response.success) {
      setApiErrorOnSubmit(false);
      next({ valid: true });
    } else if (response.errors?.includes(ErrorType.INVALID_DATA)) {
      //Invalid phone number
      handleIncorrectDataError();
    } else {
      //all other errors, redirecting to the error page
      closeMessage();
      next({ valid: false });
    }
    router.events.on('routeChangeStart', () => setPageLoading(true));
    router.events.on('routeChangeComplete', () => setPageLoading(false));
  };

  return (
    <>
      <h3>{t('phoneTitle')}</h3>
      <p className="my-2">{t('phoneSubtitle')}</p>
      <form
        className="mt-10"
        ref={formRef}
        data-testid="change-phone-form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="grid grid-cols-1">
          <div>
            <FieldControl
              fieldName="phone"
              control={control}
              render={(field, invalid) => (
                <FormattedTextField
                  {...field}
                  fullWidth
                  label={t('phoneLabel')}
                  error={invalid}
                  data-testid="phoneInput"
                  mask="(000) 000-0000"
                  maskedValue={false}
                  isPassword={true}
                />
              )}
            />
          </div>
        </div>
        <div className="flex-col w-full mt-6">
          <FormControlLabel
            className="snap-color-grey-500"
            control={
              <Controller
                name="communications"
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    className="checkboxScale ml-3 w-6 h-6 [&>svg]:w-6"
                    data-testid="commOptInCheckbox"
                    {...props}
                    checked={props.value}
                    onChange={(e: any) => {
                      props.onChange(e.target.checked);
                      tracking?.logClickEvent({
                        click_type: 'checkbox',
                        placement: 'body',
                        click_value: 'communications opt in',
                      });
                    }}
                  />
                )}
              />
            }
            label={
              <div className="bodyHeavyFont pb-1 mt-1 pl-2">
                {t('communications')}
              </div>
            }
          />
          <p className="ml-5 pl-4">
            {t('communicationsText')}{' '}
            <Link
              className={`${styles['terms-link']}`}
              href="https://snapfinance.com/legal/sms-terms-and-conditions"
              target="_blank"
            >
              {t('term')}
            </Link>
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-end mt-16">
          <Button
            disabled={
              !isValid ||
              createCode.executing ||
              pageLoading ||
              apiErrorOnSubmit
            }
            loading={createCode.executing || pageLoading}
            type="submit"
            data-testid="change-phone-submit"
          >
            {t('change')}
          </Button>
        </div>
      </form>
    </>
  );
};
