interface StoreLocatorQuery {
  zipCode: string;
  latitude: string;
  longitude: string;
  city: string;
  state: string;
  industry: string;
  search: string;
  merchantId: string;
  dbaName: string;
  pageNumber: string;
  zip: string;
  mapInteraction: string;
}

interface StoreLocatorQueryIncludes {
  industry: boolean;
  cityState: boolean;
  search: boolean;
  merchant: boolean;
  pageNumber: boolean;
  mapInteraction: boolean;
}

const DEFAULT_INCLUDES: Partial<StoreLocatorQueryIncludes> = {
  cityState: true,
  mapInteraction: true,
};

/**
 * Utility function to prepend zipCode, industry, coords or city/state
 * Also this might help to generate links, link industries links or pagination
 * @param query Current router query object
 * @param include Maps what params to add
 * @param appendQuery Append query
 * @returns
 */
export const getStoreLocatorQuery = (
  query: Partial<StoreLocatorQuery>,
  include: Partial<StoreLocatorQueryIncludes> = DEFAULT_INCLUDES,
  appendQuery: Partial<StoreLocatorQuery> = {}
) => {
  const _include = { ...DEFAULT_INCLUDES, ...include };
  let newQuery: Partial<StoreLocatorQuery> = {};
  const {
    zipCode,
    latitude,
    longitude,
    city,
    state,
    industry,
    search,
    merchantId,
    dbaName,
    pageNumber,
    zip,
    mapInteraction,
  } = query;
  const zipParam = zipCode || zip;
  if (zipParam) {
    newQuery = { ...newQuery, zipCode: zipParam };
  }
  if (latitude && longitude) {
    newQuery = { ...newQuery, latitude, longitude };
  }
  if (_include.mapInteraction && mapInteraction) {
    newQuery = { ...newQuery, latitude, longitude, mapInteraction };
  }
  if (_include.cityState && state && city) {
    newQuery = { ...newQuery, state, city };
  }
  if (_include.industry && industry) {
    newQuery = { ...newQuery, industry: industry.toUpperCase() };
  }
  if (_include.search && search) {
    newQuery = { ...newQuery, search };
  }
  if (_include.merchant && merchantId) {
    newQuery = { ...newQuery, merchantId };
  }
  if (_include.merchant && dbaName) {
    newQuery = { ...newQuery, dbaName };
  }
  if (_include.pageNumber && pageNumber) {
    newQuery = { ...newQuery, pageNumber };
  }

  const mergedQuery: Partial<StoreLocatorQuery> = {
    ...newQuery,
    ...appendQuery,
  };
  return `?${new URLSearchParams(mergedQuery).toString()}`;
};
