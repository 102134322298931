export const StatesDataList = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
    location: {
      latitude: 33.09727700814784,
      longitude: -86.75052325707948,
    },
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
    location: {
      latitude: 65.92520214274222,
      longitude: -151.72335925936588,
    },
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    location: {
      latitude: 34.33126026391204,
      longitude: -111.43295136863003,
    },
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    location: {
      latitude: 35.20082284397911,
      longitude: -92.41612583787338,
    },
  },
  {
    name: 'California',
    abbreviation: 'CA',
    location: {
      latitude: 37.18802964414307,
      longitude: -119.70414245255495,
    },
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    location: {
      latitude: 39.32595160415684,
      longitude: -105.59279426939193,
    },
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    location: {
      latitude: 41.63272313692454,
      longitude: -72.68614001525587,
    },
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
    location: {
      latitude: 39.11998848823623,
      longitude: -75.49981900552066,
    },
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    location: {
      latitude: 29.26852431186613,
      longitude: -82.10500495063651,
    },
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    location: {
      latitude: 32.62627158472465,
      longitude: -83.25947793752503,
    },
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
    location: {
      latitude: 20.687628180687582,
      longitude: -156.29974627415677,
    },
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
    location: {
      latitude: 44.70745249292253,
      longitude: -114.66606187390109,
    },
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    location: {
      latitude: 40.68322143966327,
      longitude: -89.12055738228575,
    },
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    location: {
      latitude: 40.14287401706895,
      longitude: -86.20063402230484,
    },
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    location: {
      latitude: 42.295866208931244,
      longitude: -93.50558309170239,
    },
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    location: {
      latitude: 38.68636150752582,
      longitude: -98.72223605682674,
    },
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    location: {
      latitude: 37.840807942218504,
      longitude: -85.33789187476971,
    },
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    location: {
      latitude: 31.79046120216333,
      longitude: -92.51056899512385,
    },
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
    location: {
      latitude: 45.449047640315,
      longitude: -69.19286537012451,
    },
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
    location: {
      latitude: 39.27940333055881,
      longitude: -76.61089027834126,
    },
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    location: {
      latitude: 42.39544500546256,
      longitude: -72.05767711837566,
    },
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    location: {
      latitude: 43.80658149017026,
      longitude: -84.712075248678,
    },
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
    location: {
      latitude: 46.85309693689172,
      longitude: -94.33902543228864,
    },
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
    location: {
      latitude: 33.224125121415284,
      longitude: -89.66509750662989,
    },
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
    location: {
      latitude: 38.595165541013216,
      longitude: -92.36602128076443,
    },
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    location: {
      latitude: 47.80727045365151,
      longitude: -108.99742182602152,
    },
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
    location: {
      latitude: 41.748194212770585,
      longitude: -99.7156194895533,
    },
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    location: {
      latitude: 40.06197607483577,
      longitude: -116.46130713666695,
    },
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    location: {
      latitude: 44.02871957259774,
      longitude: -71.48237419831801,
    },
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    location: {
      latitude: 39.85291695671614,
      longitude: -74.606976053447,
    },
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    location: {
      latitude: 34.56998986591303,
      longitude: -105.95524515304557,
    },
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    location: {
      latitude: 40.81338390631069,
      longitude: -73.32072513433586,
    },
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
    location: {
      latitude: 35.4114489157391,
      longitude: -79.37803572747579,
    },
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
    location: {
      latitude: 47.49775453833192,
      longitude: -100.43343571944793,
    },
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
    location: {
      latitude: 40.33112981176458,
      longitude: -82.69769164106015,
    },
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
    location: {
      latitude: 35.33179315196189,
      longitude: -96.98420774593016,
    },
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    location: {
      latitude: 44.090257537753274,
      longitude: -120.14533179568636,
    },
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    location: {
      latitude: 41.04997694823862,
      longitude: -77.88964873159335,
    },
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
    location: {
      latitude: 41.72063276637749,
      longitude: -71.607452534464,
    },
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
    location: {
      latitude: 34.06925280968488,
      longitude: -80.85838027751002,
    },
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
    location: {
      latitude: 44.73829892580872,
      longitude: -100.12790128386723,
    },
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    location: {
      latitude: 36.00777108538317,
      longitude: -85.99007372238596,
    },
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    location: {
      latitude: 31.999880826533698,
      longitude: -98.88573817350664,
    },
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    location: {
      latitude: 39.337334422597195,
      longitude: -111.58994152090952,
    },
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
    location: {
      latitude: 44.28157405412415,
      longitude: -72.65791285695602,
    },
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    location: {
      latitude: 37.72721359592096,
      longitude: -78.55427817868551,
    },
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
    location: {
      latitude: 47.198634563392005,
      longitude: -120.2848173216336,
    },
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
    location: {
      latitude: 38.80898336284372,
      longitude: -80.69745766813097,
    },
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
    location: {
      latitude: 45.10638077894791,
      longitude: -89.64199976158558,
    },
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
    location: {
      latitude: 42.96206637116876,
      longitude: -107.35087193107036,
    },
  },
  {
    name: 'District of Columbia',
    abbreviation: 'DC',
    location: {
      latitude: 38.89969878227208,
      longitude: -77.04721441060028,
    },
  },
];
