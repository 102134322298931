export const colors = {
  // Brand
  SnapGreen: '#1b844a',
  SnapBlue: '#3d5ccf',
  SnapDarkBlue: '#062d4e',
  SnapYellow: '#ffca4d',
  // Accent
  SnapOrange: '#dc7f4a',
  SnapLightGreen: '#67b25e',
  SnapLimeGreen: '#f0fff1',
  SnapMediumBlue: '#5fa4f9',
  SnapLightBlue: '#95d0e1',
  SnapLavender: '#cacde2',
  SnapSand: '#f4f2ea',
  //Neutrals
  SnapGrey500: '#353849',
  SnapGrey400: '#6c6d77',
  SnapGrey300: '#e6e6e6',
  SnapGrey200: '#ededf2',
  SnapGrey100: '#f4f6f8',
  SnapLimeWhite: '#f7fff8',
  SnapWhite: '#ffffff',
  // Special messaging
  SnapError: '#af0f0f',
  SnapLightError: '#fff4f2',
  SnapWarning: '#b16307',
  SnapLightWarning: '#fff7e1',
  SnapSuccess: '#1b844a',
  SnapLightSuccess: '#e8fbea',
  SnapNeutral: '#3d5ccf',
  SnapLightNeutral: '#eaf2ff',
};
