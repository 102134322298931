import cn from 'classnames';
import Image, { ImageProps } from 'next/image';
import { disablePrismicImgOptimization } from '@ee-monorepo/prismic/shared/utilities';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';

export interface ImageWrapperProps {
  shouldSetFullWidth?: boolean;
  shouldSetHeightToAuto?: boolean;
  enableVendorOptimization?: boolean; // keep vendor image optimization and image cropping
}

export function ImageWrapper(props: ImageProps & ImageWrapperProps) {
  const {
    src,
    className,
    shouldSetHeightToAuto = true,
    enableVendorOptimization = false,
    shouldSetFullWidth = true,
    alt,
    ...otherProps
  } = props;
  if (src) {
    return (
      <Image
        unoptimized={appConfig.unoptimizedImages}
        className={cn(
          { 'max-w-full': shouldSetFullWidth },
          shouldSetHeightToAuto ? 'h-auto' : '',
          className
        )}
        {...otherProps}
        src={
          enableVendorOptimization
            ? src
            : disablePrismicImgOptimization(src as string)
        }
        alt={alt || ''}
        style={{ maxWidth: otherProps.width, height: otherProps.height }}
      />
    );
  } else {
    return null;
  }
}
