import { useFetchWithErrorTracking } from './ErrorTrackingProvider';
import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
  createContext,
} from 'react';
import { FetchContext } from './useFetchProvider';

export type Callback = (...args: any[]) => Promise<Response>;

export interface UseFetchOptions {
  /**
   * The response type
   */
  responseAs?: 'json' | 'text';

  /**
   * If <b>cacheKey</b> is used, the response will be cached,
   * next time trying to fetch and same key configured, it will use response from cache
   */
  cacheKey?: string;
}

export function useCall<T>(
  callback: Callback,
  useFetchOptions?: UseFetchOptions
) {
  const { cache } = useContext(FetchContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FetchResponse<T>>(null);
  const [data, setData] = useState<T>(null);
  const callbackRef = useRef<Callback>(null);
  callbackRef.current = callback;

  const readFromCache = useCallback(async () => {
    setLoading(false);
    const cachedResponse = await cache?.get<FetchResponse<T>>(
      useFetchOptions.cacheKey
    );
    setData(cachedResponse.data);
    if (!cachedResponse.success) {
      setError(cachedResponse);
    }
    return cachedResponse;
  }, []);

  const call = useCallback(
    async (...args: any[]) => {
      setLoading(true);
      setError(null);
      let response: Response;
      let data: T;

      if (
        useFetchOptions?.cacheKey &&
        cache?.getStatus(useFetchOptions.cacheKey)
      ) {
        return await readFromCache();
      }
      if (useFetchOptions?.cacheKey) {
        cache?.setStatus(useFetchOptions?.cacheKey, 'waiting');
      }

      try {
        response = await callbackRef.current(...args);
        data =
          useFetchOptions?.responseAs === 'text'
            ? await response.text()
            : await response.json();

        setData(data);
        if (!response.ok) {
          setError(buildFetchResponse<T>(response, data));
        }
      } catch (e) {
        setError(e);
      }
      setLoading(false);

      if (useFetchOptions?.cacheKey) {
        cache?.set(
          useFetchOptions.cacheKey,
          buildFetchResponse<T>(response, data)
        );
      }

      return buildFetchResponse<T>(response, data);
    },
    [callbackRef]
  );

  return {
    loading,
    error,
    data,
    call,
  };
}

export function useGet<T>(url: string, useFetchOptions?: UseFetchOptions) {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();
  const { loading, error, data, call } = useCall<T>(
    () => fetchWithErrorTracking(url),
    useFetchOptions
  );
  useEffect(() => {
    call();
  }, [call]);
  return {
    loading,
    error,
    data,
  };
}

export function buildFetchResponse<T>(response: Response, data: T) {
  const headers = {};
  response?.headers?.forEach((value, key) => (headers[key] = value));
  const fetchResponse: FetchResponse<T> = {
    status: response?.status,
    success: response?.status === 200,
    headers,
  };
  fetchResponse.data = data;
  return fetchResponse;
}

export interface FetchResponse<T> {
  status: number;
  data?: T;
  success?: boolean;
  headers?: { [key: string]: string };
}

export const FetchAPIConfigContext = createContext<unknown>({});

export function useFetchAPIConfigContext<T>() {
  const config = useContext(FetchAPIConfigContext);
  return { config: config as T };
}
