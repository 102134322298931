import {
  useDocsByUid,
  useIsStoreLocator,
} from '@ee-monorepo/shared/utilities/hooks';
import { PrismicRichText } from '@prismicio/react';
import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import sanitizeHTML from 'sanitize-html';
import * as prismicH from '@prismicio/helpers';
import { PrismicButton } from '../button/prismic-button';
import { replaceRichTextPlaceholder } from '@ee-monorepo/prismic/shared/utilities';
import {
  getCookie,
  removeCookie,
} from '@ee-monorepo/shared/utilities/functions';
import { IconClose } from '@ee-monorepo/shared/ui/icons';
import { colors } from '@snap/colors';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { getPlainTextFromHTML } from '@ee-monorepo/shared/utilities/functions';

const offerBannerUid = 'offer-banner';
export function SharedUiBanner(props: { bannerUid: string; isReady? }) {
  const [bannerData, setBannerData] = useState(null);
  const [bannerClose, setBannerClose] = useState(false);
  const [offerInformation, setOfferInformation] = useState(null);
  const bannerUid = useMemo(() => props.bannerUid, [props]);
  const { tracking } = useTrackingContext();
  const { results, isLoading } = useDocsByUid(
    'getByUID',
    'banner_custom_type',
    offerInformation ? offerBannerUid : bannerUid,
    null
  );

  const offer = getCookie('offerInformation');

  useEffect(() => {
    if (offer) {
      setOfferInformation(JSON.parse(offer));
    }
  }, [offer]);

  useEffect(() => {
    const resultData = results?.docs?.['data'];
    if (results?.status === 'success' && resultData) {
      setBannerData(resultData);
      props.isReady?.(true);

      if (resultData) {
        tracking.logContentViewedEvent({
          content_name: resultData?.banner_type,
          content_value: getPlainTextFromHTML(resultData?.title[0]?.text),
        });
      }
    }
  }, [results, tracking, setBannerData, props]);

  const sanitizeOptions = {
    allowedAttributes: {
      td: ['rowspan', 'colspan'],
      '*': ['class'],
    },
  };

  const formatDate = (date: string) => {
    const dt = new Date(date);
    const formattedDate = dt.toLocaleString('default', {
      month: 'long',
      year: '2-digit',
    });
    return formattedDate;
  };

  const formatAmount = (amount: string) => {
    return amount.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const expirationDate = formatDate(
    offerInformation?.['expiration_date'] ?? ''
  );

  const offerAmount = formatAmount(offerInformation?.['offer_amount'] ?? '');

  const { isStoreLocator } = useIsStoreLocator();

  const isOfferBanner = bannerData?.banner_type === 'Offer Banner';

  const handleClickAway = () => {
    removeCookie('offerInformation', true);
    setBannerClose(true);
    props.isReady?.(true);
  };

  return !isLoading &&
    bannerData &&
    Object.keys(bannerData).length > 0 &&
    !(isOfferBanner && isStoreLocator)  &&
    !bannerClose ? ( //hiding offer banner in store locator
    <div
      data-testid="banner"
      style={{
        color: bannerData.text_color,
        backgroundColor: bannerData.background_color,
      }}
      className={cn(
        `relative w-full text-[${bannerData?.text_color}] bg-[${bannerData?.background_color}] z-10 lg:z-20 lg:[&>p]:m-0`,
        isOfferBanner
          ? 'text-left py-auto justify-between pt-[8px] pb-1'
          : 'text-center justify-center align-middle'
      )}
    >
      <div className="snap-container pl-[30px] md:pl-4 lg:pl-0 grid grid-cols-2 lg:grid-cols-3 gap-0 pt-3">
        {prismicH.isFilled.richText(bannerData.title) ? (
             <>
             <IconClose
               className={'absolute top-6 sm:ml-[-27px] md:ml-[-35px]'}
               iconColor={colors.SnapWhite}
               onClick={handleClickAway}
             ></IconClose>
             <div
               data-testid="banner-title"
               dangerouslySetInnerHTML={{
                 __html: sanitizeHTML(
                   `${replaceRichTextPlaceholder(bannerData?.title, [
                     {
                       placeholder: '{customerName}',
                       substitute: offerInformation?.['first_name'],
                     },
                   ]).map((c) => c.text)}`,
                   sanitizeOptions
                 ),
               }}
               className={'headingMHeavy lg:headingL col-span-2 lg:col-span-3 '}
             />
           </>
        ) : null}

        {/* Mobile */}
        {prismicH.isFilled.richText(bannerData.mobile_text) ? (
          <div
            data-testid="banner-mobile-section"
            className={cn(
              isOfferBanner ? 'col-span-1' : 'col-span-2',
              'lg:hidden bodyFont !mb-0'
            )}
          >
            <PrismicRichText
              field={replaceRichTextPlaceholder(bannerData.mobile_text, [
                {
                  placeholder: '{offer_expiration_date}',
                  substitute: expirationDate,
                },
                {
                  placeholder: '{approved_amount}',
                  substitute: offerAmount,
                },
              ])}
            />
          </div>
        ) : null}
        {
          <div
            data-testid="banner-desktop-section"
            className={cn(
              `hidden lg:grid`,
              isOfferBanner ? 'col-span-2' : 'col-span-3'
            )}
          >
            {prismicH.isFilled.richText(bannerData.text) ? (
              <div
                data-testid="banner-desktop-text"
                className="headingS lg:w-[194]"
              >
                <PrismicRichText
                  field={replaceRichTextPlaceholder(bannerData.text, [
                    {
                      placeholder: '{offer_expiration_date}',
                      substitute: expirationDate,
                    },
                    {
                      placeholder: '{approved_amount}',
                      substitute: offerAmount,
                    },
                  ])}
                  components={{
                    paragraph: ({ children }) => (
                      <p className="!mb-1">{children}</p>
                    ),
                  }}
                />
              </div>
            ) : null}
            {prismicH.isFilled.richText(bannerData.disclaimer) ? (
              <div
                data-testid="banner-disclaimer"
                className="secondaryFont !mb-0"
              >
                <PrismicRichText field={bannerData.disclaimer} />
              </div>
            ) : null}
          </div>
        }

        {prismicH.isFilled.link(bannerData.button_url) &&
        bannerData.button_text &&
        isOfferBanner ? (
          <PrismicButton
            data-testid="banner-cta"
            className="w-fit h-fit col-span-2 lg:col-span-1 2xl:justify-self-center mb-[16px]"
            variant={'secondary'}
            inverseMode={true}
            link={bannerData.button_url}
            showButton={true}
            label={bannerData.button_text}
            onClick={() => {
              tracking.logClickEvent({
                click_type: 'cta',
                placement: 'top navigation',
                click_value: bannerData.button_text,
              });
            }}
          />
        ) : null}

        {prismicH.isFilled.image(bannerData.image) && isOfferBanner ? (
          <div data-testid="banner-image" className="absolute right-0 bottom-0">
            <img
              src={bannerData.image.url}
              alt={bannerData.image.alt}
              width={bannerData.image.dimensions.width}
              height={bannerData.image.dimensions.height}
              className="hidden lg:flex"
            />
            <img
              src={bannerData.image.mobile.url}
              alt={bannerData.image.mobile.alt}
              width={bannerData.image.mobile.dimensions.width}
              height={bannerData.image.mobile.dimensions.height}
              className="lg:hidden"
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
}
export default SharedUiBanner;
