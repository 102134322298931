import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuMattresses({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-mattresses"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.93333 1C4.3133 1 3 2.3133 3 3.93333V9H21L21 3.93333C21 2.3133 19.6867 1 18.0667 1H5.93333ZM6 3C5.44772 3 5 3.44772 5 4V6C5 6.55229 5.44772 7 6 7L10 7C10.5523 7 11 6.55229 11 6V4C11 3.44772 10.5523 3 10 3H6ZM14 3C13.4477 3 13 3.44772 13 4V6C13 6.55229 13.4477 7 14 7L18 7C18.5523 7 19 6.55229 19 6V4C19 3.44772 18.5523 3 18 3H14Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M3 11H21V20.0667C21 21.6867 19.6867 23 18.0667 23H5.93333C4.3133 23 3 21.6867 3 20.0667V11Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
