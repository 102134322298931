import { useEffect, useLayoutEffect } from 'react';
import router, { useRouter } from 'next/router';
import { logPageView, tracking } from './tracking-config';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { TrackingContext } from '@ee-monorepo/shared/context/tracking';
import { useFeatureFlags } from '@ee-monorepo/shared/data-access/experiments';
import { useUserId } from '@ee-monorepo/shared/utilities/hooks';
import amplitude from 'amplitude-js';

export const useBrowserLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function TrackingProvider({ children }) {
  const { featureFlags } = useFeatureFlags();
  const { userId } = useUserId();
  const { isFallback } = useRouter();

  // init vendor and listen routes changes
  useBrowserLayoutEffect(() => {
    if (isFallback) {
      return;
    }

    amplitude.getInstance().init(appConfig.amplitudeApiKey, null, {
      includeReferrer: true,
      includeUtm: true,
      saveParamsReferrerOncePerSession: false,
      transport: 'beacon', // be able to trigger event after page exits or redirect
    });

    window['amplitude'] = amplitude.getInstance();

    if (!featureFlags) {
      return;
    }

    tracking.setUserProperties({
      snap_user_id: userId,
      referer: document.referrer,
      feature_flags: JSON.stringify(featureFlags),
    });

    logPageView();

    router.events.on('routeChangeComplete', () => logPageView());

    return () => {
      router.events.off('routeChangeComplete', () => logPageView());
    };
  }, [userId, isFallback, featureFlags]);

  return (
    <TrackingContext.Provider value={tracking}>
      {children}
    </TrackingContext.Provider>
  );
}
