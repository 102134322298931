import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconScooters({ iconColor, size = 'lg', className }: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-scooters"
      className={className}
    >
      <path
        d="M65 130H20"
        stroke={iconColor ?? colors.SnapWhite}
        strokeWidth="4"
      />
      <path
        d="M130 134.203C141.046 134.203 150 125.249 150 114.203C150 103.157 141.046 94.2031 130 94.2031C118.954 94.2031 110 103.157 110 114.203C110 125.249 118.954 134.203 130 134.203Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M42 134.203C53.0457 134.203 62 125.249 62 114.203C62 103.157 53.0457 94.2031 42 94.2031C30.9543 94.2031 22 103.157 22 114.203C22 125.249 30.9543 134.203 42 134.203Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M112.5 85.4531L97.0028 105.175C95.1069 107.587 92.2083 108.996 89.1399 108.996H77.0304C74.269 108.996 72.0304 106.757 72.0304 103.996L72.0305 93.0034"
        stroke={iconColor ?? colors.SnapDarkBlue}
        strokeWidth="8"
      />
      <path
        d="M104.51 95.6844C110.234 87.7852 119.534 82.647 130.034 82.647C138.515 82.647 146.213 85.9989 151.876 91.4503"
        stroke={iconColor ?? colors.SnapDarkBlue}
        strokeWidth="8"
      />
      <path
        d="M76 57H42"
        stroke={iconColor ?? colors.SnapOrange}
        strokeWidth="6"
      />
      <path
        d="M81 110H70"
        stroke={iconColor ?? colors.SnapDarkBlue}
        strokeWidth="6"
      />
      <path
        d="M130 124C135.523 124 140 119.523 140 114C140 108.477 135.523 104 130 104C124.477 104 120 108.477 120 114C120 119.523 124.477 124 130 124Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M42 124C47.5228 124 52 119.523 52 114C52 108.477 47.5228 104 42 104C36.4772 104 32 108.477 32 114C32 119.523 36.4772 124 42 124Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M136 83L117.609 30.6836C116.201 26.6794 112.419 24 108.175 24H94"
        stroke={iconColor ?? colors.SnapDarkBlue}
        strokeWidth="8"
      />
      <path
        d="M76 113H7V85C7.00001 73.9543 15.9543 65 27 65H76V90V101.5V113Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M113.905 36H119.441L133.972 78.2785L118.441 84.5L99.941 104.5H92.559L111.133 80.7538C113.025 78.3347 113.553 75.1178 112.533 72.2208L104.473 49.3201C102.183 42.8154 107.009 36 113.905 36Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M121 25C121 20.0294 125.029 16 130 16H138V36H130C125.029 36 121 31.9706 121 27V25Z"
        fill={iconColor ?? colors.SnapOrange}
      />
    </svg>
  );
}
