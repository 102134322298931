import { FlowRouter } from './types';

export function pushRoute(
  route: string,
  router: FlowRouter,
  preserveParams: boolean
) {
  const queryString = preserveParams
    ? new URLSearchParams(router.query as Record<string, string>).toString()
    : null;
  router.push(
    `/${router.locale}${route}${queryString ? `?${queryString}` : ''}`
  );
}
