import { getCookie } from './cookies';

/**
 * Gets session info from sessionStorage and cookie
 * @returns
 */
export function getSessionInfo() {
  if (typeof window === 'undefined') {
    // if nodejs runtime
    return '';
  }

  const clickIdsInCookie = {
    _gcl_aw: getCookie('_gcl_aw') || '',
    _uetmsclkid: getCookie('_uetmsclkid') || '',
    _ga: getCookie('_ga') || '',
    _fbc: getCookie('_fbc') || '',
    _fbp: getCookie('_fbp') || '',
    _snap_gclsrc: getCookie('_snap_gclsrc') || '',
  };

  return {
    clicks: JSON.stringify(clickIdsInCookie),
    sessionStorageId: sessionStorage.getItem('analytics_session_storage') || '',
    sessionUserId: getCookie('analytics_uuid') || '',
    cookieSessionId: getCookie('analytics_session_cookie') || '',
  };
}

export function getgclCookie() {
  const gclawCookie = getCookie('_gcl_aw');
  const gclID = gclawCookie ? gclawCookie?.split('.')[2] : ''; // getting the gcl ID value
  return gclID;
}
