import { createContext, ReactNode, useContext, useState } from 'react';

export const MerchantLongFormContext = createContext<
  [boolean, (enabled: boolean) => void]
>([false, () => {}]);

export const MerchantLongFormProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [show, setShow] = useState(false);
  return (
    <MerchantLongFormContext.Provider value={[show, setShow]}>
      {children}
    </MerchantLongFormContext.Provider>
  );
};

export const useMerchantLongFormContext = () => {
  const [show, setShow] = useContext(MerchantLongFormContext);
  return {
    show,
    setShow,
  };
};
