const useStoreLocatorMapHeight = (isTouch, isMobileDevice) => {
  // if is not touch device but breakpoint is narrow, map covers 60% of the vertical space (this map is stack over results)
  let mapHeight = isTouch
    ? 'calc(100vh - 230px)'
    : 'calc(100vh - var(--desktop-nav-container-height))';
  /* if is a touch device leave a gap below, otherwise map covers 100% vertical */
  mapHeight = !isTouch && isMobileDevice ? '60vh' : mapHeight;
  return mapHeight;
};

export { useStoreLocatorMapHeight };
