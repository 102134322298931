import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconFurniture({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-furniture"
      className={className}
    >
      <path
        d="M126.75 0H109.25V17.9239H107C97.6112 17.9239 90 25.5521 90 34.9619C90 44.3718 97.6112 52 107 52H129C138.389 52 146 44.3718 146 34.9619C146 25.5521 138.389 17.9239 129 17.9239H126.75V0Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 52C13.9543 52 5 60.9543 5 72V95H155V52H25ZM86 73C86 76.3137 83.3137 79 80 79C76.6863 79 74 76.3137 74 73C74 69.6863 76.6863 67 80 67C83.3137 67 86 69.6863 86 73Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155 99H5V142H21V160H31.0465L39 142H121L128.953 160H139V142H155V99ZM86 120C86 123.314 83.3137 126 80 126C76.6863 126 74 123.314 74 120C74 116.686 76.6863 114 80 114C83.3137 114 86 116.686 86 120Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
