import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuElectronics({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-electronics"
    >
      <path d="M5 6H19V8H5V6Z" fill={iconColor ?? colors.SnapGreen} />
      <path d="M12 10H5V12H12V10Z" fill={iconColor ?? colors.SnapGreen} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3C1 2.44772 1.44772 2 2 2H22C22.5523 2 23 2.44771 23 3V17C23 17.5523 22.5523 18 22 18H15V20H17C18.1046 20 19 20.8954 19 22L5 22C5 20.8954 5.89543 20 7 20H9V18H2C1.44772 18 1 17.5523 1 17V3ZM3 16L3 4L21 4V16H3Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
