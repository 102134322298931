import { ExperimentsContext } from './experiments-context';
import SplitIO from '@splitsoftware/splitio-browserjs/types/splitio';
import { useEffect, useContext, useState } from 'react';

export const useExperiment = (
  treatmentName: string,
  attributes = undefined
) => {
  const { isReady, isTimedOut, client, checkActiveExperiment } =
    useContext(ExperimentsContext);
  const [experiment, setExperiment] = useState('');
  const [config, setConfig] = useState('');
  useEffect(() => {
    if (isReady || isTimedOut) {
      checkActiveExperiment(treatmentName);
      const treatmentResult: SplitIO.TreatmentWithConfig | null = client
        ? client?.getTreatmentWithConfig(treatmentName, attributes)
        : null;
      if (treatmentResult) {
        setExperiment(treatmentResult.treatment);
        setConfig(JSON.parse(treatmentResult.config as string));
      }
    }
  }, [
    client,
    treatmentName,
    attributes,
    isReady,
    isTimedOut,
    checkActiveExperiment,
  ]);
  return {
    experiment,
    config,
  };
};
