import {
  useCall,
  useFetchWithErrorTracking,
} from '@ee-monorepo/shared/utilities/hooks';
import { PlatformResponse } from '../types';

export function useVerifyEmail() {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();
  const { call, data, loading, error } = useCall<
    PlatformResponse<{
      email: string;
      serviceEnabled: boolean;
      validEmail: boolean;
    }>
  >((email: string) =>
    fetchWithErrorTracking(
      `${process.env['NEXT_PUBLIC_API_INTERNAL']}/verification/email`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      }
    )
  );
  return {
    verifyEmail: call,
    verifyEmailResponse: data,
    verifyEmailLoading: loading,
    verifyEmailError: error,
  };
}
