import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconReduceAbandonCart({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-reduce-abandon-cart"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 22.2338C8 18.791 10.791 16 14.2338 16L81.7662 16C85.2091 16 88 18.791 88 22.2338V66.9091C88 70.3519 85.2091 73.1429 81.7662 73.1429H58.3896V82.4935H66.7013C70.1441 82.4935 72.9351 85.2845 72.9351 88.7273H23.0649C23.0649 85.2845 25.8559 82.4935 29.2987 82.4935H37.6104V73.1429H14.2338C10.791 73.1429 8 70.3519 8 66.9091L8 22.2338ZM14.2338 66.9091L14.2338 22.2338L81.7662 22.2338V66.9091L14.2338 66.9091Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M37.0006 58C35.3506 58 34.0156 59.35 34.0156 61C34.0156 62.65 35.3506 64 37.0006 64C38.6506 64 40.0006 62.65 40.0006 61C40.0006 59.35 38.6506 58 37.0006 58ZM52.0006 58C50.3506 58 49.0156 59.35 49.0156 61C49.0156 62.65 50.3506 64 52.0006 64C53.6506 64 55.0006 62.65 55.0006 61C55.0006 59.35 53.6506 58 52.0006 58Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M28 34V37H31L36.4 48.385L34.375 52.06C34.135 52.48 34 52.975 34 53.5C34 55.15 35.35 56.5 37 56.5H55V53.5H37.63C37.42 53.5 37.255 53.335 37.255 53.125L37.3 52.945L38.65 50.5H49.825C50.95 50.5 51.94 49.885 52.45 48.955L57.82 39.22C57.94 39.01 58 38.755 58 38.5C58 37.675 57.325 37 56.5 37H34.315L32.905 34H28Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <g clipPath="url(#clip0_110_181)">
        <circle cx="57" cy="35" r="10" fill="white" />
        <path
          d="M57 25C51.48 25 47 29.48 47 35C47 40.52 51.48 45 57 45C62.52 45 67 40.52 67 35C67 29.48 62.52 25 57 25ZM55 40L50 35L51.41 33.59L55 37.17L62.59 29.58L64 31L55 40Z"
          fill={iconColor ?? colors.SnapOrange}
        />
      </g>
      <defs>
        <clipPath id="clip0_110_181">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(47 25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
