/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useEffect } from 'react';
import { AppProps } from 'next/app';
import * as Sentry from '@sentry/nextjs';
import Head from 'next/head';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { NextIntlClientProvider } from 'next-intl';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import '../styles/global.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import { theme } from '@ee-monorepo/shared/utilities/constants';
import { Document } from 'prismic-javascript/types/documents';
import App from 'next/app';
import {
  useRakutenParams,
  ModernNavBarProvider,
  useUserId,
  FetchProvider,
  useMarketingCookies,
  useUTM,
  NavigationBarHeightProvider,
} from '@ee-monorepo/shared/utilities/hooks';
import { TrackingProvider } from '../components/tracking/tracking-provider';
import { PrismicProvider } from '@prismicio/react';
import { PrismicPreview } from '@prismicio/next';
import { linkResolver } from '@ee-monorepo/prismic/shared/utilities';
import { repoName, createClient } from '../prismic-configuration';
import { ExperimentsProvider } from '@ee-monorepo/shared/data-access/experiments';
import { TrackJS } from '../utils/trackjs-isomorphic';

import {
  ErrorTrackingContext,
  ErrorTrackingSeverity,
} from '@ee-monorepo/shared/utilities/hooks';
import { activeExperiments } from '../utils/experiments';
import { getCookie } from '@ee-monorepo/shared/utilities/functions';
import { AuthProvider } from '@ee-monorepo/account-takeover-forms';
import { AppMessage } from '@ee-monorepo/shared/ui/app-message';
import { useRouter } from 'next/router';

const sentryErrorLevels: {
  [key in ErrorTrackingSeverity]: Sentry.SeverityLevel;
} = {
  error: 'error',
  debug: 'debug',
  info: 'info',
  log: 'log',
  warn: 'warning',
  fatal: 'fatal',
};
function CustomApp({
  Component,
  pageProps,
  layout,
}: AppProps<any> & { layout: Document }) {
  const { userId } = useUserId();
  const utm = useUTM();
  const router = useRouter();
  useEffect(() => {
    Sentry.configureScope(function (scope) {
      console.log({ utm });
      scope.setTag('utm_campaign', utm.utm_campaign);
      scope.setTag('utm_medium', utm.utm_medium);
      scope.setTag('utm_source', utm.utm_source);
      scope.setTag('utm_term', utm.utm_term);
      scope.setTag('utm_content', utm.utm_content);
      scope.setUser({
        id: getCookie('analytics_uuid'),
      });
    });
  }, [utm]);

  useRakutenParams();
  useMarketingCookies();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <title>Welcome to Snap!</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#143965" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <div>
        <main>
          <AuthProvider>
            <FetchProvider>
              <ThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                <NextIntlClientProvider
                    messages={pageProps?.messages ?? {}}
                    locale={router.locale}
                  >
                    <PrismicProvider linkResolver={linkResolver}>
                      <PrismicPreview repositoryName={repoName}>
                        <AppMessage className="mt-12">
                          <ErrorTrackingContext.Provider
                            value={{
                              logFetchError: (
                                method,
                                path,
                                httpStatus,
                                severity
                              ) => {
                                Sentry.withScope(function (scope) {
                                  scope.setLevel(sentryErrorLevels[severity]);
                                  Sentry.captureException(
                                    new Error(`${method} ${path} ${httpStatus}`)
                                  );
                                });
                              },
                            }}
                          >
                            <ExperimentsProvider
                              authorizationKey={appConfig.splitApi}
                              userKey={userId}
                              activeExperiments={activeExperiments}
                            >
                              <TrackingProvider>
                                <ModernNavBarProvider>
                                  <NavigationBarHeightProvider>
                                    <Component {...pageProps} layout={layout} />
                                  </NavigationBarHeightProvider>
                                </ModernNavBarProvider>
                              </TrackingProvider>
                            </ExperimentsProvider>
                          </ErrorTrackingContext.Provider>
                        </AppMessage>
                      </PrismicPreview>
                    </PrismicProvider>
                  </NextIntlClientProvider>
                </StyledEngineProvider>
              </ThemeProvider>
            </FetchProvider>
          </AuthProvider>
        </main>
      </div>
    </>
  );
}

CustomApp.getInitialProps = async (ctx) => {
  const client = createClient();
  const appProps = await App.getInitialProps(ctx);
  const layout = await client.getByUID('layout', 'layout', {});
  return { ...appProps, layout };
};

if (appConfig.useTrackJS) {
  if (!TrackJS.isInstalled()) {
    TrackJS.install({
      token: 'd08797402a89403eaed63e9cf6ab721d',
      application: 'com-nextjs',
      // added defaultMetadata false if env is node to capture server errors, if env is browser then we don't need defaultMetadata key.
      defaultMetadata: process.browser ? undefined : false,
    });
  }
  CustomApp.componentDidCatch = (error: any) => {
    TrackJS.track(error);
  };
}

export default CustomApp;
