import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { storeCookie } from '@ee-monorepo/shared/utilities/functions';

const oneYear = 3.156e10;
export function useMarketingCookies() {
  const router = useRouter();
  useEffect(() => {
    if (router?.query?.gclsrc) {
      storeCookie('_snap_gclsrc', router?.query?.gclsrc, oneYear, true);
    }
  }, [router?.query]);
}
