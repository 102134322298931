import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconChevronDown({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-chevron-down"
    >
      <g clipPath="url(#clip0_112_1271)">
        <path
          d="M17.2946 9.29446C16.9053 8.90519 16.2743 8.90484 15.8846 9.29369L12 13.1698L8.11538 9.29369C7.72569 8.90484 7.09466 8.90519 6.70538 9.29446C6.31581 9.68403 6.31581 10.3157 6.70538 10.7052L11.6087 15.6085C11.8248 15.8247 12.1752 15.8247 12.3913 15.6085L17.2946 10.7052C17.6842 10.3157 17.6842 9.68403 17.2946 9.29446Z"
          fill={iconColor ?? colors.SnapGrey400}
        />
      </g>
      <defs>
        <clipPath id="clip0_112_1271">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
