import s from './shared-ui-tag.module.scss';
import cn from 'classnames';

export interface TagProps {
  tag: string;
  className?: string;
}

export function Tag({ tag, className }: TagProps) {
  return (
    <span
      className={cn(
        'inline-block px-2 mr-1',
        'tagSmallFont',
        s.tagContainer,
        className
      )}
    >
      {tag}
    </span>
  );
}
