import { useEffect, useRef, useState } from 'react';
import { useWindowResize } from './useWindowResize';

/**
 * Calculates the ref component height
 * Has an isReady property to identify when ui is ready
 * Uses the useWindowResize hook to identify when page was resized
 * @returns height, setIsReady and the ref
 */
export function useCalculateRefHeight() {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const { width } = useWindowResize();
  useEffect(() => {
    const height = ref?.current?.offsetHeight;
    setHeight(height);
    setIsReady(false);
  }, [isReady, width]); //isReady and width are acting like triggers to update the correct height

  return { height, setIsReady, ref };
}
