import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconEasyPayment({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-easy-payment"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8763 32H79.1237C81.8059 32 84 34.1942 84 36.8749V82.15C84 84.8312 81.8059 87.0249 79.1237 87.0249H8.8763C6.19415 87.0249 4 84.8308 4 82.15V36.8749C4 34.1937 6.19415 32 8.8763 32ZM64.0633 67.1403C65.3302 67.1403 66.5075 67.5193 67.49 68.1688C68.473 67.5188 69.6511 67.1403 70.9181 67.1403C74.356 67.1403 77.143 69.9282 77.143 73.3662C77.143 76.805 74.356 79.592 70.9181 79.592C69.6511 79.592 68.4729 79.213 67.49 78.5644C66.5069 79.213 65.3298 79.592 64.0633 79.592C60.6253 79.592 57.8374 76.805 57.8374 73.3662C57.8374 69.9282 60.6253 67.1403 64.0633 67.1403ZM12.3597 64.6068H34.2727V69.8782H12.3597V64.6068ZM12.3597 74.2608H46.3263V79.5322H12.3597V74.2608Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <g clipPath="url(#clip0_110_288)">
        <circle cx="68" cy="32" r="24" fill="white" />
        <path
          d="M68 8C54.752 8 44 18.752 44 32C44 45.248 54.752 56 68 56C81.248 56 92 45.248 92 32C92 18.752 81.248 8 68 8ZM63.2 44L51.2 32L54.584 28.616L63.2 37.208L81.416 18.992L84.8 22.4L63.2 44Z"
          fill={iconColor ?? colors.SnapLightGreen}
        />
      </g>
      <defs>
        <clipPath id="clip0_110_288">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(44 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
