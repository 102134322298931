import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export function useTouchMobile() {
  const isMobile = useTablet();
  const isTouch = useTouch();
  return isMobile && isTouch;
}

export function useHandHeld() {
  return useMediaQuery('(max-width: 600px)');
}

export function useTablet() {
  return useMediaQuery('(max-width: 928px)');
}

export function useTouch() {
  const [touch, setTouch] = useState(false);
  useEffect(() => {
    const isTouch = !!(
      window.navigator.maxTouchPoints > 0 ||
      'ontouchstart' in window ||
      (window['DocumentTouch'] && document instanceof window['DocumentTouch'])
    );
    setTouch(isTouch);
  }, []);
  return touch;
}
