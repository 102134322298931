import {
  Condition,
  ConditionalsArgs,
  FlowModel,
  FlowRouter,
  NextConditions,
} from './types';
import { pushRoute } from './utils';

export function resolveNextRoutingConditions(
  stepName: string,
  model: FlowModel,
  router: FlowRouter,
  nextConditions: NextConditions,
  hasNextRoute: boolean,
  args: ConditionalsArgs
) {
  const nextConditionMatch = nextConditions.find((nextCondition) => {
    if (Array.isArray(nextCondition.conditions)) {
      const conditions = nextCondition.conditions as Condition[];
      return conditions.every((condition) => executeCondition(condition, args));
    } else {
      const condition = nextCondition.conditions as Condition;
      return executeCondition(condition, args);
    }
  });
  if (nextConditionMatch) {
    return routeNext(nextConditionMatch.next, model, router);
  }
  if (!hasNextRoute) {
    throw new Error(
      `There was no condition matching on "${stepName}" based on given model provided`
    );
  }
}

/**
 * Validate the condition and its operator against the arguments passed in the "next" function call
 *
 * @param condition
 * @param args
 * @returns
 */
function executeCondition(condition: Condition, args: ConditionalsArgs) {
  const arg = args[condition.arg];
  if (!arg && typeof arg === 'undefined') {
    throw new Error(
      `Argument "${condition.arg}" not being passed as args in "next" function call`
    );
  }
  // TODO more conditions operators to be added here in future
  switch (condition.operator) {
    case 'is':
      return arg === condition.value;
    case 'isNot':
      return arg !== condition.value;
  }
}

function routeNext(nextStepName: string, model: FlowModel, router: FlowRouter) {
  const nextRoute = model.steps[nextStepName]?.route;
  const preserveParams = model.steps[nextStepName]?.preserveParams;

  if (!nextRoute) {
    throw new Error(`Next step "${nextStepName}" not found in flow model`);
  }

  pushRoute(nextRoute, router, preserveParams as boolean);
  return true;
}
