import React, { HTMLAttributes, ReactNode } from 'react';
import s from './slice-wrapper.module.scss';
import cn from 'classnames';

export interface SliceWrapperProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
}

export function SliceWrapper(props: SliceWrapperProps) {
  const { children, ...htmlProps } = props;
  return (
    <div
      data-testid="sliceWrapper"
      {...htmlProps}
      className={cn(s.wrapper, props.className)}
    >
      {children}
    </div>
  );
}
