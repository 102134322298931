import { useCallback } from 'react';
import { useVerifyDobSsn } from '../api/account-takeover/useVerifyDobSsn';
import { ErrorType } from './types';
import { createCommandHook } from '@ee-monorepo/shared/utilities/hooks';

export const useVerifyDobSsnCommand = createCommandHook(() => {
  const { verifyDobSSn, verifyDobSsnLoading } = useVerifyDobSsn();

  const execute = useCallback(
    async (params: { token: string; dob: Date | string; ssn: string }) => {
      const response = await verifyDobSSn(params.token, params.dob, params.ssn);

      const statusCode = response?.status;
      let errors: ErrorType[] = [];

      switch (statusCode) {
        case 200:
          errors = [];
          break;
        case 412:
          errors = [ErrorType.TOKEN_ALREADY_VERIFIED];
          break;
        case 428:
        case 400:
          errors = [ErrorType.INCORRECT_DATA];
          break;
        case 429:
          errors = [ErrorType.MAXIMUM_ATTEMPT_REACHED];
          break;
        case 404:
          errors = [ErrorType.TOKEN_INVALID];
          break;
        case 500:
        case 502:
          errors = [ErrorType.APPLICATION_ERROR];
          break;
        default:
          errors = [ErrorType.CONNECTIVITY_ISSUE];
      }

      return {
        statusCode,
        errors,
        data: {
          ...(response?.data || {}),
        },
      };
    },
    [verifyDobSSn]
  );

  return {
    execute,
    executing: verifyDobSsnLoading,
  };
});
