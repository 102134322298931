import { useApplyContext } from '@ee-monorepo/shared/context/apply';
import { Button, ButtonProps } from '@ee-monorepo/shared/ui/button';
import { MouseEvent, useEffect, useState } from 'react';

/**
 * Apply button
 *
 * Wrapper button component on top of existing apply function,
 * provides capabilities for ALICE to enhance low internet speed experience
 */
export function ApplyButton({ onClick, ...props }: ButtonProps) {
  const { apply } = useApplyContext();
  const [hydrated, setHydrated] = useState(false);

  // when page hydrated transform anchor to button component
  useEffect(() => {
    setHydrated(true);
  }, [setHydrated]);

  return (
    <Button
      href={hydrated ? undefined : '/apply-redirect?applyClickFallback=true'}
      {...props}
      onClick={(
        e: MouseEvent<HTMLButtonElement> & MouseEvent<HTMLAnchorElement>
      ) => {
        e.preventDefault();
        onClick?.(e);
        apply();
      }}
    />
  );
}
