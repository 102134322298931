import * as React from 'react';
import { IMaskInput } from 'react-imask';
import { TextFieldProps } from '@mui/material';
import { TextField } from './shared-ui-text-field';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
  mask: string;
  maskedValue: boolean;
  definitionMask: string;
  visibleValueMask: string;
  showSecuredValue: boolean;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const {
      onChange,
      mask,
      maskedValue,
      definitionMask = '',
      visibleValueMask = '',
      showSecuredValue = false,
      ...other
    } = props;
    return (
      <IMaskInput
        {...other}
        mask={showSecuredValue ? visibleValueMask : mask}
        onChange={() => {}}
        onAccept={(value: any, mask: any) =>
          onChange({
            target: {
              name: props.name,
              value: maskedValue ? value : mask._unmaskedValue,
            },
          })
        }
        definitions={{
          X: {
            mask: definitionMask,
            displayChar: '•',
            expose: true,
          },
        }}
        overwrite
      />
    );
  }
);

interface FormattedTextFieldProps {
  mask: string;
  maskedValue: boolean;
  visibleValueMask: string;
  definitionMask: string;
}

export const SecuredTextField = React.forwardRef(
  (props: TextFieldProps & FormattedTextFieldProps, ref) => {
    const { mask, maskedValue, definitionMask, visibleValueMask, ...rest } =
      props;
    const [showSecuredValue, setShowSecuredValue] = React.useState(false);
    const toggleSecuredValue = () => setShowSecuredValue(!showSecuredValue);

    return (
      <TextField
        {...rest}
        InputProps={{
          inputComponent: TextMaskCustom as any,
          inputProps: {
            mask: mask,
            maskedValue: maskedValue,
            definitionMask,
            visibleValueMask,
            showSecuredValue,
          },
        }}
        inputProps={{
          isPassword: false,
          partialValueToggle: true,
          toggleSecuredValue: { toggleSecuredValue },
        }}
      />
    );
  }
);
