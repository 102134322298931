import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/**
 * Verifies if current page is store locator
 * @returns boolean
 */
export function useIsStoreLocator() {
  const [isStoreLocator, setIsStoreLocator] = useState(false);
  const router = useRouter();
  useEffect(() => {
    const path = router.asPath;
    setIsStoreLocator(path.startsWith('/find-stores') || path.startsWith('/online-stores'));
  }, [router]);
  return { isStoreLocator };
}
