import React from 'react';
import { Element } from '@prismicio/helpers';
import s from './rich-text-serializer.module.scss';
import { wordify } from '@ee-monorepo/shared/utilities/functions';
import { JSXFunctionSerializer } from '@prismicio/react';
import { RTTextNodeBase } from '@prismicio/types';

const propsWithUniqueKey = (props = {}, key: string) => {
  return { ...props, key };
};

export const richTextSerializerHeadingStyleAlternative: JSXFunctionSerializer =
  function (type, element, content, children, key) {
    let props = {};
    const parsedElement = element as RTTextNodeBase;
    switch (type) {
      case Element.heading2: {
        props = {
          id: wordify(parsedElement.text),
          className: 'headingL md:headingXL',
        };
        return React.createElement(
          'h2',
          propsWithUniqueKey(props, key),
          children
        );
      }
      case Element.heading3: {
        props = {
          id: wordify(parsedElement.text),
          className: 'headingMHeavy md:headingL',
        };
        return React.createElement(
          'h3',
          propsWithUniqueKey(props, key),
          children
        );
      }
      case Element.heading4: {
        props = {
          id: wordify(parsedElement.text),
          className: 'headingSHeavy md:headingMHeavy',
        };
        return React.createElement(
          'h4',
          propsWithUniqueKey(props, key),
          children
        );
      }
      case Element.heading5: {
        props = {
          id: wordify(parsedElement.text),
          className: 'bodyHeavyFont md:headingSHeavy',
        };
        return React.createElement(
          'h5',
          propsWithUniqueKey(props, key),
          children
        );
      }
      case Element.heading6: {
        props = {
          id: wordify(parsedElement.text),
          className: 'headingS md:headingM',
        };
        return React.createElement(
          'h6',
          propsWithUniqueKey(props, key),
          children
        );
      }
      default: {
        return null;
      }
    }
  };
