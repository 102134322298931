import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconCustomers({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-customers"
    >
      <path
        d="M70.5543 25.0013C67.5659 25.0013 65.11 27.4514 65.11 30.4322C65.11 31.6575 65.5321 32.7685 66.2281 33.681L56.992 46.8461C56.6008 46.7557 56.1939 46.7249 55.7767 46.7249C54.2581 46.7249 52.8791 47.3364 51.8878 48.3496L45.3742 44.5672C45.5608 44.0216 45.6661 43.4512 45.6661 42.8456C45.6661 39.8646 43.21 37.4147 40.2218 37.4147C37.2336 37.4147 34.7774 39.8647 34.7774 42.8456C34.7774 44.0708 35.1995 45.1819 35.8956 46.0943L26.6595 59.2595C26.2683 59.1691 25.8614 59.1382 25.4442 59.1382C22.4559 59.1382 19.9999 61.5883 19.9999 64.5691C19.9999 67.5499 22.456 70 25.4442 70C28.4324 70 30.8885 67.5499 30.8885 64.5691C30.8885 63.1144 30.3031 61.7658 29.3575 60.7867L38.3751 47.9365C38.9534 48.1475 39.5741 48.2758 40.2224 48.2758C41.486 48.2758 42.6487 47.819 43.5763 47.0879L50.4545 51.0884C50.3845 51.4337 50.333 51.7909 50.333 52.1551C50.333 55.1361 52.7891 57.586 55.7773 57.586C58.7655 57.586 61.2216 55.1359 61.2216 52.1551C61.2216 50.7004 60.6363 49.3517 59.6907 48.3726L68.7082 35.5224C69.2865 35.7335 69.9072 35.8618 70.5556 35.8618C73.5439 35.8618 75.9999 33.4117 75.9999 30.4309C75.9999 27.4501 73.5438 25 70.5556 25L70.5543 25.0013ZM70.5543 28.1045C71.8613 28.1045 72.8878 29.1284 72.8878 30.4322C72.8878 31.736 71.8613 32.7599 70.5543 32.7599C69.2473 32.7599 68.2208 31.736 68.2208 30.4322C68.2208 29.1284 69.2473 28.1045 70.5543 28.1045ZM40.2209 40.5185C41.5278 40.5185 42.5543 41.5425 42.5543 42.8463C42.5543 44.15 41.5279 45.174 40.2209 45.174C38.9139 45.174 37.8874 44.15 37.8874 42.8463C37.8874 41.5425 38.9139 40.5185 40.2209 40.5185ZM55.7765 49.8287C57.0834 49.8287 58.1099 50.8521 58.1099 52.1565C58.1099 53.4602 57.0835 54.4842 55.7765 54.4842C54.4695 54.4842 53.443 53.4602 53.443 52.1565C53.443 50.8527 54.4695 49.8287 55.7765 49.8287ZM25.4431 62.2428C26.75 62.2428 27.7765 63.2662 27.7765 64.5705C27.7765 65.8742 26.7501 66.8982 25.4431 66.8982C24.1361 66.8982 23.1096 65.8743 23.1096 64.5705C23.1096 63.2667 24.1361 62.2428 25.4431 62.2428Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M76.1732 67.509C76.8973 66.6131 77.3333 65.4811 77.3333 64.25C77.3333 61.3551 74.9408 59 72 59C69.0592 59 66.6667 61.3551 66.6667 64.25C66.6667 65.4811 67.1027 66.6131 67.8268 67.509C65.5694 68.5616 64 70.8198 64 73.4375V78.6875C64 79.412 64.5973 80 65.3333 80H78.6667C79.4027 80 80 79.412 80 78.6875V73.4375C80 70.8198 78.4306 68.5616 76.1732 67.509Z"
        fill={iconColor ?? colors.SnapYellow}
      />
      <path
        d="M76.1732 67.509C76.8973 66.6131 77.3333 65.4811 77.3333 64.25C77.3333 61.3552 74.9408 59 72 59C69.0592 59 66.6667 61.3552 66.6667 64.25C66.6667 65.4811 67.1027 66.6131 67.8268 67.509C65.5694 68.5616 64 70.8198 64 73.4375V78.6875C64 79.412 64.5973 80 65.3333 80H78.6667C79.4027 80 80 79.412 80 78.6875V73.4375C80 70.8198 78.4306 68.5616 76.1732 67.509ZM72 61.625C73.4706 61.625 74.6667 62.8024 74.6667 64.25C74.6667 65.6976 73.4706 66.875 72 66.875C70.5294 66.875 69.3333 65.6976 69.3333 64.25C69.3333 62.8024 70.5294 61.625 72 61.625ZM77.3333 77.375H66.6667V73.4375C66.6667 71.266 68.4607 69.5 70.6667 69.5H73.3333C75.5393 69.5 77.3333 71.266 77.3333 73.4375V77.375Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M28.1732 24.509C28.8973 23.6131 29.3333 22.4811 29.3333 21.25C29.3333 18.3551 26.9408 16 24 16C21.0592 16 18.6667 18.3551 18.6667 21.25C18.6667 22.4811 19.1027 23.6131 19.8268 24.509C17.5694 25.5616 16 27.8198 16 30.4375V35.6875C16 36.412 16.5973 37 17.3333 37H30.6667C31.4027 37 32 36.412 32 35.6875V30.4375C32 27.8198 30.4306 25.5616 28.1732 24.509Z"
        fill={iconColor ?? colors.SnapYellow}
      />
      <path
        d="M28.1732 24.509C28.8973 23.6131 29.3333 22.4811 29.3333 21.25C29.3333 18.3552 26.9408 16 24 16C21.0592 16 18.6667 18.3552 18.6667 21.25C18.6667 22.4811 19.1027 23.6131 19.8268 24.509C17.5694 25.5616 16 27.8198 16 30.4375V35.6875C16 36.412 16.5973 37 17.3333 37H30.6667C31.4027 37 32 36.412 32 35.6875V30.4375C32 27.8198 30.4306 25.5616 28.1732 24.509ZM24 18.625C25.4706 18.625 26.6667 19.8024 26.6667 21.25C26.6667 22.6976 25.4706 23.875 24 23.875C22.5294 23.875 21.3333 22.6976 21.3333 21.25C21.3333 19.8024 22.5294 18.625 24 18.625ZM29.3333 34.375H18.6667V30.4375C18.6667 28.266 20.4607 26.5 22.6667 26.5H25.3333C27.5393 26.5 29.3333 28.266 29.3333 30.4375V34.375Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
