import { SplitFactory } from '@splitsoftware/splitio-browserjs';
import SplitIO from '@splitsoftware/splitio-browserjs/types/splitio';
import { useEffect, useState, useRef, useCallback } from 'react';
import { ExperimentsContext } from './experiments-context';

export interface ExperimentsProviderProps {
  children: JSX.Element;
  authorizationKey: string;
  userKey: string;
  activeExperiments: string[];
}

export function ExperimentsProvider({
  children,
  authorizationKey,
  userKey,
  activeExperiments,
}: ExperimentsProviderProps) {
  const [isReady, setIsReady] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const clientRef = useRef({} as SplitIO.IClient | null);

  useEffect(() => {
    const factory: SplitIO.ISDK = SplitFactory({
      core: {
        authorizationKey,
        key: userKey,
      },
      startup: {
        // low timeouts to improve experience
        readyTimeout: 1.5, // Maximum amount of time in seconds to wait before firing the SDK_READY_TIMED_OUT event
        requestTimeoutBeforeReady: 1.5, // The SDK has two main endpoints it uses /splitChanges and /mySegments that it hits to get ready. This config sets how long (in seconds) the SDK will wait for each request it makes as part of getting ready.
      },
    });
    const client: SplitIO.IClient = factory.client();
    client.on(client.Event.SDK_READY, () => {
      setIsReady(true);
    });
    client.on(client.Event.SDK_READY_TIMED_OUT, () => {
      setIsTimedOut(true);
    });
    clientRef.current = client;
    return () => {
      client.destroy();
      clientRef.current = null;
      setIsReady(false);
      setIsTimedOut(false);
    };
  }, [authorizationKey, userKey]);

  const checkActiveExperiment = useCallback(
    (experiment: string) => {
      if (!activeExperiments.includes(experiment)) {
        throw new Error(
          `${experiment} is not an active experiment set by "activeExperiments" prop on ExperimentsProvider at app level`
        );
      }
    },
    [activeExperiments]
  );

  return (
    <ExperimentsContext.Provider
      value={{
        client: clientRef?.current,
        isReady,
        isTimedOut,
        checkActiveExperiment,
        activeExperiments,
      }}
    >
      {children}
    </ExperimentsContext.Provider>
  );
}
