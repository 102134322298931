import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuCollisionRepair({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-collision-repair"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5916 6.7373L16.1294 8.25348L16.668 7.44559C16.9743 6.98606 17.5952 6.86189 18.0547 7.16824C18.5142 7.47459 18.6384 8.09546 18.3321 8.55499L16.8706 10.7471L13.4084 9.26328L10.8575 13.5146C10.5733 13.9882 9.95907 14.1418 9.48549 13.8576C9.01192 13.5734 8.85836 12.9592 9.14252 12.4856L12.5916 6.7373Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 21.1002V4.4502H1.61333C1.61333 4.4502 3.36111 4.4502 4.30222 5.3826C5.24333 6.315 10.2178 13.5754 10.2178 13.5754L13.7133 12.5098L15.73 13.975L17.4778 12.9094L18.15 14.2414V20.6502C18.15 20.8987 17.9485 21.1002 17.7 21.1002H13.95C13.9498 23.1711 12.3045 24.8499 10.275 24.8499C8.24544 24.8499 6.60016 23.1711 6.59998 21.1002H0Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.368 1.0798C19.8801 1.2997 20.1305 1.88983 19.9272 2.3979L18.9272 4.89794C18.724 5.40601 18.1441 5.63962 17.632 5.41972C17.1199 5.19982 16.8695 4.60969 17.0728 4.10162L18.0728 1.60158C18.276 1.09351 18.8559 0.859902 19.368 1.0798Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1889 10.3913C19.5097 9.95129 20.1328 9.86695 20.5808 10.2029L22.5808 11.7029C23.0287 12.0389 23.1319 12.6679 22.8111 13.108C22.4903 13.548 21.8672 13.6323 21.4192 13.2963L19.4192 11.7963C18.9713 11.4604 18.8681 10.8313 19.1889 10.3913Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7843 5.61109C23.0581 6.09974 22.8924 6.70681 22.4143 6.967L21.4957 7.4669C21.0175 7.72709 20.408 7.54189 20.1343 7.05323C19.8605 6.56457 20.0262 5.95751 20.5043 5.69731L21.4229 5.19742C21.9011 4.93723 22.5106 5.12243 22.7843 5.61109Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
