import { getSessionId } from '@ee-monorepo/shared/utilities/unique-id';
import amplitude from 'amplitude-js';
import { Tracking } from '@snap/tracking';

/**
 * Tracking wrapper setup
 */
export const tracking = new Tracking({
  vendor: {
    logEvent: (event: string, properties) => {
      amplitude.getInstance().logEvent(event, properties);
    },
    setUserProperties: (properties) => {
      amplitude.getInstance().setUserProperties(properties);
    },
  },
  getEngagementEventProps: () => ({
    page_url: document.location.href,
    page_path: window.location.pathname,
    subdomain_and_domain: document.location.host,
  }),

  getSuperEventProps: () => ({
    analytics_session_cookie: getSessionId(),
    analytics_session_storage: sessionStorage.getItem('analytics_session'),
    analytics_local_storage: localStorage.getItem('analytics_session'),
    analytics_uuid: localStorage.getItem('analytics_uuid'),
  }),
});

/**
 * Page view setup
 * Event name setup, 404 and page views throttling due to NextJS routing
 */
let lastPageViewTimestamp = new Date(null);
export const logPageView = () => {
  const currentDate = new Date();
  if (
    Math.abs(lastPageViewTimestamp.getTime() - currentDate.getTime()) < 1000
  ) {
    // avoid duplicate page views
    return;
  }
  lastPageViewTimestamp = currentDate;

  // 404 check
  const pageName = document.querySelector('[data-404-page="true"]')
    ? '404'
    : document.title;

  tracking.logPageViewedEvent({ page_name: pageName });
};
