import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconAppliances({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-appliances"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 23C22 11.9543 30.9543 3 42 3L136 3L136 33H22V23ZM126 18C126 21.3137 123.314 24 120 24C116.686 24 114 21.3137 114 18C114 14.6863 116.686 12 120 12C123.314 12 126 14.6863 126 18ZM104 24C107.314 24 110 21.3137 110 18C110 14.6863 107.314 12 104 12C100.686 12 98 14.6863 98 18C98 21.3137 100.686 24 104 24ZM94 18C94 21.3137 91.3137 24 88 24C84.6863 24 82 21.3137 82 18C82 14.6863 84.6863 12 88 12C91.3137 12 94 14.6863 94 18Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 37L22 157H136V37H22ZM121 94C121 117.196 102.196 136 79 136C55.804 136 37 117.196 37 94C37 70.804 55.804 52 79 52C102.196 52 121 70.804 121 94Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M88.4086 96.0827C94.9385 101.03 104.137 101.176 110.871 96.8922C110.605 99.8625 109.933 102.716 108.911 105.397C103.544 108.925 96.0516 108.857 90.8206 104.891L90.8102 104.883C83.8882 99.7055 74.1128 99.7055 67.1907 104.883L67.1804 104.891C61.9341 108.869 54.4526 109.007 49.104 105.435C49.8746 107.449 50.8428 109.364 51.9848 111.159C57.873 112.928 64.5417 111.909 69.5916 108.083C75.0911 103.972 82.9097 103.972 88.4093 108.083C93.4834 111.927 100.169 112.872 106.057 111.093C103.914 114.479 101.152 117.435 97.9304 119.802C95.3852 119.447 92.9207 118.484 90.8206 116.891L90.8102 116.883C83.8882 111.706 74.1128 111.706 67.1907 116.883L67.1804 116.891C65.0854 118.48 62.634 119.456 60.1044 119.828C61.5914 120.918 63.176 121.882 64.8427 122.706C66.523 122.068 68.1255 121.194 69.5916 120.083C75.0911 115.972 82.9097 115.972 88.4093 120.083C89.8803 121.197 91.4868 122.068 93.17 122.699C88.8987 124.812 84.0879 126 78.9998 126C62.3133 126 48.6098 113.228 47.1318 96.9266C53.8819 101.282 63.0813 101.015 69.5909 96.0828C75.0904 91.9724 82.909 91.9724 88.4086 96.0827Z"
        fill={iconColor ?? colors.SnapOrange}
      />
    </svg>
  );
}
