import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconInfo({ iconColor, className, size = 'xs' }: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-info"
    >
      <g id="action/info_24px">
        <path
          id="icon/action/info_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
          fill={iconColor ?? colors.SnapGreen}
        />
      </g>
    </svg>
  );
}
