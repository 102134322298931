import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconJewelry({ iconColor, size = 'lg', className }: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-jewelry"
      className={className}
    >
      <path
        d="M79.9984 154.998L73.3152 146.929C72.4894 144.256 71.5518 141.234 70.5249 137.945L26.6664 58.9999L0.48517 58.9999L80 155.001L159.515 58.9999L133.334 58.9999L89.6711 137.593L86.7996 146.79L80.0017 154.998L80 155.001L79.9984 154.998Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M61.0065 17.0005L50.0502 52.9999L108.03 52.9999L97.8564 17.0005H61.0065Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M133.769 52.9999L159.923 52.9999L135 17.0005H119L133.769 52.9999Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M0.0773926 52.9999L26.2311 52.9999L41 17.0005H25L0.0773926 52.9999Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M50.3939 58.9999C56.5558 75.0991 64.2681 98.4143 70.6169 118.285C74.0781 129.118 77.1423 138.952 79.3411 146.079C79.5823 146.861 79.8131 147.61 80.0328 148.324L107.92 58.9999L50.3939 58.9999Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M79.9985 154.998L80.0001 155.001L80.0017 154.997L80.0006 154.999L80.0001 155.001L79.9995 154.999L79.9985 154.998Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
      <path
        d="M104.1 17.0005L114.274 52.9999H133.769L119 17.0005H104.1Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
      <path
        d="M133.334 58.9999H114.207L89.6711 137.593L133.334 58.9999Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
      <path
        d="M54.7324 17.0005L43.7761 52.9999H26.2311L41.0001 17.0005H54.7324Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
      <path
        d="M26.6664 58.9999H43.9527C50.1621 74.9138 58.2458 99.3076 64.894 120.115C66.9427 126.527 68.8522 132.589 70.5249 137.945L26.6664 58.9999Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
    </svg>
  );
}
