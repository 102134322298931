import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconSatisfiedCustomer({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-satisfied-customer"
    >
      <path
        d="M56.9541 66.0874L56.5654 66.5791L57.1312 66.8488C61.479 68.9214 64.5 73.3673 64.5 78.519V89.2532C64.5 90.4614 63.5263 91.4367 62.3333 91.4367H35.6667C34.4737 91.4367 33.5 90.4614 33.5 89.2532V78.519C33.5 73.3673 36.521 68.9214 40.8688 66.8488L41.4346 66.5791L41.0458 66.0874C39.6642 64.34 38.8333 62.1337 38.8333 59.7342C38.8333 54.0885 43.3975 49.5 49 49.5C54.6025 49.5 59.1667 54.0885 59.1667 59.7342C59.1667 62.1337 58.3357 64.34 56.9541 66.0874ZM59.6667 87.0696H60.1667V86.5696V78.519C60.1667 73.806 56.3577 69.9684 51.6667 69.9684H46.3333C41.6423 69.9684 37.8333 73.806 37.8333 78.519V86.5696V87.0696H38.3333H59.6667ZM54.8333 59.7342C54.8333 56.5013 52.2202 53.8671 49 53.8671C45.7798 53.8671 43.1667 56.5013 43.1667 59.7342C43.1667 62.9671 45.7798 65.6013 49 65.6013C52.2202 65.6013 54.8333 62.9671 54.8333 59.7342Z"
        fill={iconColor ?? colors.SnapDarkBlue}
        stroke="#062D4E"
      />
      <path
        d="M19.3669 28.7717C19.5662 28.1584 20.4338 28.1584 20.6331 28.7717L23.8918 38.801C23.9809 39.0752 24.2365 39.2609 24.5249 39.2609H35.0703C35.7151 39.2609 35.9833 40.0861 35.4616 40.4651L26.9302 46.6636C26.6968 46.8331 26.5992 47.1335 26.6883 47.4078L29.947 57.4371C30.1463 58.0504 29.4444 58.5604 28.9227 58.1813L20.3913 51.9829C20.158 51.8134 19.842 51.8134 19.6087 51.9829L11.0773 58.1813C10.5556 58.5604 9.85368 58.0504 10.053 57.4371L13.3117 47.4078C13.4008 47.1335 13.3032 46.8331 13.0698 46.6636L4.53843 40.4651C4.01674 40.0861 4.28485 39.2609 4.9297 39.2609H15.4751C15.7635 39.2609 16.0191 39.0752 16.1082 38.801L19.3669 28.7717Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M47.3669 5.94845C47.5662 5.33516 48.4338 5.33516 48.6331 5.94845L51.8918 15.9777C51.9809 16.252 52.2365 16.4377 52.5249 16.4377H63.0703C63.7151 16.4377 63.9833 17.2629 63.4616 17.6419L54.9302 23.8403C54.6968 24.0098 54.5992 24.3103 54.6883 24.5846L57.947 34.6139C58.1463 35.2271 57.4444 35.7371 56.9227 35.3581L48.3913 29.1597C48.158 28.9902 47.842 28.9902 47.6087 29.1597L39.0773 35.3581C38.5556 35.7371 37.8537 35.2271 38.053 34.6139L41.3117 24.5846C41.4008 24.3103 41.3032 24.0098 41.0698 23.8403L32.5384 17.6419C32.0167 17.2629 32.2849 16.4377 32.9297 16.4377H43.4751C43.7635 16.4377 44.0191 16.252 44.1082 15.9777L47.3669 5.94845Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M76.1316 28.7717C76.3308 28.1584 77.1985 28.1584 77.3977 28.7717L80.6564 38.801C80.7456 39.0752 81.0012 39.2609 81.2895 39.2609H91.8349C92.4798 39.2609 92.7479 40.0861 92.2262 40.4651L83.6948 46.6636C83.4615 46.8331 83.3639 47.1335 83.453 47.4078L86.7117 57.4371C86.911 58.0504 86.209 58.5604 85.6873 58.1813L77.1559 51.9829C76.9226 51.8134 76.6067 51.8134 76.3734 51.9829L67.842 58.1813C67.3203 58.5604 66.6183 58.0504 66.8176 57.4371L70.0763 47.4078C70.1654 47.1335 70.0678 46.8331 69.8345 46.6636L61.3031 40.4651C60.7814 40.0861 61.0495 39.2609 61.6943 39.2609H72.2398C72.5281 39.2609 72.7837 39.0752 72.8728 38.801L76.1316 28.7717Z"
        fill={iconColor ?? colors.SnapOrange}
      />
    </svg>
  );
}
