import { Industry } from '@ee-monorepo/shared/utilities/types';
import {
  IconMenuAppliances,
  IconMenuAll,
  IconMenuAutoService,
  IconMenuOther,
  IconMenuCarAudio,
  IconMenuCellPhones,
  IconMenuElectronics,
  IconMenuFurniture,
  IconMenuHomeDecor,
  IconMenuJewelry,
  IconMenuMattresses,
  IconMenuMusicalInstruments,
  IconMenuTiresRims,
  IconMenuAlarms,
  IconMenuHVAC,
  IconMenuLawnAndGarden,
  IconMenuMedicalDevices,
  IconMenuPowerTools,
  IconMenuScooters,
  IconMenuSportingGoods,
  IconMenuCollisionRepair,
} from './menu';
import { IconMenuProps, IconProps } from './constants';
import {
  IconAppliances,
  IconAll,
  IconAutoService,
  IconOther,
  IconCarAudio,
  IconCellPhones,
  IconElectronics,
  IconFurniture,
  IconHomeDecor,
  IconJewelry,
  IconMattresses,
  IconMusicalInstruments,
  IconTiresRims,
  IconAlarms,
  IconHVAC,
  IconLawnAndGarden,
  IconMedicalDevices,
  IconPowerTools,
  IconScooters,
  IconSportingGoods,
  IconCollisionRepair,
} from './shopping-categories';
/* eslint-disable complexity */
export function MenuIconIndustry(
  props: { industry: Industry } & IconMenuProps
) {
  const { industry, ...iconProps } = props;
  switch (industry) {
    case 'ALL':
      return <IconMenuAll {...iconProps} />;
    case 'HOME_AUTO_ALARMS':
      return <IconMenuAlarms {...iconProps} />;
    case 'AUTO_SERVICE':
      return <IconMenuAutoService {...iconProps} />;
    case 'APPLIANCES':
      return <IconMenuAppliances {...iconProps} />;
    case 'CAR_AUDIO':
      return <IconMenuCarAudio {...iconProps} />;
    case 'CELL_PHONES':
      return <IconMenuCellPhones {...iconProps} />;
    case 'DENTAL':
      return <IconMenuOther {...iconProps} />;
    case 'ELECTRONICS':
      return <IconMenuElectronics {...iconProps} />;
    case 'FURNITURE':
      return <IconMenuFurniture {...iconProps} />;
    case 'HOME_DECOR':
      return <IconMenuHomeDecor {...iconProps} />;
    case 'JEWELRY':
      return <IconMenuJewelry {...iconProps} />;
    case 'MATTRESSES':
      return <IconMenuMattresses {...iconProps} />;
    case 'MUSICAL_INSTRUMENTS':
      return <IconMenuMusicalInstruments {...iconProps} />;
    case 'TIRES_RIMS':
      return <IconMenuTiresRims {...iconProps} />;
    case 'HVAC':
      return <IconMenuHVAC {...iconProps} />;
    case 'LAWN_AND_GARDEN':
      return <IconMenuLawnAndGarden {...iconProps} />;
    case 'MEDICAL_DEVICES':
      return <IconMenuMedicalDevices {...iconProps} />;
    case 'POWER_TOOLS':
      return <IconMenuPowerTools {...iconProps} />;
    case 'SCOOTERS':
      return <IconMenuScooters {...iconProps} />;
    case 'SPORTING_GOODS':
      return <IconMenuSportingGoods {...iconProps} />;
    case 'COLLISION_REPAIR':
      return <IconMenuCollisionRepair {...iconProps} />;
  }
  return <IconMenuOther {...iconProps} />;
}

export function IconIndustry(props: { industry: Industry } & IconProps) {
  const { industry, ...iconProps } = props;
  switch (industry) {
    case 'ALL':
      return <IconAll {...iconProps} />;
    case 'HOME_AUTO_ALARMS':
      return <IconAlarms {...iconProps} />;
    case 'AUTO_SERVICE':
      return <IconAutoService {...iconProps} />;
    case 'APPLIANCES':
      return <IconAppliances {...iconProps} />;
    case 'CAR_AUDIO':
      return <IconCarAudio {...iconProps} />;
    case 'CELL_PHONES':
      return <IconCellPhones {...iconProps} />;
    case 'DENTAL':
      return <IconOther {...iconProps} />;
    case 'ELECTRONICS':
      return <IconElectronics {...iconProps} />;
    case 'FURNITURE':
      return <IconFurniture {...iconProps} />;
    case 'HOME_DECOR':
      return <IconHomeDecor {...iconProps} />;
    case 'JEWELRY':
      return <IconJewelry {...iconProps} />;
    case 'MATTRESSES':
      return <IconMattresses {...iconProps} />;
    case 'MUSICAL_INSTRUMENTS':
      return <IconMusicalInstruments {...iconProps} />;
    case 'TIRES_RIMS':
      return <IconTiresRims {...iconProps} />;
    case 'HVAC':
      return <IconHVAC {...iconProps} />;
    case 'LAWN_AND_GARDEN':
      return <IconLawnAndGarden {...iconProps} />;
    case 'MEDICAL_DEVICES':
      return <IconMedicalDevices {...iconProps} />;
    case 'POWER_TOOLS':
      return <IconPowerTools {...iconProps} />;
    case 'SCOOTERS':
      return <IconScooters {...iconProps} />;
    case 'SPORTING_GOODS':
      return <IconSportingGoods {...iconProps} />;
    case 'COLLISION_REPAIR':
      return <IconCollisionRepair {...iconProps} />;
  }
  return <IconOther {...iconProps} />;
}
