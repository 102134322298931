import { createContext } from 'react';
import SplitIO from '@splitsoftware/splitio-browserjs/types/splitio';

export const ExperimentsContext = createContext<{
  isReady: boolean;
  isTimedOut: boolean;
  client: SplitIO.IClient;
  //throw error if experiment is not active
  checkActiveExperiment: (experiment: string) => void;
  activeExperiments: string[];
}>({
  isReady: false,
  isTimedOut: false,
  client: {} as SplitIO.IClient | null,
  checkActiveExperiment: (experiment: string) => {},
  activeExperiments: [],
});
