import { appConfig } from '@ee-monorepo/shared/utilities/constants';
export const setGlobalCSSVariable = (
  propertyName: string,
  propertyValue: string
) => {
  const root = document.documentElement;
  root?.style.setProperty(propertyName, propertyValue);
};

export function includesMatch(
  lookupValue: string,
  inputString: string
): boolean {
  if (!!lookupValue && !!inputString) {
    const searchCriteria = new RegExp(lookupValue, 'i');
    return inputString.match(searchCriteria) !== null;
  }
  return false;
}

export function removeQueryParametersFromUrl(url: string): string {
  const urlSegments = url ? url.split('?') : [];
  if (urlSegments.length > 1) {
    return urlSegments[0];
  }
  return url;
}
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export const currencyFormatterWithoutDecimal = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
export const buildOffsetUsingPageNumber = (pageNumber: string) => {
  return buildOffsetUsingPageNumberInt(parseInt(pageNumber));
};

export const buildOffsetUsingPageNumberInt = (pageNumber: number) => {
  return pageNumber ? (pageNumber - 1) * appConfig.merchantRetrievalLimit : 0;
};
