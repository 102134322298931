import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconHVAC({ iconColor, size = 'lg', className }: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-hvac"
      className={className}
    >
      <path
        d="M16 50.0375L80 16L144 50.0375V144H16V50.0375Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M80 131C104.853 131 125 111.077 125 86.5C125 61.9233 104.853 42 80 42C55.1472 42 35 61.9233 35 86.5C35 111.077 55.1472 131 80 131Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.3489 47.9995C82.8717 47.9995 87.3489 52.4767 87.3489 57.9995V66.0121C89.6907 66.9161 91.8371 68.213 93.7073 69.8221L109.586 61.3042L112.274 66.3142C114.884 71.1811 113.055 77.2428 108.189 79.8536L101.126 83.6424C101.235 84.5272 101.291 85.4286 101.291 86.3431C101.291 87.6466 101.177 88.9234 100.957 90.164L116.599 99.8408L113.608 104.676C110.702 109.373 104.54 110.824 99.8428 107.919L92.8299 103.58C91.1757 104.861 89.3321 105.908 87.3484 106.674L87.3484 124.683H81.6629C76.1401 124.683 71.6629 120.206 71.6629 114.683L71.6629 106.674C69.3195 105.769 67.1718 104.471 65.3008 102.861L49.4255 111.377L46.7379 106.366C44.1272 101.5 45.9561 95.4379 50.8229 92.8272L57.8859 89.0384C57.7769 88.1552 57.7208 87.2557 57.7208 86.3431C57.7208 84.8963 57.8618 83.4825 58.1309 82.1147L43.1335 72.8366L46.1247 68.0016C49.0303 63.3049 55.1933 61.8529 59.89 64.7585L66.5122 68.8554C68.0803 67.6884 69.8107 66.7273 71.6634 66.0121V47.9995H77.3489ZM79.5052 97.6706C85.7617 97.6706 90.8336 92.5987 90.8336 86.3422C90.8336 80.0858 85.7617 75.0139 79.5052 75.0139C73.2488 75.0139 68.1769 80.0858 68.1769 86.3422C68.1769 92.5987 73.2488 97.6706 79.5052 97.6706Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
