import { trackToDataLayer } from '@ee-monorepo/tracking';

export const trackStoreLocatorSearchEvent = (searchTerm: string) => {
  if (searchTerm) {
    trackToDataLayer('store_locator_search_entered', {
      search_term: searchTerm,
    });
  }
};

export const trackMerchantLeadCreated = (createdLeadData: {
  [x: string]: unknown;
}) => {
  if (Object.keys(createdLeadData).length) {
    trackToDataLayer('merchant_lead_created', {
      ...createdLeadData,
      process: 'merchant lead acquisition',
    });
  }
};
