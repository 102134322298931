import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconPowerTools({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-power-tools"
      className={className}
    >
      <path
        d="M75 114H153V130.5C153 137.68 147.18 143.5 140 143.5H88C80.8203 143.5 75 137.68 75 130.5V114Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M55 22H140C147.18 22 153 27.8203 153 35V55C153 62.1797 147.18 68 140 68H55V22Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 42H36V48H8L6 42Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M29 37.1529C29 30.7365 33.6814 25.2798 40.0233 24.3041L55 22V68L40.0232 65.6959C33.6814 64.7202 29 59.2635 29 52.8471V37.1529Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
      <path
        d="M134 68V22"
        stroke={iconColor ?? colors.SnapLightBlue}
        strokeWidth="6"
      />
      <path
        d="M119 74H82V78.625C82 83.0433 85.5817 86.625 90 86.625H111C115.418 86.625 119 83.0433 119 78.625V74Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
      <path
        d="M126.444 74H93.4438L104.428 103.182C106.397 108.413 102.531 114 96.9408 114H92.5V134.875H141.5V114L126.444 74Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
