import {
    useCall,
    useFetchWithErrorTracking,
  } from '@ee-monorepo/shared/utilities/hooks';
  import { PlatformResponse } from '../types';
  
  export function useValidateCode() {
    const { fetchWithErrorTracking } = useFetchWithErrorTracking();
    const { call, data, loading, error } = useCall<
      PlatformResponse<{ result: boolean }>
    >((token: string, code: string) =>
      fetchWithErrorTracking(
        `${process.env['NEXT_PUBLIC_IDENTITY_API']}/id/token/code`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'no-referrer-when-downgrade',
          },
          body: JSON.stringify({ token, code }),
        }
      )
    );
    return {
      validateCode: call,
      validateCodeResponse: data,
      validateCodeLoading: loading,
      validateCodeError: error,
    };
  }
  