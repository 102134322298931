import * as React from 'react';
import { IMaskInput } from 'react-imask';
import { TextFieldProps } from '@mui/material';
import { TextField } from './shared-ui-text-field';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
  mask: string;
  maskedValue: boolean;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, mask, maskedValue, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={mask}
        onChange={() => {}}
        onAccept={(value: any, mask: any) =>
          onChange({
            target: {
              name: props.name,
              value: maskedValue ? value : mask._unmaskedValue,
            },
          })
        }
        overwrite
      />
    );
  }
);

interface FormattedTextFieldProps {
  mask: string;
  maskedValue: boolean;
  isPassword: boolean;
}

export const FormattedTextField = React.forwardRef(
  (props: TextFieldProps & FormattedTextFieldProps, ref) => {
    const { mask, maskedValue, isPassword, ...rest } = props;
    return (
      <TextField
        {...rest}
        InputProps={{
          inputComponent: TextMaskCustom as any,
          inputProps: { mask: props.mask, maskedValue: props.maskedValue },
        }}
        inputProps={{
          isPassword: props.isPassword,
        }}
      />
    );
  }
);
