import { getCookie } from '@ee-monorepo/shared/utilities/functions';
import { useEffect } from 'react';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';

export function useUIEventTracking() {
  useEffect(() => {
    const key = 'testVariation';
    const scriptElement = document.createElement('script');
    const domainNamesToIgnoreProdScript = ['beta', 'experiment'];
    sessionStorage.setItem(key, getCookie('testVariation') || `{}`);

    const isProd =
      appConfig?.useUIEventJSProduction &&
      !domainNamesToIgnoreProdScript.find((domainName) =>
        window.location.hostname.includes(domainName)
      );

    scriptElement.type = 'text/javascript';
    scriptElement.src = isProd
      ? 'https://amlp-ui.snapfinance.com/mini/prod_ui_event.js'
      : 'https://amlp-ui-qa.snapfinance.com/mini/qa_ui_event.js';
    scriptElement.id = 'uievents';
    document.body.appendChild(scriptElement);
  }, []);
}
