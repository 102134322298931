import { getColor } from '@ee-monorepo/shared/utilities/functions';
import cn from 'classnames';
import React, { useState } from 'react';
export enum TagBackgroundColor {
  'yellow' = 'SnapYellow',
  'lavender' = 'SnapLavender',
  'sand' = 'SnapSand',
  'light-blue' = 'SnapLightBlue',
  'grey-300' = 'SnapGrey300',
  'medium-blue' = 'SnapMediumBlue',
  'grey-100' = 'SnapGrey100',
}
export interface ColoredTagProps {
  backgroundColor: TagBackgroundColor;
  text: string;
  className?: string;
  hoverBackgroundColor?: TagBackgroundColor;
}

export function ColoredTag(props: ColoredTagProps) {
  const { text, backgroundColor, className, hoverBackgroundColor } = props;
  const [hover, setHover] = useState<TagBackgroundColor | null>(null);
  return (
    <div className={className}>
      {text ? (
        <span
          data-testid="tag"
          className={cn(
            'tagLargeFont text-SnapGrey500 inline-flex rounded justify-center items-center py-2 px-4 m-0',
            hover
              ? getColor(hover).backgroundColorClass
              : getColor(backgroundColor).backgroundColorClass
          )}
          onMouseOver={() => setHover(hoverBackgroundColor)}
          onMouseLeave={() => setHover(null)}
        >
          {text.toUpperCase()}
        </span>
      ) : null}
    </div>
  );
}

export default ColoredTag;
