import { useRouter } from 'next/router';
import { useMemo } from 'react';

export function useUTM() {
  const { query } = useRouter();
  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } =
    query as { [key: string]: string };
  return useMemo(
    () => ({
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    }),
    [utm_source, utm_medium, utm_campaign, utm_term, utm_content]
  );
}
