import { useCallback } from 'react';
import { useValidateCode } from '../api/account-takeover/useValidateCode';
import { ErrorType } from './types';
import { createCommandHook } from '@ee-monorepo/shared/utilities/hooks';

export const useValidateCodeCommand = createCommandHook(() => {
  const { validateCode, validateCodeLoading } = useValidateCode();

  const execute = useCallback(
    async (params: { token: string; code: string }) => {
      const response = await validateCode(params.token, params.code);
      const statusCode = response?.status;
      let errors: ErrorType[] = [];
      switch (statusCode) {
        case 200:
          errors = [];
          break;
        case 500:
        case 502:
          errors = [ErrorType.APPLICATION_ERROR];
          break;
        case 400:
          errors = [ErrorType.INCORRECT_DATA];
          break;
        default:
          errors = [ErrorType.CONNECTIVITY_ISSUE];
      }

      return {
        statusCode,
        errors,
        data: {
          ...(response?.data || {}),
        },
      };
    },
    [validateCode]
  );

  return {
    execute,
    executing: validateCodeLoading,
  };
});
