import { createContext, ReactNode, useContext, useState } from 'react';

export type NavigationType =
  | 'Standard navigation'
  | 'Clean navigation'
  | 'CTA with text banner';

export interface ProviderProps {
  title?: string;
  description?: string;
  image?: { url?: string };
  noindex?: boolean;
  navigation_bar_type?: NavigationType;
  navigation_text_banner?: string;
  show_customer_links?: boolean;
}

export const PageSEOProviderContext = createContext<ProviderProps>({});

export const PageSEOProvider = (props: {
  values: ProviderProps;
  children: ReactNode;
}) => {
  return (
    <PageSEOProviderContext.Provider value={props.values}>
      {props.children}
    </PageSEOProviderContext.Provider>
  );
};

export const usePageSEOProviderContext = () => {
  const pageSEO = useContext(PageSEOProviderContext);
  return {
    pageSEO,
  };
};
