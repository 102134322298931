import {
    useCall,
    useFetchWithErrorTracking,
  } from '@ee-monorepo/shared/utilities/hooks';
  import { PlatformResponse } from '../types';
  
  export function useCreateCode() {
    const { fetchWithErrorTracking } = useFetchWithErrorTracking();
    const { call, data, loading, error } = useCall<
      PlatformResponse<{ result: boolean }>
    >((token: string, value: string, optin: boolean) =>
      fetchWithErrorTracking(
        `${process.env['NEXT_PUBLIC_IDENTITY_API']}/id/token`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'no-referrer-when-downgrade',
          },
          body: JSON.stringify({ token, value, optin }),
        }
      )
    );
    return {
      createCode: call,
      createCodeResponse: data,
      createCodeLoading: loading,
      createCodeError: error,
    };
  }
  