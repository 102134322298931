import { IconMenuProps } from '../constants';

export function IconEyeClose({ iconColor, className }: IconMenuProps) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 20 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>4163A71C-E2A6-479D-ACB1-2FA7CF7F9526@1.00x</title>
      <defs>
        <path
          d="M10,4.97916667 C13.1583333,4.97916667 15.975,6.75416667 17.35,9.5625 C16.8583333,10.5791667 16.1666667,11.4541667 15.3416667,12.1625 L16.5166667,13.3375 C17.675,12.3125 18.5916667,11.0291667 19.1666667,9.5625 C17.725,5.90416667 14.1666667,3.3125 10,3.3125 C8.94166667,3.3125 7.925,3.47916667 6.96666667,3.7875 L8.34166667,5.1625 C8.88333333,5.05416667 9.43333333,4.97916667 10,4.97916667 Z M9.10833333,5.92916667 L10.8333333,7.65416667 C11.3083333,7.8625 11.6916667,8.24583333 11.9,8.72083333 L13.625,10.4458333 C13.6916667,10.1625 13.7416975,9.8625 13.7416975,9.55416667 C13.75,7.4875 12.0666667,5.8125 10,5.8125 C9.69166667,5.8125 9.4,5.85416667 9.10833333,5.92916667 Z M1.675,3.20416667 L3.90833333,5.4375 C2.55,6.50416667 1.475,7.92083333 0.833333333,9.5625 C2.275,13.2208333 5.83333333,15.8125 10,15.8125 C11.2666667,15.8125 12.4833333,15.5708333 13.6,15.1291667 L16.45,17.9791667 L17.625,16.8041667 L2.85,2.02083333 L1.675,3.20416667 Z M7.925,9.45416667 L10.1,11.6291667 C10.0666667,11.6375 10.0333333,11.6458333 10,11.6458333 C8.85,11.6458333 7.91666667,10.7125 7.91666667,9.5625 C7.91666667,9.52083333 7.925,9.49583333 7.925,9.45416667 L7.925,9.45416667 Z M5.09166667,6.62083333 L6.55,8.07916667 C6.35833333,8.5375 6.25,9.0375 6.25,9.5625 C6.25,11.6291667 7.93333333,13.3125 10,13.3125 C10.525,13.3125 11.025,13.2041667 11.475,13.0125 L12.2916667,13.8291667 C11.5583333,14.0291667 10.7916667,14.1458333 10,14.1458333 C6.84166667,14.1458333 4.025,12.3708333 2.65,9.5625 C3.23333333,8.37083333 4.08333333,7.3875 5.09166667,6.62083333 Z"
          id="hide-icon"
        ></path>
      </defs>
      <g
        id="Mobile-User-flow"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-Assets" transform="translate(-100.000000, -80.000000)">
          <g
            id="icon/action/visibility_off_24px"
            transform="translate(100.000000, 78.000000)"
          >
            <mask id="mask-2" fill="white">
              <use xlinkHref="#hide-icon"></use>
            </mask>
            <use
              fillOpacity="0.54"
              fill="#000000"
              fillRule="evenodd"
              xlinkHref="#hide-icon"
            ></use>
          </g>
        </g>
      </g>
    </svg>
  );
}
