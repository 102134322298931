import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuSportingGoods({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-sporting-goods"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 8.81811L8.81797 12.0001L11.9999 15.1821L15.1819 12.0001L11.9999 8.81811ZM3.69148 18.5408C3.30096 18.1503 3.30096 17.5172 3.69148 17.1266L4.04503 16.7731L7.22702 19.9551L6.87346 20.3086C6.48294 20.6991 5.84977 20.6991 5.45925 20.3086L3.69148 18.5408ZM16.773 4.04516L17.1265 3.6916C17.517 3.30108 18.1502 3.30108 18.5407 3.6916L20.3085 5.45937C20.699 5.84989 20.699 6.48306 20.3085 6.87358L19.9549 7.22714L16.773 4.04516Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.818 5.63611L18.3639 15.182L19.6014 13.9446C20.3824 13.1636 20.3824 11.8972 19.6014 11.1162L12.8839 4.39867C12.1028 3.61762 10.8365 3.61762 10.0554 4.39867L8.818 5.63611ZM5.63606 8.81811L15.182 18.364L13.9446 19.6015C13.1635 20.3825 11.8972 20.3825 11.1161 19.6015L4.39862 12.884C3.61757 12.1029 3.61757 10.8366 4.39862 10.0555L5.63606 8.81811ZM10.9393 20.4854L4.04501 13.5911L3.86823 13.7678C3.08718 14.5489 3.08718 15.8152 3.86823 16.5963L7.9341 20.6621C8.71514 21.4432 9.98147 21.4432 10.7625 20.6621L10.9393 20.4854ZM13.5909 4.04515L20.4852 10.9394L20.662 10.7627C21.443 9.98162 21.443 8.71529 20.662 7.93424L16.5961 3.86837C15.8151 3.08732 14.5488 3.08732 13.7677 3.86837L13.5909 4.04515Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
