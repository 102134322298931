import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuAll({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-all"
    >
      <path
        d="M2.75 3C2.33579 3 2 3.33579 2 3.75V5.25C2 5.66421 2.33579 6 2.75 6H4.25C4.66421 6 5 5.66421 5 5.25V3.75C5 3.33579 4.66421 3 4.25 3H2.75Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M8 3.5C7.44772 3.5 7 3.94772 7 4.5C7 5.05228 7.44772 5.5 8 5.5H21C21.5523 5.5 22 5.05228 22 4.5C22 3.94772 21.5523 3.5 21 3.5H8Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M7 9.5C7 8.94772 7.44772 8.5 8 8.5H21C21.5523 8.5 22 8.94772 22 9.5C22 10.0523 21.5523 10.5 21 10.5H8C7.44772 10.5 7 10.0523 7 9.5Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M8 13.5C7.44772 13.5 7 13.9477 7 14.5C7 15.0523 7.44772 15.5 8 15.5H21C21.5523 15.5 22 15.0523 22 14.5C22 13.9477 21.5523 13.5 21 13.5H8Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M7 19.5C7 18.9477 7.44772 18.5 8 18.5H21C21.5523 18.5 22 18.9477 22 19.5C22 20.0523 21.5523 20.5 21 20.5H8C7.44772 20.5 7 20.0523 7 19.5Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M2.75 8C2.33579 8 2 8.33579 2 8.75V10.25C2 10.6642 2.33579 11 2.75 11H4.25C4.66421 11 5 10.6642 5 10.25V8.75C5 8.33579 4.66421 8 4.25 8H2.75Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M2 13.75C2 13.3358 2.33579 13 2.75 13H4.25C4.66421 13 5 13.3358 5 13.75V15.25C5 15.6642 4.66421 16 4.25 16H2.75C2.33579 16 2 15.6642 2 15.25V13.75Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M2.75 18C2.33579 18 2 18.3358 2 18.75V20.25C2 20.6642 2.33579 21 2.75 21H4.25C4.66421 21 5 20.6642 5 20.25V18.75C5 18.3358 4.66421 18 4.25 18H2.75Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
