import { IconMenuProps } from '../constants';

export function IconMenuLanguage({ iconColor, className }: IconMenuProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99398 1.33398C4.31398 1.33398 1.33398 4.32065 1.33398 8.00065C1.33398 11.6807 4.31398 14.6673 7.99398 14.6673C11.6807 14.6673 14.6673 11.6807 14.6673 8.00065C14.6673 4.32065 11.6807 1.33398 7.99398 1.33398ZM12.614 5.33398H10.6473C10.434 4.50065 10.1273 3.70065 9.72732 2.96065C10.954 3.38065 11.974 4.23398 12.614 5.33398ZM8.00065 2.69398C8.55398 3.49398 8.98732 4.38065 9.27398 5.33398H6.72732C7.01398 4.38065 7.44732 3.49398 8.00065 2.69398ZM2.84065 9.33398C2.73398 8.90732 2.66732 8.46065 2.66732 8.00065C2.66732 7.54065 2.73398 7.09398 2.84065 6.66732H5.09398C5.04065 7.10732 5.00065 7.54732 5.00065 8.00065C5.00065 8.45398 5.04065 8.89398 5.09398 9.33398H2.84065ZM3.38732 10.6673H5.35398C5.56732 11.5007 5.87398 12.3007 6.27398 13.0407C5.04732 12.6207 4.02732 11.774 3.38732 10.6673ZM5.35398 5.33398H3.38732C4.02732 4.22732 5.04732 3.38065 6.27398 2.96065C5.87398 3.70065 5.56732 4.50065 5.35398 5.33398ZM8.00065 13.3073C7.44732 12.5073 7.01398 11.6207 6.72732 10.6673H9.27398C8.98732 11.6207 8.55398 12.5073 8.00065 13.3073ZM9.56065 9.33398H6.44065C6.38065 8.89398 6.33398 8.45398 6.33398 8.00065C6.33398 7.54732 6.38065 7.10065 6.44065 6.66732H9.56065C9.62065 7.10065 9.66732 7.54732 9.66732 8.00065C9.66732 8.45398 9.62065 8.89398 9.56065 9.33398ZM9.72732 13.0407C10.1273 12.3007 10.434 11.5007 10.6473 10.6673H12.614C11.974 11.7673 10.954 12.6207 9.72732 13.0407ZM10.9073 9.33398C10.9607 8.89398 11.0007 8.45398 11.0007 8.00065C11.0007 7.54732 10.9607 7.10732 10.9073 6.66732H13.1607C13.2673 7.09398 13.334 7.54065 13.334 8.00065C13.334 8.46065 13.2673 8.90732 13.1607 9.33398H10.9073Z"
        fill={iconColor}
      />
    </svg>
  );
}
