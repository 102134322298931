import { useCallback } from 'react';
import { useVerifyEmail } from '../api/account-takeover/useVerifyEmail';
import { ErrorType } from './types';
import { createCommandHook } from '@ee-monorepo/shared/utilities/hooks';

export const useVerifyEmailCommand = createCommandHook(() => {
  const { verifyEmail, verifyEmailLoading } = useVerifyEmail();

  const execute = useCallback(
    async (params: { email: string }) => {
      const response = await verifyEmail(params.email);
      if (!response) {
        return {
          success: false,
          errors: [ErrorType.CONNECTIVITY_ISSUE],
          data: undefined,
        };
      }
      const errors = !response.success
        ? response?.status === 500 || response?.status === 502
          ? [ErrorType.APPLICATION_ERROR]
          : [ErrorType.EMAIL_VALIDATION_SERVICE_ERROR]
        : [];

      return {
        success: response.success,
        errors,
        data: {
          ...response.data,
        },
      };
    },
    [verifyEmail]
  );

  return {
    execute,
    executing: verifyEmailLoading,
  };
});
