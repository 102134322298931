import * as yup from 'yup';

const REQUIRED = 'shared.forms.validations.required';
const DATE_FORMAT = 'shared.forms.validations.dateFormat';
const SSN_FORMAT = 'shared.forms.validations.invalidSsn';

const dateRegex = /(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d/;
export interface VerifyFormSchemaData {
  birthdate: string;
  ssn: string;
}

export const formSchema: yup.SchemaOf<VerifyFormSchemaData> = yup.object().shape({
  birthdate: yup.string().required(REQUIRED).matches(dateRegex, DATE_FORMAT),
  ssn: yup.string().required(REQUIRED).length(11, SSN_FORMAT),
});
