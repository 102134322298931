import { createContext, ReactNode, useContext, useState } from 'react';

export const MerchantShortFormContext = createContext<
  [boolean, (enabled: boolean) => void]
>([false, () => {}]);

export const MerchantShortFormProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [show, setShow] = useState(false);
  return (
    <MerchantShortFormContext.Provider value={[show, setShow]}>
      {children}
    </MerchantShortFormContext.Provider>
  );
};

export const useMerchantShortFormContext = () => {
  const [show, setShow] = useContext(MerchantShortFormContext);
  return {
    show,
    setShow,
  };
};
