import { IconMenuProps } from '../constants';

export function IconCheckboxChecked({ iconColor, className }: IconMenuProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5282_112169)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0Z"
          fill="#3D5CCF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.06 8.83334C19.5797 8.31365 19.5812 7.47153 19.0633 6.95001C18.5429 6.42588 17.6956 6.42438 17.1733 6.94667L9.33333 14.7867L6.83365 12.2967C6.3135 11.7785 5.4725 11.7779 4.95152 12.2952C4.42675 12.8163 4.42578 13.6647 4.94936 14.187L8.62623 17.8547C9.01689 18.2444 9.64938 18.244 10.0396 17.8538L19.06 8.83334Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_5282_112169">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
