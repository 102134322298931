import {
    useCall,
    useFetchWithErrorTracking,
  } from '@ee-monorepo/shared/utilities/hooks';
  import { PlatformResponse } from '../types';
  
  export function useVerifyToken() {
    const { fetchWithErrorTracking } = useFetchWithErrorTracking();
    const { call, data, loading, error } = useCall<
      PlatformResponse<{ result: boolean }>
    >((token: string) =>
      fetchWithErrorTracking(
        `${process.env['NEXT_PUBLIC_IDENTITY_API']}/id/token?token=${token}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'no-referrer-when-downgrade',
          }
        }
      )
    );
    return {
      verifyToken: call,
      verifyTokenResponse: data,
      verifyTokenLoading: loading,
      verifyTokenError: error,
    };
  }
  