import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconHomeDecor({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-home-decor"
      className={className}
    >
      <path
        d="M101.449 96V96.7869C93.733 101.658 88.6957 109.683 88.6957 118.759C88.6957 129.797 96.1484 139.283 106.809 143.428C111.437 145.227 115.953 147.696 118.11 152.168C118.187 152.328 118.264 152.488 118.341 152.648C118.886 153.78 119.428 154.906 120 156L40 156C40.538 154.971 41.0518 153.918 41.5659 152.86C43.8839 148.088 48.7305 145.484 53.7143 143.667C64.7135 139.657 72.4638 130.015 72.4638 118.759C72.4638 109.683 67.4264 101.658 59.7101 96.7869V96H101.449Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M116 111H110V127C110 128.657 111.343 130 113 130C114.657 130 116 128.657 116 127V111Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M54.7615 4C45.0647 4 36.7648 10.9559 35.0695 20.5033L31.8566 38.5968C41.085 43.9526 52.6096 42.8622 61.0082 35.2277L61.0182 35.2187C72.4195 24.9935 88.6461 24.9935 100.047 35.2187L100.057 35.2277C108.143 42.5774 119.157 43.7298 128.222 39.0421L128.95 43.1382C118.686 47.9116 106.434 46.4265 97.3718 38.1921C87.4921 29.3357 73.5735 29.3357 63.6938 38.1921C54.3521 46.6802 41.5895 48.1548 31.1187 42.7528L25.5069 74.3557C35.1869 85.0123 50.5302 85.6732 61.0082 76.1486L61.0182 76.1396C72.4195 65.9144 88.6461 65.9144 100.047 76.1396L100.057 76.1486C110.191 85.3604 124.926 84.8368 134.657 75.2815L135.481 79.9212C124.328 89.1592 108.462 89.1896 97.3718 79.113C87.4921 70.2566 73.5735 70.2566 63.6938 79.113C52.3148 89.4523 35.8598 89.3853 24.654 79.1593L19 111H141L122 4H54.7615Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
