import { colors } from '@snap/colors';

export interface IconAllProps {
  iconColor?: string;
  className?: string;
}

export function IconAll({ iconColor, className }: IconAllProps) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-all"
      className={className}
    >
      <path
        d="M4 5C3.44772 5 3 5.44772 3 6V8C3 8.55228 3.44772 9 4 9H6C6.55228 9 7 8.55228 7 8V6C7 5.44772 6.55228 5 6 5H4Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M4 11C3.44772 11 3 11.4477 3 12V14C3 14.5523 3.44772 15 4 15H6C6.55228 15 7 14.5523 7 14V12C7 11.4477 6.55228 11 6 11H4Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M3 18C3 17.4477 3.44772 17 4 17H6C6.55228 17 7 17.4477 7 18V20C7 20.5523 6.55228 21 6 21H4C3.44772 21 3 20.5523 3 20V18Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M4 23C3.44772 23 3 23.4477 3 24V26C3 26.5523 3.44772 27 4 27H6C6.55228 27 7 26.5523 7 26V24C7 23.4477 6.55228 23 6 23H4Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8H28C28.5523 8 29 7.55228 29 7C29 6.44772 28.5523 6 28 6H10Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14H28C28.5523 14 29 13.5523 29 13C29 12.4477 28.5523 12 28 12H10Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M9 19C9 18.4477 9.44772 18 10 18H28C28.5523 18 29 18.4477 29 19C29 19.5523 28.5523 20 28 20H10C9.44772 20 9 19.5523 9 19Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M10 24C9.44772 24 9 24.4477 9 25C9 25.5523 9.44772 26 10 26H28C28.5523 26 29 25.5523 29 25C29 24.4477 28.5523 24 28 24H10Z"
        fill={iconColor ?? colors.SnapOrange}
      />
    </svg>
  );
}
