import cookies from 'js-cookie';

/**
 * Store cookies
 *
 * @param key
 * @param value
 * @param expireMS
 * @param shareSubdomains for example when passing cookies to apply.finance.com
 */
export function storeCookie(
  key: string,
  value: any,
  expireMS?: number,
  shareSubdomains?: boolean
) {
  const expDate = new Date();

  // If sharing between subdomains, remove the 'www' from 'www.snapfinance.com'
  const domain = shareSubdomains ? sharedSubDomain() : window.location.hostname;

  if (expireMS) {
    const ms = expDate.getMilliseconds();

    //set expiration time in milliseconds
    expDate.setMilliseconds(ms + expireMS);
  }

  const options = {
    expires: expireMS ? expDate : null,
    domain: domain,
  };

  //store to cookies
  cookies.set(
    key,
    typeof value === 'object' ? JSON.stringify(value) : value,
    options
  );
}

/**
 * Get cookie by name
 * @param name
 * @returns
 */
export function getCookie(name: string) {
  return cookies.get(name);
}

/**
 * Remove cookie by name
 * @param name
 */
export function removeCookie(name: string, shareSubdomains: boolean) {
  const domain = shareSubdomains ? sharedSubDomain() : window.location.hostname;
  cookies.remove(name, { domain: domain });
}

function sharedSubDomain() {
  const host = window.location.hostname;
  return host === 'localhost' ? 'localhost' : 'snapfinance.com';
}
