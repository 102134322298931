import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuAppliances({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-appliances"
    >
      <path
        d="M13.6809 13.8718C14.847 14.7552 16.4896 14.7813 17.692 14.0163C17.431 16.9302 14.9826 19.2141 12.0008 19.2141C9.02103 19.2141 6.57398 16.9334 6.31006 14.0225C7.51542 14.8002 9.15817 14.7525 10.3206 13.8718C11.3027 13.1378 12.6988 13.1378 13.6809 13.8718Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1C5.68629 1 3 3.68629 3 7V23H21V1H9ZM15.0005 4.5C15.5528 4.5 16.0005 4.05228 16.0005 3.5C16.0005 2.94772 15.5528 2.5 15.0005 2.5C14.4482 2.5 14.0005 2.94772 14.0005 3.5C14.0005 4.05228 14.4482 4.5 15.0005 4.5ZM18.0005 4.5C18.5528 4.5 19.0005 4.05228 19.0005 3.5C19.0005 2.94772 18.5528 2.5 18.0005 2.5C17.4482 2.5 17.0005 2.94772 17.0005 3.5C17.0005 4.05228 17.4482 4.5 18.0005 4.5ZM12 21C16.1421 21 19.5 17.6421 19.5 13.5C19.5 9.35786 16.1421 6 12 6C7.85786 6 4.5 9.35786 4.5 13.5C4.5 17.6421 7.85786 21 12 21Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
