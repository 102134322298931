import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuMusicalInstruments({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-musical-instruments"
    >
      <path
        d="M21.4932 1L21.1848 1.07967L21.185 1.07744L12.3044 3.37652V11.8614C11.858 11.4706 11.2123 11.179 10.4176 11.179C8.66624 11.179 7.24658 12.5802 7.24658 14.3087C7.24658 16.0372 8.66624 17.4384 10.4176 17.4384C12.1689 17.4384 13.5885 16.0372 13.5885 14.3087L13.5884 11.8484C13.5883 10.368 13.5881 8.97715 13.5885 7.29872L20.2091 5.50132V9.9522C19.7626 9.56135 19.1169 9.2698 18.3222 9.2698C16.5709 9.2698 15.1512 10.671 15.1512 12.3995C15.1512 14.1279 16.5709 15.5291 18.3222 15.5291C20.0735 15.5291 21.4932 14.1279 21.4932 12.3995L21.493 9.94356C21.4929 8.46151 21.4928 7.06958 21.4932 5.3895V1Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M2 12.411C2 7.55068 5.61597 3.53494 10.3044 2.90723V9.18025C7.52441 9.2398 5.24658 11.4892 5.24658 14.3087C5.24658 17.1664 7.58647 19.4384 10.4176 19.4384C12.4958 19.4384 14.3094 18.214 15.127 16.4324C16.0098 17.1207 17.1218 17.5291 18.3222 17.5291C18.848 17.5291 19.3569 17.4508 19.837 17.3048C18.1653 20.1161 15.0972 22 11.589 22C6.29316 22 2 17.7068 2 12.411Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
