export function getInitialsFromName(name: string) {
  if (!name || name.length < 2) {
    return '';
  }
  // remove duplicate whitespaces and get array of words
  const words = name.replace(/\s+/g, ' ').split(' ');
  if (words.length > 1) {
    return `${words[0].charAt(0)}${words[1].charAt(0)}`;
  }
  return `${name.slice(0, 2)}`.toUpperCase();
}
