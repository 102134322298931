import * as yup from 'yup';

const REQUIRED = 'shared.forms.validations.required';
const VERIFICATION_CODE_LENGTH = 'shared.forms.validations.otpLength';

const INVALID_EMAIL = 'shared.forms.validations.email';
const PHONE_NUMBER_LENGTH = 'shared.forms.validations.phoneLength';

export interface OTPFormSchemaData {
  otp: string;
}

export const OTPFormSchema: yup.SchemaOf<OTPFormSchemaData> = yup
  .object()
  .shape({
    otp: yup.string().required(REQUIRED).length(6, VERIFICATION_CODE_LENGTH),
  });

export const ResendOTPFormSchema = yup.object().shape({
  phone: yup.string().when('type', {
    is: 'PHONE',
    then: yup.string().required(REQUIRED).length(10, PHONE_NUMBER_LENGTH),
    otherwise: yup.string().nullable(),
  }),
  email: yup.string().when('type', {
    is: 'EMAIL',
    then: yup.string().required(REQUIRED).email(INVALID_EMAIL),
    otherwise: yup.string().nullable(),
  }),
  type: yup.string(),
}) as yup.SchemaOf<ResendDialogFormSchemaData>;

export interface ResendDialogFormSchemaData {
  phone?: string | null;
  email?: string | null;
  type: string;
}
