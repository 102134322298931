import * as Prismic from '@prismicio/client';
import { enableAutoPreviews } from '@prismicio/next';

// eslint-disable-next-line node/no-process-env
export const repoName = process.env.NEXT_PUBLIC_PRISMIC_REPO;

export const apiEndpoint = Prismic.getEndpoint(repoName);

// eslint-disable-next-line node/no-process-env
export const accessToken = process.env.NEXT_PUBLIC_PRISMIC_REPO_ACCESS_TOKEN;

export const Router = {
  routes: [
    {
      type: 'learning_topic',
      path: '/:blogType/:category/:uid',
      resolvers: {
        category: 'category',
        blogType: 'category.blog_type',
      },
    },
  ],
};

export function createClient(config = {}) {
  const client = Prismic.createClient(
    apiEndpoint,
    Object.assign({ routes: Router.routes, ...config }, { accessToken })
  );

  enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
}
