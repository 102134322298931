import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconMerchants({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-merchants"
    >
      <path
        d="M87 50.4516V42.3613H15V50.4516H19V74.7226H59V50.4516H75V74.7226H83V50.4516H87ZM51 66.6323H27V50.4516H51V66.6323Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M19 10H83V18.0903H19V10Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M87 50.4516V42.3612L83 22.1355H19L15 42.3612V50.4516H87Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M18.0006 81C16.3506 81 15.0156 82.35 15.0156 84C15.0156 85.65 16.3506 87 18.0006 87C19.6506 87 21.0006 85.65 21.0006 84C21.0006 82.35 19.6506 81 18.0006 81ZM33.0006 81C31.3506 81 30.0156 82.35 30.0156 84C30.0156 85.65 31.3506 87 33.0006 87C34.6506 87 36.0006 85.65 36.0006 84C36.0006 82.35 34.6506 81 33.0006 81Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M9 57V60H12L17.4 71.385L15.375 75.06C15.135 75.48 15 75.975 15 76.5C15 78.15 16.35 79.5 18 79.5H36V76.5H18.63C18.42 76.5 18.255 76.335 18.255 76.125L18.3 75.945L19.65 73.5H30.825C31.95 73.5 32.94 72.885 33.45 71.955L38.82 62.22C38.94 62.01 39 61.755 39 61.5C39 60.675 38.325 60 37.5 60H15.315L13.905 57H9Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
