import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuAutoService({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-auto-service"
    >
      <path
        d="M4.33871 0H7.66128L8.5 5H7L7 11H7.5C8.32843 11 9 11.6716 9 12.5V20H3V12.5C3 11.6716 3.67157 11 4.5 11H5L5 5H3.5L4.33871 0Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M3 21H9L9 22.5C9 23.3284 8.32843 24 7.5 24H4.5C3.67157 24 3 23.3284 3 22.5V21Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M14.3222 0.726359C14.7436 -0.239682 15.8646 0.301854 15.8646 1.35581C15.8646 1.87331 16.2599 2.29283 16.7475 2.29283H18.2525C18.7401 2.29283 19.1353 1.87331 19.1353 1.35581C19.1353 0.301831 20.2564 -0.23973 20.6778 0.72633C20.8846 1.2004 21 1.72856 21 2.28533C21 3.75045 20.2009 5.01748 19.04 5.62215L19.4997 23.0225C19.5138 23.5586 19.0332 24.0001 18.4352 24.0001H16.5648C15.9668 24.0001 15.4862 23.5586 15.5003 23.0225L15.96 5.62215C14.7991 5.01748 14 3.75045 14 2.28533C14 1.72857 14.1154 1.20042 14.3222 0.726359Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
