import React, { useState, useRef } from 'react';
import Popper from '@mui/material/Popper';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { richTextSerializer } from '@ee-monorepo/prismic/shared/serializers';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import cn from 'classnames';
import { colors } from '@snap/colors';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import s from './tooltip-with-popper.module.scss';
import {
  IconClose,
  IconTooltip,
  IconTooltipFilled,
} from '@ee-monorepo/shared/ui/icons';
import { JSXFunctionSerializer, PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';

export interface TooltipWithPopperProps {
  mainContent: PrismicFields.RichTextField;
  popperContent: PrismicFields.RichTextField;
  iconTooltipColor?: string;
  toolTipFilled?: boolean;
  mainContentColor?: string;
  elementType: string;
  mainContentBold?: string;
  mainContentSerializer: JSXFunctionSerializer;
}

export function TooltipWithPopper({
  mainContent,
  popperContent,
  mainContentColor,
  mainContentSerializer,
  elementType,
  iconTooltipColor,
  toolTipFilled,
  mainContentBold,
}: TooltipWithPopperProps) {
  const [arrowRef, setArrowRef] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const mainContentRef = useRef(null);
  const { tracking } = useTrackingContext();
  const open = Boolean(anchorEl);
  const id = open ? 'tooltip_popper' : undefined;
  const isMobile = useHandHeld();

  const handleClick = (event) => {
    tracking.logClickEvent({
      click_type: 'button',
      placement: 'hero',
      click_value: 'info popup',
    });
    if (isMobile) {
      setAnchorEl(anchorEl ? null : mainContentRef.current);
    } else {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  return (
    <div data-testid={`${elementType}-popper-container`}>
      <div
        className={cn(
          s.mainContentContainer,
          elementType === 'paragraph'
            ? s.paragraphMarginSpace
            : s.headingMarginSpace,
          mainContentColor ? mainContentColor : 'text-SnapDarkBlue',
          mainContentBold ? mainContentBold : ''
        )}
        ref={mainContentRef}
      >
        <PrismicRichText
          field={mainContent}
          components={mainContentSerializer}
        />
        {prismicH.isFilled.richText(popperContent) && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="inline">
              <div
                className={cn('relative inline', s[`tooltip${elementType}`])}
              >
                {toolTipFilled ? (
                  <IconTooltipFilled
                    className={cn(s.cursor, s.tooltipIcon)}
                    aria-describedby={id}
                    iconColor={iconTooltipColor ?? colors.SnapGreen}
                    onClick={handleClick}
                  />
                ) : (
                  <IconTooltip
                    className={cn(s.cursor, s.tooltipIcon)}
                    aria-describedby={id}
                    iconColor={iconTooltipColor ?? colors.SnapGreen}
                    onClick={handleClick}
                  />
                )}
              </div>
              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom"
                className={s.popperContainer}
                modifiers={[
                  {
                    name: 'arrow',
                    enabled: true,
                    options: {
                      element: arrowRef,
                    },
                  },
                ]}
              >
                <div ref={setArrowRef} className={s.arrowUp}></div>
                <div
                  data-testid="popper-content"
                  className={cn(
                    'bg-SnapGrey500',
                    'text-SnapWhite',
                    'p-4 flex mt-5',
                    s.popper
                  )}
                >
                  <PrismicRichText
                    field={popperContent}
                    components={richTextSerializer}
                  />
                  <IconClose
                    className={s.cursor}
                    iconColor={colors.SnapWhite}
                    onClick={handleClickAway}
                  />
                </div>
              </Popper>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
}
