export enum ErrorType {
  APPLICATION_ERROR = 'APPLICATION_ERROR',
  SMS_OTP_CHECK_ERROR = 'SMS_OTP_CHECK_ERROR',
  EMAIL_VALIDATION_SERVICE_ERROR = 'EMAIL_VALIDATION_SERVICE_ERROR',
  TOKEN_ALREADY_VERIFIED = 'TOKEN_ALREADY_VERIFIED',
  INCORRECT_DATA = 'INCORRECT_DATA',
  MAXIMUM_ATTEMPT_REACHED = 'MAXIMUM_ATTEMPT_REACHED',
  TOKEN_INVALID = 'TOKEN_INVALID',
  CONNECTIVITY_ISSUE = 'CONNECTIVITY_ISSUE',
  INVALID_DATA = 'INVALID_DATA', //returned email/phone is invalid
}
