import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconChevronLeft({
  iconColor,
  className,
  size = 'xxxs',
}: IconMenuProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M15.0005 17.5895C15.3897 17.2003 15.3901 16.5692 15.0012 16.1795L11.1251 12.2949L15.0012 8.41031C15.3901 8.02061 15.3897 7.38958 15.0005 7.00031C14.6109 6.61073 13.9793 6.61073 13.5897 7.00031L8.68217 11.9078C8.46839 12.1216 8.46839 12.4682 8.68218 12.682L13.5897 17.5895C13.9793 17.9791 14.6109 17.9791 15.0005 17.5895Z"
        fill={iconColor ?? colors.SnapGrey400}
      />
    </svg>
  );
}
