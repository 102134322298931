import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconCellPhones({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-cell-phones"
      className={className}
    >
      <path
        d="M71 32.9998V22.9998C71 21.8952 71.8954 20.9998 73 20.9998H83C84.1046 20.9998 85 21.8952 85 22.9998V32.9998C85 34.1043 84.1046 34.9998 83 34.9998H73C71.8954 34.9998 71 34.1043 71 32.9998Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M98 32.9998V22.9998C98 21.8952 98.8954 20.9998 100 20.9998H110C111.105 20.9998 112 21.8952 112 22.9998V32.9998C112 34.1043 111.105 34.9998 110 34.9998H100C98.8954 34.9998 98 34.1043 98 32.9998Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M44 88.9998V78.9998C44 77.8952 44.8954 76.9998 46 76.9998H56C57.1046 76.9998 58 77.8952 58 78.9998V88.9998C58 90.1043 57.1046 90.9998 56 90.9998H46C44.8954 90.9998 44 90.1043 44 88.9998Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 24.9998C28 13.9541 36.9543 4.99976 48 4.99976L108 4.99976C119.046 4.99976 128 13.9541 128 24.9998L128 135C128 146.045 119.046 155 108 155H48C36.9543 155 28 146.045 28 135L28 24.9998ZM67 22.9998C67 19.686 69.6863 16.9998 73 16.9998H83C86.3137 16.9998 89 19.686 89 22.9998V32.9998C89 36.3135 86.3137 38.9998 83 38.9998H73C69.6863 38.9998 67 36.3135 67 32.9998V22.9998ZM94 22.9998C94 19.686 96.6863 16.9998 100 16.9998H110C113.314 16.9998 116 19.686 116 22.9998V32.9998C116 36.3135 113.314 38.9998 110 38.9998H100C96.6863 38.9998 94 36.3135 94 32.9998V22.9998ZM40 78.9998C40 75.6861 42.6863 72.9998 46 72.9998H56C59.3137 72.9998 62 75.6861 62 78.9998V88.9998C62 92.3135 59.3137 94.9998 56 94.9998H46C42.6863 94.9998 40 92.3135 40 88.9998V78.9998Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M46 16.9999C42.6863 16.9999 40 19.6862 40 22.9999V32.9999C40 36.3136 42.6863 38.9999 46 38.9999H56C59.3137 38.9999 62 36.3136 62 32.9999L62 22.9999C62 19.6862 59.3137 16.9999 56 16.9999H46Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M46 44.9999C42.6863 44.9999 40 47.6862 40 50.9999V60.9999C40 64.3136 42.6863 66.9999 46 66.9999H56C59.3137 66.9999 62 64.3136 62 60.9999L62 50.9999C62 47.6862 59.3137 44.9999 56 44.9999H46Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M94 50.9999C94 47.6862 96.6863 44.9999 100 44.9999H110C113.314 44.9999 116 47.6862 116 50.9999V60.9999C116 64.3136 113.314 66.9999 110 66.9999H100C96.6863 66.9999 94 64.3136 94 60.9999V50.9999Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M73 44.9999C69.6863 44.9999 67 47.6862 67 50.9999V60.9999C67 64.3136 69.6863 66.9999 73 66.9999H83C86.3137 66.9999 89 64.3136 89 60.9999V50.9999C89 47.6862 86.3137 44.9999 83 44.9999H73Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M78 147C82.4183 147 86 143.418 86 139C86 134.582 82.4183 131 78 131C73.5817 131 70 134.582 70 139C70 143.418 73.5817 147 78 147Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
    </svg>
  );
}
