import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconExpandReach({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-expand-reach"
    >
      <g clipPath="url(#clip0_110_119)">
        <g clipPath="url(#clip1_110_119)">
          <path
            d="M48 9.59961C26.8032 9.59961 9.59998 26.8028 9.59998 47.9996C9.59998 69.1964 26.8032 86.3996 48 86.3996C69.1968 86.3996 86.4 69.1964 86.4 47.9996C86.4 26.8028 69.1968 9.59961 48 9.59961ZM44.16 78.4508C28.992 76.5692 17.28 63.6668 17.28 47.9996C17.28 45.6188 17.5872 43.3532 18.0864 41.126L36.48 59.5196V63.3596C36.48 67.5836 39.936 71.0396 44.16 71.0396V78.4508ZM70.656 68.6972C69.6576 65.5868 66.816 63.3596 63.36 63.3596H59.52L55.68 53.3996C55.68 51.2876 38.16 48.8396 42 44.9996L32.64 47.9996L28.8 36.4796L36.48 34.1996C38.592 34.1996 42 38.5916 42 36.4796L44.16 28.7996H51.84C56.064 28.7996 59.52 25.3436 59.52 21.1196V19.5452C70.7712 24.1148 78.72 35.1356 78.72 47.9996C78.72 55.9868 75.648 63.2444 70.656 68.6972Z"
            fill={iconColor ?? colors.SnapDarkBlue}
          />
          <path
            d="M86.6647 9.60189C81.5418 9.60189 77.3316 13.802 77.3316 18.912C77.3316 21.0125 78.0552 22.9171 79.2484 24.4813L63.4151 47.0501C62.7445 46.8951 62.0469 46.8422 61.3317 46.8422C58.7284 46.8422 56.3643 47.8906 54.665 49.6275L43.4988 43.1433C43.8188 42.2081 43.9992 41.2302 43.9992 40.192C43.9992 35.0818 39.7887 30.8819 34.6661 30.8819C29.5435 30.8819 25.333 35.082 25.333 40.192C25.333 42.2925 26.0566 44.1971 27.2498 45.7613L11.4165 68.3301C10.7459 68.1752 10.0483 68.1223 9.33312 68.1223C4.21025 68.1223 0 72.3224 0 77.4324C0 82.5424 4.21049 86.7425 9.33312 86.7425C14.4558 86.7425 18.6662 82.5424 18.6662 77.4324C18.6662 74.9385 17.6627 72.6266 16.0417 70.9482L31.5003 48.9193C32.4918 49.2811 33.5558 49.501 34.6672 49.501C36.8334 49.501 38.8265 48.7179 40.4168 47.4645L52.208 54.3226C52.088 54.9145 51.9997 55.5268 51.9997 56.1512C51.9997 61.2614 56.2101 65.4613 61.3328 65.4613C66.4554 65.4613 70.6659 61.2612 70.6659 56.1512C70.6659 53.6574 69.6624 51.3455 68.0414 49.667L83.5 27.6381C84.4914 27.9999 85.5554 28.2198 86.6669 28.2198C91.7898 28.2198 96 24.0197 96 18.9097C96 13.7997 91.7895 9.59961 86.6669 9.59961L86.6647 9.60189ZM86.6647 14.9216C88.9052 14.9216 90.6649 16.6769 90.6649 18.912C90.6649 21.147 88.9053 22.9024 86.6647 22.9024C84.4241 22.9024 82.6645 21.147 82.6645 18.912C82.6645 16.6769 84.4241 14.9216 86.6647 14.9216ZM34.6646 36.2028C36.9051 36.2028 38.6648 37.9582 38.6648 40.1932C38.6648 42.4282 36.9051 44.1836 34.6646 44.1836C32.424 44.1836 30.6643 42.4282 30.6643 40.1932C30.6643 37.9582 32.424 36.2028 34.6646 36.2028ZM61.3313 52.1631C63.5718 52.1631 65.3316 53.9175 65.3316 56.1535C65.3316 58.3885 63.5719 60.1439 61.3313 60.1439C59.0908 60.1439 57.3311 58.3886 57.3311 56.1535C57.3311 53.9185 59.0907 52.1631 61.3313 52.1631ZM9.33117 73.4444C11.5717 73.4444 13.3314 75.1988 13.3314 77.4347C13.3314 79.6697 11.5717 81.4251 9.33117 81.4251C7.09059 81.4251 5.33092 79.6698 5.33092 77.4347C5.33092 75.1997 7.09059 73.4444 9.33117 73.4444Z"
            fill={iconColor ?? colors.SnapLightBlue}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_110_119">
          <rect width="96" height="96" fill="white" />
        </clipPath>
        <clipPath id="clip1_110_119">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
