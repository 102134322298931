import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconMusicalInstruments({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-musical-instruments"
      className={className}
    >
      <path
        d="M149.972 85H10.0281C10.2655 93.4612 12.0043 101.547 14.9877 109H145.012C147.996 101.547 149.735 93.4612 149.972 85Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M143.264 113H16.7364C27.971 136.649 52.0761 153 80.0001 153C107.924 153 132.029 136.649 143.264 113Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M149.972 81C149.735 72.5388 147.996 64.4531 145.012 57H14.9877C12.0043 64.4531 10.2655 72.5388 10.0281 81H149.972Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M16.7364 53H143.264C132.029 29.3505 107.924 13 80.0001 13C52.0761 13 27.971 29.3505 16.7364 53Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M142.749 7.58159L145 7V39.0433C144.997 51.3079 144.998 61.4691 144.999 72.288C144.999 77.9517 145 83.7957 145 90.2161C145 102.834 134.636 113.063 121.852 113.063C109.067 113.063 98.7036 102.834 98.7036 90.2161C98.7036 77.5983 109.067 67.3695 121.852 67.3695C127.653 67.3695 132.367 69.4978 135.626 72.3511V39.8596L87.2964 52.9807C87.2934 65.2452 87.2943 75.4064 87.2953 86.2253C87.2958 91.8891 87.2964 97.733 87.2964 104.153C87.2964 116.771 76.9328 127 64.1482 127C51.3635 127 41 116.771 41 104.153C41 91.5356 51.3635 81.3069 64.1482 81.3069C69.9495 81.3069 74.6634 83.4352 77.9224 86.2884V24.3486L142.75 7.56534L142.749 7.58159Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
