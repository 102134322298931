import React, { ReactNode, useEffect, useState } from 'react';
import { FlowContext, FlowStepContext } from './context';
import { FlowModel, FlowRouter } from './types';
import { resolveRouting, routeLaunch } from './route-resolver';

/**
 * Flow orchestrator
 *
 * provides decisioning on which flow model is running
 */
export interface FlowProviderProps {
  children: React.ReactNode;
  model: FlowModel;
  router: FlowRouter;
}
export function FlowProvider({
  model: _model,
  router,
  children,
}: FlowProviderProps) {
  const [model, setModel] = useState(_model);
  useEffect(() => {
    if (_model) {
      setModel(_model);
    }
  }, [_model, setModel]);

  return (
    <FlowContext.Provider
      value={{
        model,
        launch: (args) => {
          routeLaunch(model, router, args);
        },
        back: (stepName) => {
          resolveRouting(stepName, model, router, 'back');
        },
        next: (stepName, args) => {
          resolveRouting(stepName, model, router, 'next', args);
        },
      }}
    >
      {children}
    </FlowContext.Provider>
  );
}

/**
 * Provides the step name, to be used as flow step key name
 *
 * Then page children components or form will know the current step name
 */
export function FlowStepProvider({
  stepName,
  children,
}: {
  stepName: string;
  children: ReactNode;
}) {
  return (
    <FlowStepContext.Provider value={stepName}>
      {children}
    </FlowStepContext.Provider>
  );
}
