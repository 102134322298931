import cn from 'classnames';
import { RichTextField } from '@ee-monorepo/prismic/shared/types';
import { richTextSerializer } from '@ee-monorepo/prismic/shared/serializers';
import { getColor } from '@ee-monorepo/shared/utilities/functions';
import s from './title.module.scss';
import { PrismicRichText } from '@prismicio/react';
import { HTMLProps } from 'react';
export interface TitleProps extends HTMLProps<HTMLDivElement> {
  text: RichTextField;
  bgColor?: string;
}

export function Title(props: TitleProps) {
  const { text, bgColor, ...otherProps } = props;

  return (
    <div
      data-testid="header-title"
      className={cn(
        'md:py-16 py-12 bg-SnapMediumBlue',
        getColor(bgColor || 'SnapYellow').backgroundColorClass
      )}
      {...otherProps}
    >
      <div
        className={cn(s.titleContainer, 'snap-container text-SnapDarkBlue')}
        data-testid="title-container"
      >
        {text ? (
          <PrismicRichText field={text} components={richTextSerializer} />
        ) : null}
      </div>
    </div>
  );
}

export default Title;
