// THIS IS A SHORT TERM FIX AND WILL BE REMOVED EVENTUALLY
// input param is event name - any string is allowed.
export function trackToDataLayer(
  eventName: string,
  properties: { [key: string]: unknown } = {}
) {
  window['dataLayer'] = window['dataLayer'] || [];
  window['dataLayer'].push({
    event: eventName,
    ...properties,
  });
}
