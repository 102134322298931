import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { Link } from '@ee-monorepo/prismic/shared/types';
import {
  includesMatch,
  removeQueryParametersFromUrl,
} from '@ee-monorepo/shared/utilities/functions';
import { LinkType, RichTextField } from '@prismicio/types';
import { NextRouter } from 'next/router';
import * as prismicH from '@prismicio/helpers';

// Manages links to internal Prismic documents
export const linkResolver = (doc: Link) => {
  switch (doc.type) {
    case 'home':
      return '/';
    case 'error':
      return '/404';
    case 'pillar':
      return `/finance/${doc.uid}`;
    case 'stack':
      return `/${doc.uid}`;
    case 'open_position':
      return `/jobs/${doc.uid}`;
    case 'legal_disclosure':
      return `/legal/${doc.uid}`;
    case 'ccpa_form':
      return `/${doc.uid}`;
    case 'in_store_onboarding':
      return '/merchant-apply/tell-us-about-you';
    case 'ecommerce_inquiry':
      return `/${doc.uid}`;
    case 'learning_topic':
      return doc?.url;
    case 'business_page':
      return `/partner/${doc.uid}`;
    case 'landing_page':
      return `/${doc.uid}`;
    case 'ent_landing_page':
      return `/enterprise/${doc.uid}`;
    default:
      return '/';
  }
};

export const disablePrismicImgOptimization = (imageUrl: string): string => {
  if (!!imageUrl && includesMatch('prismic.io', imageUrl)) {
    const modifiedImageUrl = removeQueryParametersFromUrl(imageUrl);
    return modifiedImageUrl;
  }
  return imageUrl;
};

export const checkMatchingPathname = (
  router: NextRouter,
  link: PrismicFields.Link
) => {
  if (!link) return false;
  if (link?.link_type === LinkType.Document) {
    return router.asPath === linkResolver(link);
  } else {
    const regex = /https:\/\/(www\.)?snapfinance\.com(\/.*)?/;
    return regex.test(link.url as string)
      ? router.asPath === new URL(link?.url as string).pathname
      : false;
  }
};

/**
 * Takes a rich text and replaces place holders inside its text
 * @param richText to be formatted
 * @param replace an array with all place holders and substitutes for the formatted text
 * @returns the rich text with the new formatted text
 */
export const replaceRichTextPlaceholder = (
  richText: RichTextField,
  replace: { placeholder: string; substitute: string }[]
) => {
  if (!richText || !prismicH.isFilled.richText(richText)) return richText;

  //get text and replaces all placeholders
  let text = prismicH.asText(richText);
  replace.forEach((item) => {
    text = text.replace(item.placeholder, item.substitute);
  });

  //restore the text back
  if ('text' in richText[0]) richText[0].text = text;

  return richText;
};
