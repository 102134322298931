import { useCallback } from 'react';

export function useConfetti(): useConfettiOutputs {
  const maxParticleCount = 150; //set max confetti count
  const particleSpeed = 2; //set the particle animation speed
  const colors = ['#67B25E', '#5FA4F9', '#FFCA4D', '#DC7F4A'];
  let streamingConfetti = false;
  let animationTimer: number | null = null;
  let particles: any[] = [];
  let waveAngle = 0;

  const resetParticle = (particle: any, width: number, height: number) => {
    particle.color = colors[(Math.random() * colors.length) | 0];
    particle.x = Math.random() * width;
    particle.y = Math.random() * height - height;
    particle.diameter = Math.random() * 5 + 5;
    particle.tilt = Math.random() * 10 - 10;
    particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
    particle.tiltAngle = 0;
    return particle;
  };

  const drawParticles = (context: CanvasRenderingContext2D) => {
    for (let i = 0; i < particles.length; i++) {
      const particle = particles[i];
      context.beginPath();
      context.lineWidth = particle.diameter;
      context.strokeStyle = particle.color;
      const x = particle.x + particle.tilt;
      context.moveTo(x + particle.diameter / 2, particle.y);
      context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
      context.stroke();
    }
  };

  const updateParticles = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    waveAngle += waveAngle;
    for (let i = 0; i < particles.length; i++) {
      const particle = particles[i];
      if (!streamingConfetti && particle.y < -15) particle.y = height + 100;
      else {
        particle.tiltAngle += particle.tiltAngleIncrement;
        particle.x += Math.sin(waveAngle);
        particle.y +=
          (Math.cos(waveAngle) + particle.diameter + particleSpeed) * 0.5;
        particle.tilt = Math.sin(particle.tiltAngle) * 15;
      }
      if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
        if (streamingConfetti && particles.length <= maxParticleCount)
          resetParticle(particle, width, height);
        else {
          particles.splice(i, 1);
          i--;
        }
      }
    }
  };

  const startConfettiAnimation = useCallback(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const requestAnimFrame =
      window.requestAnimationFrame ||
      function (callback) {
        return window.setTimeout(callback, 16.6666667);
      };
    let canvas = <HTMLCanvasElement>document.getElementById('confetti-canvas');
    if (canvas === null) {
      canvas = document.createElement('canvas');
      canvas.setAttribute('id', 'confetti-canvas');
      canvas.setAttribute(
        'style',
        'display: block; z-index: 999999; pointer-events: none; position: absolute; top: 0;'
      );
      document.body.appendChild(canvas);
      canvas.width = width;
      canvas.height = height;
      window.addEventListener(
        'resize',
        function () {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
        },
        true
      );
    }
    const context = canvas.getContext('2d');
    while (particles.length < maxParticleCount) {
      particles.push(resetParticle({}, width, height));
    }
    streamingConfetti = true;
    if (animationTimer === null) {
      const runAnimation = () => {
        context.clearRect(0, 0, window.innerWidth, window.innerHeight);
        if (particles.length === 0) {
          animationTimer = null;
        } else {
          updateParticles();
          drawParticles(context);
          animationTimer = requestAnimFrame(runAnimation);
        }
      };
      runAnimation();
    }
  }, [
    animationTimer,
    particles,
    streamingConfetti,
    maxParticleCount,
    updateParticles,
    drawParticles,
  ]);

  const stopConfettiAnimation = useCallback(() => {
    streamingConfetti = false;
    updateParticles();
  }, [streamingConfetti, updateParticles]);

  const removeConfetti = useCallback(() => {
    stopConfettiAnimation();
    particles = [];
  }, [stopConfettiAnimation, particles]);

  const toggleConfettiAnimation = useCallback(() => {
    if (streamingConfetti) {
      stopConfettiAnimation();
    } else {
      startConfettiAnimation();
    }
  }, [streamingConfetti, stopConfettiAnimation, startConfettiAnimation]);

  return {
    startConfettiAnimation,
    stopConfettiAnimation,
    removeConfetti,
    toggleConfettiAnimation,
  };
}

export interface useConfettiOutputs {
  startConfettiAnimation: () => void;
  stopConfettiAnimation: () => void;
  removeConfetti: () => void;
  toggleConfettiAnimation: () => void;
}
