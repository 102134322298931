import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuHomeDecor({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-home-decor"
    >
      <path
        d="M9.95113 1C7.05824 1 4.57726 3.06422 4.05118 5.90887L3.60853 8.30236C4.89685 9.59447 6.83129 9.63862 8.17898 8.42439L8.18398 8.41988C10.2625 6.57226 13.2448 6.57227 15.3233 8.4199L15.3283 8.42438C16.52 9.49799 18.1902 9.56752 19.4589 8.66712L18.041 1H9.95113Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M19.8457 10.7583C17.9541 11.6715 15.661 11.4249 13.9738 9.90686C12.6628 8.7433 10.8445 8.74329 9.53349 9.90682C7.71512 11.543 5.17455 11.7355 3.19825 10.5208L2 17H10.6538C10.6719 17.136 10.6812 17.2741 10.6812 17.4138C10.6812 19.2625 9.05525 20.8208 6.83738 21.298C6.29266 21.4151 5.77161 21.6719 5.48967 22.1525C5.42208 22.2677 5.35633 22.3837 5.29078 22.4994C5.19527 22.668 5.09992 22.8363 5 23H19C18.9 22.8361 18.8051 22.6671 18.7098 22.4974C18.6308 22.3568 18.5514 22.2153 18.4685 22.0758C18.2018 21.6269 17.7154 21.3845 17.2078 21.262C15.071 20.7463 13.5217 19.2184 13.5217 17.4138C13.5217 17.2741 13.531 17.136 13.5491 17H17V18C17 18.5523 17.4477 19 18 19C18.5523 19 19 18.5523 19 18V17H21L19.8457 10.7583Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
