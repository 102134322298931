import { colors } from '@snap/colors';
import { IconMenuProps } from '../constants';

export function IconTwitter({ iconColor, className }: IconMenuProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-twitter"
    >
      <path
        d="M20 3.52049C19.2691 3.86885 18.4718 4.07787 17.6744 4.21721C18.5382 3.65984 19.1362 2.82377 19.4684 1.84836C18.6711 2.33607 17.8073 2.68443 16.8771 2.89344C16.0797 1.9877 15.0166 1.5 13.887 1.5C11.6279 1.5 9.76744 3.45082 9.76744 5.81967C9.76744 6.16803 9.83389 6.51639 9.90033 6.79508C6.51163 6.58607 3.45515 4.91393 1.46179 2.26639C1.12957 2.89344 0.930233 3.65984 0.930233 4.42623C0.930233 5.88934 1.66113 7.21311 2.72425 7.97951C2.0598 7.97951 1.39535 7.77049 0.863787 7.4918V7.56148C0.863787 9.65164 2.25914 11.3934 4.1196 11.7418C3.78738 11.8115 3.3887 11.8811 3.05648 11.8811C2.7907 11.8811 2.52492 11.8811 2.25914 11.8115C2.7907 13.4836 4.31894 14.7377 6.04651 14.8074C4.65116 15.9918 2.85714 16.6189 0.996678 16.6189C0.664452 16.6189 0.332226 16.6189 0 16.5492C1.79402 17.7336 3.98671 18.5 6.24585 18.5C13.7542 18.5 17.8738 11.9508 17.8738 6.30738C17.8738 6.09836 17.8738 5.95902 17.8738 5.75C18.7375 5.12295 19.4684 4.35656 20 3.52049Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
