import { useMemo } from 'react';
import {
  getSessionId,
  getUserId,
} from '@ee-monorepo/shared/utilities/unique-id';

export function useSessionId() {
  const sessionId = useMemo(() => getSessionId(), [getSessionId]); // memo to avoid value re-set

  return {
    sessionId,
  };
}

export function useUserId() {
  const userId = useMemo(() => getUserId(), [getUserId]); // memo to avoid value re-set

  return {
    userId,
  };
}
