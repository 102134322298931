import {
  AppProcessEventProps,
  ApplicationProcessEventName,
  ClickEventProps,
  ContentViewedEventProps,
  EventName,
  FunctionNotInitializedError,
  GetEngagementEventPropsFuntionType,
  GetSuperEventPropsFuntionType,
  InvalidPropertiesError,
  PageViewedEventProps,
  MerchantLeadEventProps,
  OnErrorFuntionType,
  TrackingInterface,
  PersonAvailableEventProps,
  SearchEventProps,
  SignAgreementProps,
  SlideEventProps,
  StoreSelectedEventProps,
  SystemEventName,
  TrackingPropsInterface,
  UserProperties,
  Vendor,
  ApplicationProcessEventProps,
} from './contracts';

export class Tracking implements TrackingInterface {
  /**
   * logEvent will be called in each log method.
   * This method will be defined on each application and passed thought the constructor of this class
   */
  private _vendor: Vendor;

  /**
   * function that returns the super properties.
   * This method will be defined on each application and passed thought the constructor of this class
   */
  private getSuperEventProps: GetSuperEventPropsFuntionType;

  /**
   * Function that returns engagement properties.
   * This method will be defined on each application and passed thought the constructor of this class
   */
  private getEngagementEventProps: GetEngagementEventPropsFuntionType;

  /**
   * Function that handle the error
   * In case that we want to control errors for logging them or catch them
   */
  private onError: OnErrorFuntionType | undefined;

  /**
   * constructor
   * @param vendor abstraction for tracking vendor implementation
   * @param getSuperEventProps function that returns the super properties of the app
   * @param getEngagementEventProps funtion that returns the engagement properties
   * @param onError function for catching errors from the outsite
   */
  constructor(props: TrackingPropsInterface) {
    this._vendor = props.vendor;
    this.onError = props.onError;
    this.getSuperEventProps = props.getSuperEventProps
      ? props.getSuperEventProps
      : () => {
          throw new FunctionNotInitializedError('getSuperEventProps');
        };
    this.getEngagementEventProps = props.getEngagementEventProps
      ? props.getEngagementEventProps
      : () => {
          throw new FunctionNotInitializedError('getEngagementEventProps');
        };
  }

  setUserProperties(properties: Partial<UserProperties>) {
    this._vendor.setUserProperties(properties);
  }

  /**
   * logPageViewedEvent for all the page viewed events - mostly called on page load
   * @param props properties of the event
   */
  logPageViewedEvent(props: PageViewedEventProps) {
    const engagementProps = this.getEngagementEventProps();
    const properties = props.page_name
      ? { ...engagementProps, ...props }
      : engagementProps;
    this.logEventWithSuperProps('page viewed', properties);
  }

  /**
   * logSystemEvent to log any system event
   * @param eventName name of the event: log or error
   * @param props object with properties
   */
  logSystemEvent(eventName: SystemEventName, props: object) {
    this._vendor.logEvent(eventName, props);
  }

  /**
   * logProcessEvent: open for any process evemt
   * @param eventName as string with the name of the process
   * @param props object with properties
   */
  logProcessEvent(eventName: string, props: object) {
    this._vendor.logEvent(eventName, props);
  }

  /**
   * logContentViewedEvent for all the content viewed events - mostly called to track the Errors
   * @param {ContentViewedEventProps} props properties of the event
   * @throws InvalidPropertiesError
   */
  logContentViewedEvent(props: ContentViewedEventProps) {
    if (this.checkProps(props)) {
      this.logEventWithSuperProps('content viewed', {
        ...this.getEngagementEventProps(),
        ...props,
      });
    }
  }

  /**
   * logClickEvent for all the click type events
   * @param {ClickEventProps} props properties of the event
   * @throws InvalidPropertiesError
   */
  logClickEvent(props: ClickEventProps) {
    if (this.checkProps(props)) {
      const { click_value, ...rest } = props;
      this.logEventWithSuperProps('click', {
        ...this.getEngagementEventProps(),
        click_value: click_value.toLowerCase(),
        ...rest,
      });
    }
  }

  /**
   * logStoreSelectedEvent for store locator, when user selects a store
   * @param props properties of the event
   * @throws InvalidPropertiesError
   */
  logStoreSelectedEvent(props: StoreSelectedEventProps) {
    if (this.checkProps(props)) {
      this.logEventWithSuperProps('store selected', {
        ...this.getEngagementEventProps(),
        ...props,
      });
    }
  }

  /**
   * logSlideEvent for all the slide events - Mostly called on the Slider components
   * @param props properties of the event
   * @throws InvalidPropertiesError
   */
  logSlideEvent(props: SlideEventProps) {
    if (this.checkProps(props)) {
      this.logEventWithSuperProps('slide', {
        ...this.getEngagementEventProps(),
        ...props,
      });
    }
  }

  /**
   * logStoreSelectedEvent for store locator, when user selects a store
   * @param props properties of the event
   * @throws InvalidPropertiesError
   */
  logSearchEvent(props: SearchEventProps) {
    if (this.checkProps(props)) {
      this.logEventWithSuperProps('search', {
        ...this.getEngagementEventProps(),
        ...props,
      });
    }
  }

  /**
   * logApplyRedirectEvent logs the apply redirect event
   */
  logApplyRedirectEvent() {
    this.logEventWithSuperProps(
      'apply redirect',
      this.getEngagementEventProps()
    );
  }

  /**
   * Logs Application Agreement Signed event
   * @param props properties of the event
   * @throws InvalidPropertiesError
   */
  logSignAgreementEvent(props: SignAgreementProps) {
    this.logEventWithSuperProps('sign origination agreement', { ...props });
  }

  /**
   * logMerchantLeadEvent for merchant leads
   * @param props properties of the event
   * @throws InvalidPropertiesError
   */
  logMerchantLeadEvent(props: MerchantLeadEventProps) {
    if (this.checkProps(props)) {
      this.logEventWithSuperProps('merchant lead created', {
        process: 'merchant lead acquisition',
        ...props,
      });
    }
  }

  /**
   * logPersonAvailableEvent to record the person_id
   * @param props properties of the event
   * @throws InvalidPropertiesError
   */
  logPersonAvailableEvent(props: PersonAvailableEventProps) {
    // According to version spec this event does not recieve super props
    this._vendor.logEvent('person_id available', props);
  }

  /**
   * logApplicationProcessEvent for customer's application process
   * @param eventName the name of the event happening during app process
   * @param props properties of the event
   * @throws InvalidPropertiesError
   */
  logApplicationProcessEvent(props: ApplicationProcessEventProps) {
    const eventName = props.event_name;
    const { application_id, status, sub_status, ...remaining_props } =
      props.event_properties;
    if (this.checkProps(remaining_props)) {
      switch (eventName) {
        case 'application submitted':
        case 'send application link':
          this.logAplicationSubmittedEvent(eventName, {
            ...remaining_props,
            application_id,
          });
          break;
        case 'application status changed':
          this.logAplicationStatusChangedEvent(eventName, {
            ...remaining_props,
            application_id,
            status,
            sub_status,
          });
          break;
        default:
          this.logEventWithSuperProps(eventName, remaining_props);
      }
    }
  }

  /**
   * Logs Application Submitted event
   * @param eventName name of the event
   * @param props properties
   * @throws InvalidPropertiesError
   */
  private logAplicationSubmittedEvent(
    eventName: ApplicationProcessEventName,
    props: AppProcessEventProps
  ) {
    if (props.application_id) {
      this.logEventWithSuperProps(eventName, props);
    } else {
      this.onError?.(new InvalidPropertiesError());
      return;
    }
  }

  /**
   * Logs Application Status Changes event
   * @param eventName name of the event
   * @param props properties
   * @throws InvalidPropertiesError
   */
  private logAplicationStatusChangedEvent(
    eventName: ApplicationProcessEventName,
    props: AppProcessEventProps
  ) {
    if (props.application_id && props.status) {
      this.logEventWithSuperProps(eventName, props);
    } else {
      this.onError?.(new InvalidPropertiesError());
      return;
    }
  }

  /**
   * Calls original logEvent function but adds the base props into the original props
   * @param eventName name of the event
   * @param props original props for each event
   */
  private logEventWithSuperProps(eventName: EventName, props?: object) {
    this._vendor.logEvent(eventName, {
      ...this.getSuperEventProps(),
      ...props,
    });
  }

  /**
   * Checks object props and returns trye if one of the props is falsy
   * @param props object with all props to review
   */
  private checkProps(props: object) {
    return true;
  }
}
