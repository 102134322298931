import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconOmnichannel({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M37 23.253C37 22.1926 37.8596 21.333 38.92 21.333L56.4133 21.333C57.4737 21.333 58.3333 22.1926 58.3333 23.253V53.413C58.3333 54.4734 57.4737 55.333 56.4133 55.333H38.92C37.8596 55.333 37 54.4734 37 53.413L37 23.253Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M54.3333 28.667H41V26.667H54.3333V28.667Z"
        fill={iconColor ?? colors.SnapWhite}
      />
      <path
        d="M48.3333 34.667H41V32.667H48.3333V34.667Z"
        fill={iconColor ?? colors.SnapWhite}
      />
      <path
        d="M47.6667 52.667C48.5871 52.667 49.3333 51.9208 49.3333 51.0003C49.3333 50.0799 48.5871 49.3337 47.6667 49.3337C46.7462 49.3337 46 50.0799 46 51.0003C46 51.9208 46.7462 52.667 47.6667 52.667Z"
        fill={iconColor ?? colors.SnapWhite}
      />
      <path
        d="M5 25.333H34.3333L34.3333 46.6663H25V33.333H15.6667V46.6663H7.66667V30.6663H5V25.333Z"
        fill={iconColor ?? colors.SnapBlue}
      />
      <path
        d="M50.3333 8H7.66667V12H50.3333V8Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M34.3333 30.6667V23.2533C34.3333 20.7202 36.3868 18.6667 38.92 18.6667L51.2745 18.6667L50.3333 14.6667H7.66667L5 26V30.6667H34.3333Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
