import { createContext, useContext, ReactNode, useState } from 'react';
import { useVerifyTokenCommand } from '../../commands/useVerifyTokenCommand';

export interface User {
  token: string;
  valid: boolean;
  birthdate?: Date | string;
  ssn?: string;
  phoneNumber?: string;
  emailId?: string;
  type?: 'EMAIL' | 'PHONE';
  // Add other user-related properties as needed
}
// Define the context type with default values
interface AuthContextType {
  user: User; // Replace 'User' with your user data type
  verifyToken: (token: string) => void;
  resetUser: () => void;
  setUserData: (user: User) => void;
  executing: boolean;
}

const defaultAuthContext: AuthContextType = {
  user: { valid: false, token: null },
  verifyToken: (token: string) => {},
  resetUser: () => {},
  setUserData: (user: User) => {},
  executing: false,
};

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>({
    valid: false,
    token: null,
    type: 'EMAIL',
  });
  const tokenVerify = useVerifyTokenCommand();

  const verifyToken = async (token: string) => {
    const verifyToken = await tokenVerify.execute({
      token: token,
    });

    if (verifyToken.hasErrors) {
      setUser({
        valid: false,
        token: token,
      });
    } else {
      setUser({
        valid: verifyToken.data['result'] || false,
        token: token,
      });
    }
  };

  const setUserData = (user: User) => {
    setUser((prevFilters) => ({ ...prevFilters, ...user }));
  };

  const resetUser = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        verifyToken,
        setUserData,
        resetUser,
        executing: tokenVerify.executing,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

export const useAuth = () => useContext(AuthContext);
