import { Merchant } from '@ee-monorepo/shared/utilities/types';

export interface MerchantFlows {
  isXOLite?: boolean;
  isXO?: boolean;
  isInStoreLoan?: boolean;
  isInStoreLTO?: boolean;
  isInStoreLTODCX?: boolean;
}

export function getMerchantFlows(merchant: Merchant): MerchantFlows {
  const isNotLoan =
    (merchant.merchantProducts && !merchant.merchantProducts.includes('SLN')) ||
    !merchant.merchantProducts;

  return {
    isXOLite: merchant.ecommerceXOLite,
    isXO: merchant.ecommerceXO || merchant.ecommercePlatform,
    isInStoreLoan:
      merchant.merchantProducts &&
      merchant.merchantProducts.includes('SLN') &&
      !merchant.kona,
    isInStoreLTO: isNotLoan && !merchant.kona,
    isInStoreLTODCX: isNotLoan && merchant.kona,
  };
}

export function useMerchantFlow({
  merchant,
}: {
  merchant: Merchant;
}): MerchantFlows {
  if (!merchant) {
    return {};
  }
  return getMerchantFlows(merchant);
}
