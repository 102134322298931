import React from 'react';
import { Element } from '@prismicio/helpers';
import { wordify } from '@ee-monorepo/shared/utilities/functions';
import { richTextSerializerWithOptions } from './rich-text-serializer';
import { JSXFunctionSerializer } from '@prismicio/react';
import { RTTextNodeBase } from '@prismicio/types';

// -- Function to add unique key to props
const propsWithUniqueKey = (props = {}, key: string) => {
  return { ...props, key };
};

// Default rendering for prismic rich text field
export const heroSecondarySerializer: JSXFunctionSerializer = function (
  type,
  element,
  content,
  children,
  key
) {
  let props = {};
  const parsedElement = element as RTTextNodeBase;
  switch (type) {
    case Element.heading1: {
      props = {
        id: wordify(parsedElement.text),
        className: 'headingL md:headingXL',
      };
      return React.createElement(
        'h1',
        propsWithUniqueKey(props, key),
        children
      );
    }
    // Return richTextSerializer as default
    default: {
      const htmlSerializer = richTextSerializerWithOptions({
        useHeadingIds: false,
      });
      return htmlSerializer(type, element, content, children, key);
    }
  }
};
