import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconAutoService({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-auto-service"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.253 5.77768C103.253 0.7834 97.9492 -2.15091 95.0532 1.91804C92.4969 5.50984 91 9.86434 91 14.5576C91 23.4396 96.3611 31.1082 104.118 34.6902L101.002 152.645C100.91 156.13 104.034 159 107.921 159H120.079C123.966 159 127.09 156.13 126.998 152.645L123.882 34.6902C131.639 31.1082 137 23.4396 137 14.5576C137 9.86412 135.503 5.50944 132.946 1.91755C130.05 -2.15128 124.747 0.783062 124.747 5.7773V8.98053C124.747 12.0856 122.149 14.6027 118.945 14.6027H109.055C105.851 14.6027 103.253 12.0856 103.253 8.98053V5.77768ZM119 146C119 148.761 116.761 151 114 151C111.239 151 109 148.761 109 146C109 143.238 111.239 141 114 141C116.761 141 119 143.238 119 146Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M35.3613 0.999664H52.6387L57 30.9997H51L51 96.3333C51 100.199 47.866 103.333 44 103.333C40.134 103.333 37 100.199 37 96.3333L37 30.9997H31L35.3613 0.999664Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M51 70.9997C57.6274 70.9997 63 76.3722 63 82.9997L63 127H25L25 82.9997C25 76.3722 30.3726 70.9997 37 70.9997H51Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path d="M25 131V139H63V131H25Z" fill={iconColor ?? colors.SnapOrange} />
      <path
        d="M63 143H25V147C25 153.627 30.3726 159 37 159L51 159C57.6274 159 63 153.627 63 147V143Z"
        fill={iconColor ?? colors.SnapOrange}
      />
    </svg>
  );
}
