export function formatPhone(phoneString: string) {
  if (!phoneString) {
    return '';
  }

  let formatted = '';
  const phone = phoneString.replace(/[^0-9*]/g, ''); // keep only numbers and *

  switch (phone.length) {
    case 7:
      formatted = phone.substr(0, 3) + '-' + phone.substr(3);
      break;
    case 10:
      formatted =
        '(' +
        phone.substr(0, 3) +
        ') ' +
        phone.substr(3, 3) +
        '-' +
        phone.substr(6);
      break;
    case 11:
      formatted =
        phone.substr(0, 1) +
        ' (' +
        phone.substr(1, 3) +
        ') ' +
        phone.substr(4, 3) +
        '-' +
        phone.substr(7);
      break;
  }
  return formatted;
}

export function formatPhoneNumberWithoutBrackets(phoneString: string) {
  if (!phoneString) {
    return '';
  }

  let formatted = '';
  const phone = phoneString.replace(/[^0-9*]/g, ''); // keep only numbers and *

  switch (phone.length) {
    case 7:
      formatted = phone.substr(0, 3) + '-' + phone.substr(3);
      break;
    case 10:
      formatted =
        phone.substr(0, 3) + '-' + phone.substr(3, 3) + '-' + phone.substr(6);
      break;
    case 11:
      formatted =
        phone.substr(0, 1) +
        ' ' +
        phone.substr(1, 3) +
        '-' +
        phone.substr(4, 3) +
        '-' +
        phone.substr(7);
      break;
  }
  return formatted;
}
