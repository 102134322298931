import React from 'react';
import { Element } from '@prismicio/helpers';
import { richTextSerializerWithOptions } from './rich-text-serializer';
import { JSXFunctionSerializer } from '@prismicio/react';

// -- Function to add unique key to props
const propsWithUniqueKey = (props = {}, key: string) => {
  return { ...props, key };
};

// Default rendering for prismic rich text field
export const spltCtaSerializer: JSXFunctionSerializer = function (
  type,
  element,
  content,
  children,
  key
) {
  let props = {};
  switch (type) {
    case Element.paragraph:
      props = { className: 'headingS md:headingM' };
      return React.createElement('p', propsWithUniqueKey(props, key), children);
    // Return defaults
    default: {
      const htmlSerializer = richTextSerializerWithOptions({
        useHeadingIds: false,
      });
      return htmlSerializer(type, element, content, children, key);
    }
  }
};
