import { useCallback } from 'react';
import { CommandReturn } from './useCommand';

/**
 * Returns a reference to execute the callback function and automatically
 * retries until success or max number of retries has been reached
 * @param callback reference to a command hook
 */

export function useCommandWithRetries<P, D, E>(
  callback: (params: P) => Promise<CommandReturn<D, E>>
) {
  const execute = useCallback(
    async (params: P, maxRetries: number): Promise<CommandReturn<D, E>> => {
      let response;
      for (let retry = 0; retry <= maxRetries; retry++) {
        response = await callback(params);
        if (response.success) {
          return response; // success only
        }
      }
      // maxRetries reached
      return response; // success or error
    },
    []
  );
  return { execute };
}
