import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuLawnAndGarden({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-lawn-and-garden"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9999 8.11114V3.00003H12.4269V8.11114C12.4269 10.2589 13.7607 12.0001 15.9326 12.0001H16V15.0001H15V21.4001C15 22.2837 15.5969 23.0001 16.3333 23.0001H17.6667C18.4031 23.0001 19 22.2837 19 21.4001V15.0001H18V12.0001H18.0674C19.7392 12 21.4999 10.2589 21.4999 8.11114V3.00003H19.9269V8.11114C19.9269 9.3998 19.3706 10.4445 18.0674 10.4445H18V3.00006H16V10.4445H15.9326C14.6294 10.4445 13.9999 9.3998 13.9999 8.11114ZM8 15.0001V9.00006H6V15.0001H5V21.4001C5 22.2837 5.59696 23.0001 6.33333 23.0001H7.66667C8.40305 23.0001 9 22.2837 9 21.4001V15.0001H8Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.17624 2.38388C3.8037 3.42537 3 5.03272 3 6.73622V12H11V6.73622C11 5.03272 10.1963 3.42537 8.82376 2.38388L7 1L5.17624 2.38388ZM9 15H5V17H9V15ZM15 15H19V17H15V15Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
