import { FlowModel } from '@ee-monorepo/shared/context/flows';

export const accountTakeOverModel: FlowModel = {
  launch: {
    nextConditions: [
      {
        conditions: {
          arg: 'valid',
          operator: 'is',
          value: 'false',
        },
        next: 'invalidLinkError',
      },
    ],
    next: 'verify',
  },
  steps: {
    verify: {
      route: '/security/verify',
      nextConditions: [
        {
          conditions: {
            arg: 'type',
            operator: 'is',
            value: 'PHONE',
          },
          next: 'changePhone',
        },
        {
          conditions: {
            arg: 'type',
            operator: 'is',
            value: 'EMAIL',
          },
          next: 'changeEmail',
        },
        {
          conditions: {
            arg: 'type',
            operator: 'is',
            value: 'NULL',
          },
          next: 'redirectError',
        },
      ],
    },
    changePhone: {
      route: '/security/change-phone',
      nextConditions: [
        {
          conditions: {
            arg: 'valid',
            operator: 'is',
            value: true,
          },
          next: 'verifyOtp',
        },
        {
          conditions: {
            arg: 'valid',
            operator: 'is',
            value: false,
          },
          next: 'redirectError',
        },
      ],
    },
    changeEmail: {
      route: '/security/change-email',
      nextConditions: [
        {
          conditions: {
            arg: 'valid',
            operator: 'is',
            value: true,
          },
          next: 'verifyOtp',
        },
        {
          conditions: {
            arg: 'valid',
            operator: 'is',
            value: false,
          },
          next: 'redirectError',
        },
      ],
    },
    verifyOtp: {
      route: '/security/verify-otp',
      nextConditions: [
        {
          conditions: {
            arg: 'valid',
            operator: 'is',
            value: true,
          },
          next: 'success',
        },
        {
          conditions: {
            arg: 'valid',
            operator: 'is',
            value: false,
          },
          next: 'redirectError',
        },
      ],
    },
    success: {
      route: '/security/success',
    },
    invalidLinkError: {
      route: '/security/invalid-link-error',
    },
    redirectError: {
      route: '/security/redirect-error',
    },
  },
};
