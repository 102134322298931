import React from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { formatDate } from '@ee-monorepo/shared/utilities/functions';
import { Card } from '@ee-monorepo/shared/ui/card';
import { ColoredTag as Tag } from '@ee-monorepo/shared/ui/tag';
import { PrismicText } from '@prismicio/react';

export function LearningCenterCard({
  blogType,
  category,
  background_color,
  uid,
  topic,
  summary,
  release_date,
  className,
}) {
  return (
    <Card
      data-testid="relatedArticleItem"
      className={cn('mr-0', className, 'cursor-pointer md:w-1/3')}
    >
      <Link href={`/${blogType}/${category.slug}/${uid}`}>
        <div>
          <div className="[&>span]:tagSmallFont">
            <Tag
              backgroundColor={background_color}
              text={category.slug.replace('-', ' ')}
              className="mb-4"
            />
          </div>
          <a
            href={`/${blogType}/${category.slug}/${uid}`}
            className="text-SnapGrey500 mb-1 headingSHeavy md:headingMHeavy hover:no-underline"
          >
            <PrismicText field={topic} />
          </a>
          <div className="mb-4 text-SnapGrey400 secondaryFont md:bodyFont">
            <PrismicText field={summary} />
          </div>
          <span className="tagSmallFont text-SnapGrey400">
            {formatDate(release_date)}
          </span>
        </div>
      </Link>
    </Card>
  );
}
