import { IconMenuProps } from '../constants';

export function IconMenuInStore({ iconColor, className }: IconMenuProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-in-store"
    >
      <path d="M20 4H4V6H20V4Z" fill={iconColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7L21 12V14L20 14V20H18V14H14V20H4V14L3 14V12L4 7H20ZM6 14V18H12V14H6Z"
        fill={iconColor}
      />
    </svg>
  );
}
