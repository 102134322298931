import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import {
  storeCookie,
  removeCookie,
  storeSelectedMerchantCookie,
} from '@ee-monorepo/shared/utilities/functions';
import {
  ApplicationType,
  LaunchAppConfig,
  Merchant,
} from '@ee-monorepo/shared/utilities/types';
import { useCallback } from 'react';
import { getMerchantFlows } from './useMerchantFlow';
import { useRakutenParams } from './useRakutenParams';

export interface ApplyFunctionOptions {
  merchant?: Merchant;
  isPrequalify?: boolean;
}

export type ApplyFunction = (options?: ApplyFunctionOptions) => void;

export interface ApplyOptions {
  testVariation: {
    [experimentName: string]: string;
  };
}
export function useApply(applyOptions: ApplyOptions): {
  apply: ApplyFunction;
} {
  const { testVariation } = applyOptions;
  const { rakutenParams } = useRakutenParams();

  const apply = useCallback(
    async (options: ApplyFunctionOptions = {}) => {
      const externalMerchantId = options.merchant
        ? options.merchant.externalMerchantId.toString()
        : null;

      const merchantProductType: ApplicationType =
        options.merchant &&
        options.merchant.merchantProducts &&
        options.merchant.merchantProducts.indexOf('SLN') !== -1
          ? 'SLN'
          : 'LTO';

      const applyPath = options.merchant
        ? {
            type: merchantProductType,
            merchant_id: externalMerchantId,
            path: 'store-locator',
          }
        : null;

      const config: LaunchAppConfig = {
        kona: true,
        source: 'SNAP_FINANCE_COM',
        testVariation: testVariation ? JSON.stringify(testVariation) : '{}', // send testVariation to apply
        merchantId: options.merchant ? options.merchant.id.toString() : null,
        externalMerchantId: externalMerchantId,
        productType: merchantProductType,
        paramId:
          merchantProductType === 'SLN' ? options.merchant.paramId : null,
        platformModel: options.isPrequalify ? 'PQA' : undefined,
      };

      if (rakutenParams) {
        config['rakutenParams'] = rakutenParams;
      }

      storeCookie('appConfig', config, 30000, true);
      removeCookie('last-visited-category', false);

      //const applyEndpoint = 'http://localhost:4201'; // local consumer application runninng
      const applyEndpoint = appConfig.applyEndpoint;

      let applyUrl =
        merchantProductType === 'SLN'
          ? `${applyEndpoint}/snap-loan/landing`
          : `${applyEndpoint}/snap-com/landing`;

      if (options.isPrequalify) {
        applyUrl =
          merchantProductType === 'SLN'
            ? `${applyEndpoint}/snap-loan/prequal`
            : `${applyEndpoint}/snap-com/prequal`;
      }

      if (options.merchant) {
        const { isXOLite } = getMerchantFlows(options.merchant);
        // on XO Lite go the base route where XO lite banners also go
        if (isXOLite) {
          applyUrl = applyEndpoint;
        }
        storeSelectedMerchantCookie(options.merchant);
      }

      window.location.href = applyUrl;
    },
    [rakutenParams, testVariation]
  );

  return { apply };
}
