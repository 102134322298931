import { ReactNode, createContext, useMemo } from 'react';

export type CacheStatus = 'waiting';
export interface Cache {
  get: <T>(key: string) => Promise<T>;
  getStatus: (key: string) => CacheStatus;
  setStatus: (key: string, status: CacheStatus) => void;
  set: (key: string, value: unknown) => void;
}

export interface FetchContextProps {
  cache?: Cache;
}

export const FetchContext = createContext<FetchContextProps>({});

/**
 * Create a caching mechanism to avoid duplicate calls
 * Is optional
 * @returns
 */
export const createCache = () => {
  const _cache = new Map();
  const cacheSubscription = new Map<string, (() => void)[]>();
  return {
    /* sets cache value to "waiting" while waiting for cache to populate */
    setStatus: (key: string, status: CacheStatus) => _cache.set(key, status),
    /* to check if cache is waiting */
    getStatus: (key: string) => _cache.get(key),
    /* sets value in cache and emit any subscription */
    set: (key: string, value: unknown) => {
      _cache.set(key, value);
      cacheSubscription.has(key) &&
        cacheSubscription.get(key).forEach((cb) => cb()); // emit all cache subscriptions
      cacheSubscription.delete(key); // delete all cache subscriptions
    },
    /* call this function only if getStatus returns something  */
    /* gets value if available otherwise waits for cache to emit, */
    get: (key: string) => {
      if (_cache.get(key) === 'waiting') {
        return new Promise((resolve) => {
          cacheSubscription.has(key) || cacheSubscription.set(key, []);
          cacheSubscription.get(key).push(() => resolve(_cache.get(key)));
        });
      }
      return _cache.get(key);
    },
  } as Cache;
};

export const FetchProvider = ({ children }: { children: ReactNode }) => {
  const cache: Cache = useMemo(createCache, []);

  return (
    <FetchContext.Provider value={{ cache }}>{children}</FetchContext.Provider>
  );
};
