import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuHVAC({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-hvac"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7.31836L12 2L22 7.31836V22H2V7.31836ZM12 20C15.866 20 19 16.866 19 13C19 9.13401 15.866 6 12 6C8.13401 6 5 9.13401 5 13C5 16.866 8.13401 20 12 20ZM13.197 9.30314C13.197 7.89308 12.0539 6.75 10.6438 6.75V9.68182C10.3422 9.79824 10.0605 9.9547 9.80524 10.1447L9.51446 9.96481C8.31533 9.22296 6.74185 9.59367 6 10.7928L8.44105 12.303C8.39726 12.5256 8.37431 12.7557 8.37431 12.9911C8.37431 13.1397 8.38345 13.2861 8.40119 13.4299L8.06708 13.6091C6.82451 14.2757 6.35757 15.8233 7.02413 17.0659L9.60816 15.6797C9.91272 15.9419 10.2623 16.1532 10.6438 16.3004V16.6788C10.6438 18.0888 11.7868 19.2319 13.1969 19.2319L13.1969 16.3004C13.5197 16.1758 13.8198 16.0053 14.089 15.7969L14.4437 16.0163C15.6428 16.7582 17.2163 16.3875 17.9581 15.1883L15.412 13.6132C15.4477 13.4112 15.4664 13.2033 15.4664 12.9911C15.4664 12.8423 15.4572 12.6955 15.4394 12.5515L15.7735 12.3722C17.0161 11.7057 17.483 10.158 16.8165 8.91548L14.2318 10.302C13.9275 10.0401 13.5781 9.82901 13.197 9.68187V9.30314ZM13.7642 12.991C13.7642 14.0094 12.9387 14.8349 11.9203 14.8349C10.9019 14.8349 10.0764 14.0094 10.0764 12.991C10.0764 11.9726 10.9019 11.1471 11.9203 11.1471C12.9387 11.1471 13.7642 11.9726 13.7642 12.991Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
