import { getColor } from '@ee-monorepo/shared/utilities/functions';
import cn from 'classnames';
import { richTextSerializer } from '@ee-monorepo/prismic/shared/serializers';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { PrismicRichText } from '@prismicio/react';

export interface BlockTextProps {
  bulletNumber: number;
  text: PrismicFields.RichTextField;
  bulletBgColor: string;
  textColor: string;
}

export function BlockText({
  bulletNumber,
  text,
  bulletBgColor,
  textColor,
}: BlockTextProps) {
  return (
    <div className="mt-3 mb-3 flex items-center">
      <div
        className={cn(
          getColor(bulletBgColor).backgroundColorClass,
          'w-[56px] h-[56px]  md:w-[72px] md:h-[72px] headingMHeavy flex items-center justify-center mr-2 rounded-full'
        )}
      >
        {bulletNumber}
      </div>
      <div className={cn(getColor(textColor).textColorClass, 'w-[328px] p-1')}>
        <PrismicRichText field={text} components={richTextSerializer} />
      </div>
    </div>
  );
}
