import { Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@snap/colors';

export const CustomAlert = styled(Alert)(({ theme }) => ({
  border: '1px solid',
  color: colors.SnapGrey500,

  '&.MuiAlert-standardSuccess': {
    borderColor: colors.SnapGreen,
    backgroundColor: colors.SnapLightSuccess,
  },
  '&.MuiAlert-standardError': {
    borderColor: colors.SnapError,
    backgroundColor: colors.SnapLightError,
  },
  '&.MuiAlert-standardWarning': {
    borderColor: colors.SnapWarning,
    backgroundColor: colors.SnapLightWarning,
  },
  '&.MuiAlert-standardInfo': {
    borderColor: colors.SnapBlue,
    backgroundColor: colors.SnapLightNeutral,
    '.MuiAlert-icon': {
      color: colors.SnapBlue,
    },
  },
  '.MuiAlert-message': {
    overflow: 'hidden',
  },
}));
