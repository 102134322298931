import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconAlarms({ iconColor, size = 'lg', className }: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-home-auto-alarms"
      className={className}
    >
      <path
        d="M81 68C76.4615 68 73 71.4256 73 75.3911C73 78.1107 74.602 80.5487 77.0908 81.8406C77.9452 82.2841 78.3624 83.2734 78.0836 84.1948L73.9071 98H88.3006L84.0915 84.1024C83.8166 83.1949 84.2169 82.219 85.0498 81.7659C87.4587 80.4553 89 78.0595 89 75.3911C89 71.4256 85.5385 68 81 68Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 2C22.9543 2.00001 14 10.9543 14 22L14 105.786C14 112.516 17.3842 118.794 23.0056 122.493L80 160L136.994 122.493C142.616 118.794 146 112.516 146 105.786V2L34 2ZM69 75.3911C69 68.9834 74.4928 64 81 64C87.5072 64 93 68.9834 93 75.3911C93 79.0853 91.155 82.3261 88.3581 84.3903L92.9102 99.4203C93.0938 100.026 92.9795 100.683 92.6023 101.192C92.225 101.7 91.6292 102 90.9961 102H71.2125C70.5794 102 69.9838 101.7 69.6065 101.192C69.2292 100.684 69.1148 100.027 69.2981 99.4209L73.8089 84.5112C70.9184 82.4536 69 79.1587 69 75.3911Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56 55H58V45C58 32.8497 67.8497 23 80 23C92.1503 23 102 32.8497 102 45V55H104C109.523 55 114 59.4772 114 65V101C114 106.523 109.523 111 104 111H56C50.4772 111 46 106.523 46 101V65C46 59.4771 50.4772 55 56 55ZM94 45V55H66V45C66 37.268 72.268 31 80 31C87.732 31 94 37.268 94 45ZM81 64C74.4928 64 69 68.9834 69 75.3911C69 79.1587 70.9184 82.4536 73.8089 84.5112L69.2981 99.4209C69.1148 100.027 69.2292 100.684 69.6065 101.192C69.9838 101.7 70.5794 102 71.2125 102H90.9961C91.6292 102 92.225 101.7 92.6023 101.192C92.9795 100.683 93.0938 100.026 92.9102 99.4203L88.3581 84.3903C91.155 82.3261 93 79.0853 93 75.3911C93 68.9834 87.5072 64 81 64Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
    </svg>
  );
}
