import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuOther({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-other"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM6.6 14.1004C7.7598 14.1004 8.7 13.1602 8.7 12.0004C8.7 10.8406 7.7598 9.90039 6.6 9.90039C5.4402 9.90039 4.5 10.8406 4.5 12.0004C4.5 13.1602 5.4402 14.1004 6.6 14.1004ZM14.1 12.0004C14.1 13.1602 13.1598 14.1004 12 14.1004C10.8402 14.1004 9.9 13.1602 9.9 12.0004C9.9 10.8406 10.8402 9.90039 12 9.90039C13.1598 9.90039 14.1 10.8406 14.1 12.0004ZM17.4 14.1004C18.5598 14.1004 19.5 13.1602 19.5 12.0004C19.5 10.8406 18.5598 9.90039 17.4 9.90039C16.2402 9.90039 15.3 10.8406 15.3 12.0004C15.3 13.1602 16.2402 14.1004 17.4 14.1004Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
