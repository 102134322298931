import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuTiresRims({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-tires-rims"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM15.2119 19.4382C14.2271 19.864 13.1411 20.1 12 20.1C10.8588 20.1 9.77279 19.864 8.788 19.4382L10.4528 16.5547C10.606 16.3323 10.8098 16.1396 11.0592 15.9956C11.9553 15.4783 13.1008 15.7846 13.6192 16.6797L15.2119 19.4382ZM16.7742 18.5442L15.1775 15.7788C14.6623 14.8824 14.9699 13.7378 15.8656 13.2206C16.1151 13.0766 16.3838 12.9964 16.653 12.975L20.0419 12.975C19.7679 15.2595 18.5428 17.2517 16.7742 18.5442ZM20.0585 11.175L16.6531 11.175C16.3839 11.1535 16.1151 11.0734 15.8657 10.9293C14.9694 10.4119 14.6619 9.2662 15.1784 8.36963L16.8349 5.5006C18.6092 6.82265 19.8237 8.85411 20.0585 11.175ZM15.281 4.59203L13.5471 7.59521C13.3939 7.8176 13.1901 8.01035 12.9406 8.15435C12.0449 8.67151 10.8998 8.36557 10.3812 7.47119L8.71891 4.59208C9.7219 4.14718 10.8321 3.9 12 3.9C13.1679 3.9 14.278 4.14716 15.281 4.59203ZM7.16503 5.50068C5.39079 6.82273 4.17628 8.85415 3.9415 11.175H7.34691C7.61607 11.1535 7.8848 11.0733 8.1342 10.9294C9.03026 10.412 9.33776 9.26675 8.82178 8.37028L7.16503 5.50068ZM3.95808 12.975C4.23214 15.2595 5.4571 17.2516 7.22573 18.5441L8.8205 15.7819C9.33827 14.8851 9.031 13.7384 8.1342 13.2206C7.88479 13.0766 7.61605 12.9964 7.34688 12.975L3.95808 12.975ZM12 14.4C13.3255 14.4 14.4 13.3255 14.4 12C14.4 10.6745 13.3255 9.6 12 9.6C10.6745 9.6 9.6 10.6745 9.6 12C9.6 13.3255 10.6745 14.4 12 14.4Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
