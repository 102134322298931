import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuCellPhones({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-cell-phones"
    >
      <path d="M8 12V10H10L10 12H8Z" fill={iconColor ?? colors.SnapGreen} />
      <path d="M14 6V4H16V6H14Z" fill={iconColor ?? colors.SnapGreen} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C5 1.89543 5.89543 1 7 1H17C18.1046 1 19 1.89543 19 3V21C19 22.1046 18.1046 23 17 23L7 23C5.89543 23 5 22.1046 5 21V3ZM7 3V7H11L11 3H7ZM7 13V9H11L11 13H7ZM13 3H17V7H13V3ZM13.5 20.5C13.5 21.3284 12.8284 22 12 22C11.1716 22 10.5 21.3284 10.5 20.5C10.5 19.6716 11.1716 19 12 19C12.8284 19 13.5 19.6716 13.5 20.5Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
