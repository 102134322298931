import { SvgIconProps, SvgIcon } from '@mui/material';

export type IconPersonProps = {
  iconColor: string;
} & SvgIconProps;

export function IconPerson({ iconColor, ...rest }: IconPersonProps) {
  return (
    <SvgIcon
      {...rest}
      fontSize="small"
      viewBox="0 0 16 16"
      data-testid="icon-person"
    >
      <path
        d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
        fill={iconColor}
      />
    </SvgIcon>
  );
}
