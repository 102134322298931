import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuAlarms({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-home-auto-alarms"
    >
      <path
        d="M13 9V7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V9H13Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path d="M13 11V15H11V11H13Z" fill={iconColor ?? colors.SnapGreen} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7C2 3.68629 4.68629 1 8 1H22V15.2495C22 16.5937 21.3248 17.848 20.2028 18.5883L12 24L3.79723 18.5883C2.67519 17.848 2 16.5937 2 15.2495V7ZM15 9C16.1046 9 17 9.89543 17 11V15C17 16.1046 16.1046 17 15 17H9C7.89543 17 7 16.1046 7 15V11C7 9.89543 7.89543 9 9 9V7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7V9Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
