import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';

/**
 * ranEAID: string value representing the publisher's encrypted unique ID
 * ranSiteID: string value composed by ranEAID + unique value that will vary per click even for the same publisher
 * ranMID: string value representing Snap merchant ID provided by Rakuten Marketing
 * landingDate: string representing the date of the transaction, format YYYYMMDD_HHmm
 */
export function useRakutenParams() {
  const router = useRouter();
  const [rakutenParams, setRakutenParams] = useState(null);
  useEffect(() => {
    if (
      router?.query['ranSiteID'] &&
      router?.query['ranEAID'] &&
      router?.query['ranMID']
    ) {
      const rakutenParams = {
        ranMID: router.query['ranMID'],
        ranEAID: router.query['ranEAID'],
        ranSiteID: router.query['ranSiteID'],
        landingDate: dayjs().format('YYYYMMDD_HHmm'),
      };
      sessionStorage.setItem('rakutenParams', JSON.stringify(rakutenParams));
      setRakutenParams(rakutenParams);
    } else {
      if (sessionStorage.getItem('rakutenParams')) {
        const rakutenParams = JSON.parse(
          sessionStorage.getItem('rakutenParams')
        );
        setRakutenParams(rakutenParams);
      }
    }
  }, [router?.query]);
  return {
    rakutenParams,
  };
}
