import { useTranslations } from 'next-intl';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ImageWrapper } from '@ee-monorepo/prismic/shared/ui';
import React from 'react';

export const ErrorPage = () => {
  const t = useTranslations('account_takeover_form');

  return (
    <div>
      <div className="flex justify-center mt-2 mb-4">
        <ImageWrapper
          src="/assets/icons/account-takeover/error_plug.svg"
          alt={t('error_icon_alt')}
          width={160}
          height={160}
        />
      </div>

      <h3>{t('errorHeading')}</h3>
      <p className="mt-3">{t('errorContent')}</p>
    </div>
  );
};
