import { createContext, useCallback, useContext } from 'react';

export type ErrorTrackingSeverity =
  | 'log'
  | 'debug'
  | 'info'
  | 'warn'
  | 'error'
  | 'fatal';

export interface ErrorTrackingContextProps {
  logFetchError: (
    method: string,
    path: string,
    httpStatus: number,
    severity: ErrorTrackingSeverity
  ) => void;
}

/**
 * Error tracking context
 * @prop logFetchError - Error tracking callback function to be called when a fetch error occurs, its purpose is to inject any vendor error tracking logging, from the app level
 */
export const ErrorTrackingContext = createContext<ErrorTrackingContextProps>({
  logFetchError: (method, path, httpStatus, severity) =>
    console.error(
      'ErrorTrackingContext not implemented at the app level, also error tracking vendor needs to be integrated',
      {
        method,
        path,
        httpStatus,
        severity,
      }
    ),
});

/**
 * Fetch options with error tracking
 *
 * @prop handleErrorTrackingSeverity - Optional, custom severity error tracking callback function based on the http status, if not propvided, the default severity is 'error'
 */
type FetchWithErrorTrackingOptions = RequestInit & {
  handleErrorTrackingSeverity?: (httpStatus: number) => ErrorTrackingSeverity;
};

/**
 * Standard Fetch API function wrapper hook with error tracking
 *
 * @example
 * const { fetchWithErrorTracking } = useFetchWithErrorTracking();
 *
 * @returns
 */
export function useFetchWithErrorTracking() {
  const { logFetchError } = useContext(ErrorTrackingContext);
  const fetchWithErrorTracking = useCallback(
    async (
      url: string,
      {
        handleErrorTrackingSeverity,
        ...options
      }: FetchWithErrorTrackingOptions = {}
    ) => {
      const response = await fetch(url, options);
      const method = options?.method || 'GET';
      if (!response.ok) {
        // if response has error calls handleErrorTrackingSeverity, custom errors severities can be thrown by the status
        const severity: ErrorTrackingSeverity =
          handleErrorTrackingSeverity?.(response.status) || 'error';
        logFetchError(method, url, response.status, severity);
      }
      return response;
    },
    []
  );
  return {
    fetchWithErrorTracking,
  };
}

/**
 * Returns Application's Error Tracking Context
 */
export const useErrorTrackingContext = () => {
  return useContext(ErrorTrackingContext);
};
