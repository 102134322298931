import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuJewelry({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-jewelry"
    >
      <path
        d="M4.26464 9L0.155216 9L12 23.6159L23.8448 9L19.7354 9L12 23.6159L16.911 9L7.08845 9L12 23.6159L4.26464 9Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M7.06658 8L16.9325 8L15.4681 3H8.52812L7.06658 8Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M19.8024 8L23.8833 8L20.2165 3H17.8262L19.8024 8Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M6.17379 3L4.19763 8L0.116699 8L3.78355 3H6.17379Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
