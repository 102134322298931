import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

export const NavigationBarHeightProviderContext = createContext<{
  height: number;
  setHeight: Dispatch<SetStateAction<number>>;
}>(null);

export const NavigationBarHeightProvider = ({ children }) => {
  const [height, setHeight] = useState(54);
  return (
    <NavigationBarHeightProviderContext.Provider value={{ height, setHeight }}>
      {children}
    </NavigationBarHeightProviderContext.Provider>
  );
};

export const useNavigationBarHeight = () => {
  const result = useContext(NavigationBarHeightProviderContext);
  return result;
};
