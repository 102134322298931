import React from 'react';
import Link from 'next/link';
import { linkResolver } from '@ee-monorepo/prismic/shared/utilities';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import * as prismicH from '@prismicio/helpers';
export interface PrismicLinkProps {
  children?: React.ReactNode;
  link: PrismicFields.Link;
  className?: string;
  render?: (props: RenderProps) => React.ReactNode;
  onClick?: () => void;
}

export interface RenderProps {
  href: string;
  target: string;
  rel: string;
  className: string;
}

export const PrismicLink = ({
  children,
  link,
  className,
  onClick,
  render,
}: PrismicLinkProps) => {
  const isInternalLink = link?.link_type === 'Document';
  const href = isInternalLink ? linkResolver(link) : prismicH.asLink(link);
  const target = link?.target === '_blank' ? '_blank' : '';
  const rel = 'noreferrer';

  if (render) {
    // tag/component agnostic, examples: custom link buttons
    return <>{render({ href, target, rel, className })}</>;
  }
  return (
    // nextjs link
    <Link
      data-testid="prismic-link"
      onClick={onClick}
      target={target}
      className={className}
      rel={rel}
      href={href ?? ''}
    >
      {children}
    </Link>
  );
};
