import { useEffect, useState } from 'react';

/**
 *
 * @param distance Pixels distance that detects if user scrolled down or not
 */

export interface UseScrolledDownOptions {
  distance?: number;
  enabled?: boolean;
}
export const useScrolledDown = (
  options: UseScrolledDownOptions = { distance: 25, enabled: true }
) => {
  const [scrolled, setScrolled] = useState(false);
  const distance = options.distance || 5;
  const enabled = typeof options.enabled === 'boolean' ? options.enabled : true;

  useEffect(() => {
    const onScrolled = () => {
      if (window.scrollY > distance) {
        return setScrolled(true);
      }
      return setScrolled(false);
    };

    if (enabled) {
      window.addEventListener('scroll', onScrolled);
    }

    return () => {
      window.removeEventListener('scroll', onScrolled);
    };
  }, [enabled, distance]);
  return { scrolled };
};
