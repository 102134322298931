import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconOther({ iconColor, size = 'lg', className }: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-other"
      className={className}
    >
      <circle cx="80" cy="80" r="70" fill={iconColor ?? colors.SnapDarkBlue} />
      <path
        d="M44 94C51.732 94 58 87.732 58 80C58 72.268 51.732 66 44 66C36.268 66 30 72.268 30 80C30 87.732 36.268 94 44 94Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M94 80C94 87.732 87.732 94 80 94C72.268 94 66 87.732 66 80C66 72.268 72.268 66 80 66C87.732 66 94 72.268 94 80Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M130 80C130 87.732 123.732 94 116 94C108.268 94 102 87.732 102 80C102 72.268 108.268 66 116 66C123.732 66 130 72.268 130 80Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
    </svg>
  );
}
