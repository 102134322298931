import { useState, useEffect, useCallback, useRef } from 'react';
import { Fingerprint } from '@ee-monorepo/shared/utilities/types';

export interface UseFingerPrintReturn {
  fingerprint: Fingerprint;
  getFingerprint: () => Promise<Fingerprint>;
  getDevice: () => Promise<string>;
}

export function useFingerprint(): UseFingerPrintReturn {
  const [fingerprint, _setFingerprint] = useState<Fingerprint | null>(null);
  // create ref to store callback, so getFingerprint can subscribe when ready
  const fingerPrintCallbackRef = useRef<(_: Fingerprint) => void | null>(null);

  const setFingerprint = useCallback(
    (_fingerprint: Fingerprint) => {
      window.sessionStorage.setItem(
        'fingerprint',
        JSON.stringify(_fingerprint)
      );
      fingerPrintCallbackRef.current?.(_fingerprint);
      _setFingerprint(_fingerprint);
    },
    [fingerPrintCallbackRef]
  );

  const getFingerprintFromSession = useCallback(() => {
    const sessionFP = window.sessionStorage.getItem('fingerprint');
    return sessionFP ? JSON.parse(sessionFP) : null;
  }, []);

  useEffect(() => {
    const sessionFP = getFingerprintFromSession();

    if (sessionFP) {
      setFingerprint(sessionFP);
      return;
    }

    const load = async () => {
      /* dynamic import (frontend, needs access to window object), static import won't work because file is exec in the server during build time */
      const fingerPrintModule = await import(
        '@ee-monorepo/shared/utilities/fingerprint'
      );
      const { clientJS } = fingerPrintModule;
      const _fingerprint: Fingerprint = {
        fingerprintId: clientJS.getFingerprint(),
        isMobile: clientJS.isMobile(),
        browser: clientJS.getBrowser(),
        clientIP: '0.0.0.0', // default ip
      };

      setFingerprint(_fingerprint);

      try {
        const res = await window.fetch(
          'https://api.ipify.org?format=json&callback=getIP',
          {
            signal: AbortSignal.timeout(2000), // timeout after this time
          }
        );
        if (!res.ok) {
          throw res;
        }
        const ipResponse = await res.json();
        _fingerprint.clientIP = ipResponse.ip;
      } catch (e) {}

      setFingerprint(_fingerprint);
    };

    load();
  }, [setFingerprint, getFingerprintFromSession]);

  const getFingerprint = useCallback(
    () =>
      new Promise<Fingerprint>((resolve) => {
        const _fingerprint = getFingerprintFromSession();
        if (_fingerprint) {
          return resolve(_fingerprint);
        }
        // OR wait til fingerprint ready
        fingerPrintCallbackRef.current = (_fingerprint) => {
          fingerPrintCallbackRef.current = null;
          resolve(_fingerprint);
        };
      }),
    [fingerPrintCallbackRef, getFingerprintFromSession]
  );

  const getDevice = useCallback(async (): Promise<string> => {
    /* dynamic import (frontend, needs access to window object), static import won't work because file is exec in the server during build time */
    const fingerPrintModule = await import(
      '@ee-monorepo/shared/utilities/fingerprint'
    );
    const { clientJS } = fingerPrintModule;
    const device = clientJS.getDevice();
    return device as string;
  }, []);

  return { fingerprint, getFingerprint, getDevice };
}
