import { ExperimentsContext } from './experiments-context';
import SplitIO from '@splitsoftware/splitio-browserjs/types/splitio';
import { useEffect, useState, useContext } from 'react';

export const useExperiments = (
  treatmentNames: string[],
  attributes = undefined
) => {
  const { isReady, isTimedOut, client, checkActiveExperiment } =
    useContext(ExperimentsContext);
  const [experiments, setExperiments] = useState(
    {} as SplitIO.TreatmentsWithConfig
  );
  const [treatments] = useState(treatmentNames);
  useEffect(() => {
    const defaultExperiments = {};
    treatments?.forEach((treatmentName) => {
      checkActiveExperiment(treatmentName);
      defaultExperiments[treatmentName] = {
        treatment: '',
        config: '',
      };
    });
    setExperiments(defaultExperiments);
  }, [treatments, checkActiveExperiment]);

  useEffect(() => {
    if (isReady || isTimedOut) {
      const treatmentResults: SplitIO.TreatmentsWithConfig | null = client
        ? client?.getTreatmentsWithConfig(treatments, attributes)
        : null;
      if (treatmentResults && Object.keys(treatmentResults).length !== 0) {
        setExperiments(treatmentResults);
      }
    }
  }, [client, treatments, attributes, isReady, isTimedOut]);

  return {
    experiments,
  };
};
