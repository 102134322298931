import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import { Button } from '@ee-monorepo/shared/ui/button';
import { Card } from '@ee-monorepo/shared/ui/card';
import { SnapDialog } from '@ee-monorepo/shared/ui/dialog';
import { IconInStore, IconOnline } from '@ee-monorepo/shared/ui/icons';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';

export interface PartnerModalProps {
  show?: boolean;
  handleClose(): void;
}

export function PartnerModal({ show = false, handleClose }: PartnerModalProps) {
  const t = useTranslations();
  const isMobile = useHandHeld();

  return (
    <SnapDialog
      title={t('shared.partner.onboardingModalTitle') as string}
      id="partner-modal"
      data-testid="partner-modal"
      maxWidth={'sm'}
      fullWidth
      open={show}
      onClose={() => handleClose()}
    >
      <div className="flex flex-col md:flex-row pt-1">
        <Card
          className={cn('text-center mb-5 mr-0 md:mb-0 md:mr-12', {
            'w-1/2': !isMobile,
          })}
        >
          <IconInStore size="lg" />
          <div className="bodyHeavyFont text-center my-5">
            {t('shared.partner.onboardingModalInStore')}
          </div>
          <div className="flex flex-col md:flex-row justify-center">
            <Button
              href={appConfig.comUrl + '/merchant-apply/tell-us-about-you'}
            >
              {t('shared.partner.onboardingModalButton')}
            </Button>
          </div>
        </Card>
        <Card
          className={cn('text-center', {
            'w-1/2': !isMobile,
          })}
        >
          <IconOnline size="lg" />
          <div className="bodyHeavyFont my-5">
            {t('shared.partner.onboardingModalEcommerce')}
          </div>
          <div className="flex flex-col md:flex-row justify-center">
            <Button href={appConfig.comUrl + '/ecommerce-inquiry'}>
              {t('shared.partner.onboardingModalButton')}
            </Button>
          </div>
        </Card>
      </div>
    </SnapDialog>
  );
}
