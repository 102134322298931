import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconSportingGoods({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-sporting-goods"
      className={className}
    >
      <path
        d="M107.411 59.4062C109.364 61.3588 109.364 64.5246 107.411 66.4772L67.5294 106.359C65.5768 108.312 62.411 108.312 60.4583 106.359L50.5589 96.4596C48.6062 94.507 48.6062 91.3411 50.5589 89.3885L90.4407 49.5067C92.3933 47.5541 95.5591 47.5541 97.5118 49.5067L107.411 59.4062Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M137.502 29.3152C139.455 31.2678 139.455 34.4336 137.502 36.3862L132.864 41.0246C130.911 42.9773 127.745 42.9773 125.793 41.0246L115.893 31.1252C113.941 29.1725 113.941 26.0067 115.893 24.0541L120.532 19.4157C122.484 17.463 125.65 17.463 127.603 19.4157L137.502 29.3152Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M41.2777 125.54C43.2303 127.492 43.2303 130.658 41.2777 132.611L36.386 137.502C34.4334 139.455 31.2675 139.455 29.3149 137.502L19.4154 127.603C17.4628 125.65 17.4628 122.484 19.4154 120.532L24.3071 115.64C26.2598 113.688 29.4256 113.688 31.3782 115.64L41.2777 125.54Z"
        fill={iconColor ?? colors.SnapOrange}
      />
      <path
        d="M137.472 70.6809C141.377 74.5861 141.377 80.9178 137.472 84.823L128.28 94.0154C126.327 95.968 123.161 95.968 121.209 94.0154L63.2258 36.0327C61.2732 34.08 61.2732 30.9142 63.2258 28.9616L72.4182 19.7692C76.3235 15.864 82.6551 15.864 86.5604 19.7692L137.472 70.6809Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M19.3852 86.9443C15.48 83.039 15.48 76.7074 19.3852 72.8021L28.5776 63.6097C30.5303 61.6571 33.6961 61.6571 35.6487 63.6097L93.6314 121.592C95.5841 123.545 95.5841 126.711 93.6314 128.664L84.439 137.856C80.5338 141.761 74.2022 141.761 70.2969 137.856L19.3852 86.9443Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M143.129 45.225C147.034 49.1302 147.034 55.4618 143.129 59.3671L138.948 63.5481L93.7102 18.2762L97.8742 14.1123C101.779 10.207 108.111 10.207 112.016 14.1123L143.129 45.225Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M13.7586 112.047C9.85341 108.141 9.8534 101.81 13.7586 97.9046L17.745 93.9182L62.9998 139.173L59.0134 143.159C55.1082 147.065 48.7765 147.065 44.8713 143.159L13.7586 112.047Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
