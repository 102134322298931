import * as yup from 'yup';

const REQUIRED = 'shared.forms.validations.required';
const PHONE_NUMBER_LENGTH = 'shared.forms.validations.phoneLength';

export interface ChangePhoneFormSchemaData {
  phone: string;
  communications: boolean;
}

export const formSchema: yup.SchemaOf<ChangePhoneFormSchemaData> = yup
  .object()
  .shape({
    phone: yup.string().required(REQUIRED).length(10, PHONE_NUMBER_LENGTH),
    communications: yup.boolean().required(REQUIRED),
  });
