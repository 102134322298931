import s from './shared-ui-spinner-ring.module.scss';
import cn from 'classnames';
export interface SpinnerRingProps {
  color?: string;
  className?: string;
}

export const SpinnerRing = ({
  color = 'white',
  className,
}: SpinnerRingProps) => (
  <svg className={cn(s.spinnerRing, className)} viewBox="0 0 50 50">
    <circle
      className={s.spinnerRingBorder}
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
    ></circle>
    <circle
      className={s.spinnerRingArc}
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
      stroke={color}
    ></circle>
  </svg>
);
