// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ImageWrapper } from '@ee-monorepo/prismic/shared/ui';
import { SnapDialog } from '@ee-monorepo/shared/ui/dialog';
import { FieldControl } from '@ee-monorepo/shared/ui/field-control';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';
import { VerifyEmailFormSchema } from './schema';
import { TextField } from '@ee-monorepo/shared/ui/text-field';
import { Button } from '@ee-monorepo/shared/ui/button';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
interface EmailValidationDialogProps {
  onSubmit: (data: any) => void;
  onClose: (data: any) => void;
  proceed: (data: any) => void;
}

export const EmailValidationDialog = ({
  onSubmit,
  onClose,
  proceed,
}: EmailValidationDialogProps) => {
  const t = useTranslations('account_takeover_form');

  const isMobile = useHandHeld();

  const dialogStyles = {
    bottom: isMobile ? 0 : 'auto',
    marginBottom: isMobile ? 0 : 'initial',
  };
  const { tracking } = useTrackingContext();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<any>({
    defaultValues: {
      email: '',
    },
    mode: 'all',
    resolver: yupResolver(VerifyEmailFormSchema),
  });

  const onCloseModal = (e: any) => {
    tracking?.logClickEvent({
      click_type: 'button',
      placement: 'email validation modal',
      click_value: 'close',
    });
    onClose(e);
  };

  return (
    <SnapDialog
      title=""
      hideCloseButton={false}
      id="email-validation-dialog"
      data-testid="email-validation-modal"
      open={true}
      onClose={onCloseModal}
      fullScreenOnMobile={false}
      centered={true}
      PaperProps={{
        sx: {
          maxWidth: !isMobile ? '660px' : '100%',
          width: !isMobile ? '65%' : '100%',
        },
        style: dialogStyles,
      }}
    >
      <div className="p-2 md:p-0">
        <div className={`flex mb-4`}>
          <ImageWrapper
            src="/assets/icons/account-takeover/email_failed.svg"
            alt={t('alternate_email_alt')}
            width={40}
            height={40}
          />
        </div>
        <h3 className="mb-2">{t('emailValidationTitle')}</h3>
        <p>{t('emailValidationDescription')}</p>
        <form
          className="mt-10"
          data-testid="email-validation-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="grid grid-cols-1 mt-10 mb-16 h-14">
            <FieldControl
              fieldName="email"
              control={control}
              data-testid="email-field-control"
              render={(field, invalid) => (
                <TextField
                  type="text"
                  {...field}
                  fullWidth
                  label={t('emailFieldLabel')}
                  inputProps={{
                    pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
                  }}
                  error={invalid}
                  data-testid="email-field"
                />
              )}
            />
          </div>
          <div className="flex md:flex-row flex-col-reverse justify-end mb-4 md:mt-6 md:mb-0">
            <div className="m-2 flex flex-col md:flex-row justify-end mr-6 items-center pt-2">
              <Button
                className="cursor-pointer snap-color-green h-full h-8 mt-2 md:mt-0"
                onClick={proceed}
                variant="link"
                data-testid="proceed-without-changes-btn"
              >
                {t('proceedBtn')}
              </Button>
            </div>
            <div className="flex flex-col md:flex-row justify-end h-12 md:h-14">
              <Button
                className="h-full"
                type="submit"
                disabled={!isValid}
                data-testid="corrected-email-submit"
              >
                {t('saveChangesBtn')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </SnapDialog>
  );
};

export default EmailValidationDialog;
