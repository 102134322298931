import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuScooters({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-scooters"
    >
      <path
        d="M19 21C20.6569 21 22 19.6569 22 18C22 16.3431 20.6569 15 19 15C17.3431 15 16 16.3431 16 18C16 19.6569 17.3431 21 19 21Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M6 21C7.65686 21 9 19.6569 9 18C9 16.3431 7.65686 15 6 15C4.34315 15 3 16.3431 3 18C3 19.6569 4.34315 21 6 21Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7H12V9H6V7Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M19 19.4695C19.8284 19.4695 20.5 18.7979 20.5 17.9695C20.5 17.1411 19.8284 16.4695 19 16.4695C18.1716 16.4695 17.5 17.1411 17.5 17.9695C17.5 18.7979 18.1716 19.4695 19 19.4695Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M6 19.4695C6.82843 19.4695 7.5 18.7979 7.5 17.9695C7.5 17.1411 6.82843 16.4695 6 16.4695C5.17157 16.4695 4.5 17.1411 4.5 17.9695C4.5 18.7979 5.17157 19.4695 6 19.4695Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M12 17H1V12.9167C1 11.3058 2.4275 10 4.18841 10H12V13.6458V15.3229V17Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5483 2H15.2189C16.0814 2 16.85 2.57029 17.1361 3.42246L20.4999 12.1115C21.3699 12.3474 22.1509 12.8153 22.7784 13.4479L21.973 14.3241C21.2548 13.6 20.2801 13.1557 19.2051 13.1557C18.1243 13.1557 17.1439 13.6052 16.4245 14.3368L14.9203 16.1868C14.535 16.7001 13.9459 17 13.3223 17H11.5645C10.8429 17 10.2581 16.3874 10.2581 15.6318L10.2581 13.9606L11.4194 13.9606L11.4193 15.6318C11.4193 15.7157 11.4844 15.7838 11.5645 15.7838H13.3223C13.5896 15.7838 13.8421 15.6553 14.0072 15.4352L16.0974 12.7472L16.4614 12.2599C16.736 11.8921 16.8126 11.4031 16.6646 10.9626L15.4946 7.48115C15.1833 6.55519 15.7777 5.58783 16.6765 5.46845L16.0406 3.82591C15.9179 3.46062 15.5885 3.21621 15.2189 3.21621H13.5483V2Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M18 3.35C18 2.60441 18.474 2 19.0588 2H20V5H19.0588C18.474 5 18 4.39559 18 3.65V3.35Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
