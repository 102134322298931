import { MerchantShortFormProvider } from './merchant-short-form-provider';
import { MerchantLongFormProvider } from './merchant-long-form-provider';
import { ReactNode } from 'react';

//This wrapper is created to avoid putting multiple providers in the [uid]
export const MerchantFormProviderWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <>
      <MerchantShortFormProvider>
        <MerchantLongFormProvider>{children}</MerchantLongFormProvider>
      </MerchantShortFormProvider>
    </>
  );
};