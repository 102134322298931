import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconIncreaseOrder({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-increase-order"
    >
      <g clipPath="url(#clip0_110_100)">
        <g clipPath="url(#clip1_110_100)">
          <path
            d="M67.1093 22.8228L67.1093 23.8217L68.1082 23.8228L78.3532 23.834L78.3644 34.079L78.3655 35.0779L79.3644 35.0779L82.8219 35.0779L83.8219 35.0779L83.8219 34.0779L83.8219 19.3653L83.8219 18.3653L82.8219 18.3653L68.1093 18.3653L67.1093 18.3653L67.1093 19.3653L67.1093 22.8228Z"
            fill={iconColor ?? colors.SnapLightGreen}
            stroke="#67B25E"
            strokeWidth="2"
          />
        </g>
        <path
          d="M10 76.3081L40.4001 46.0655L48.2621 54.0241L78.6622 23.251"
          stroke="#67B25E"
          strokeWidth="8"
        />
      </g>
      <path d="M11 8V85H88" stroke="#062D4E" strokeWidth="6" />
      <defs>
        <clipPath id="clip0_110_100">
          <rect
            width="80"
            height="80"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
        <clipPath id="clip1_110_100">
          <rect
            width="30.0449"
            height="26.1913"
            fill="white"
            transform="translate(54.1909 29.2451) rotate(-45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
