import React, { ReactNode } from 'react';
import { Element } from '@prismicio/helpers';
import { linkResolver } from '@ee-monorepo/prismic/shared/utilities';
import { wordify } from '@ee-monorepo/shared/utilities/functions';
import { JSXFunctionSerializer } from '@prismicio/react';
import { RTLinkNode, RTTextNodeBase } from '@prismicio/types';
import { Link } from '@ee-monorepo/prismic/shared/types';

// -- Function to add unique key to props
const propsWithUniqueKey = (props = {}, key: string) => {
  return { ...props, key };
};

export type HTMLSerializerGetterWithOptions<T> = (options?: {
  useHeadingIds?: boolean;
}) => JSXFunctionSerializer;

type ParsedElement = RTTextNodeBase & { direction?: 'rtl' };

export const richTextSerializerWithOptions: HTMLSerializerGetterWithOptions<
  ReactNode
> = (options = { useHeadingIds: false }) => {
  const _richTextSerializer: JSXFunctionSerializer = (
    type,
    element,
    content,
    children,
    key
  ) => {
    let props = {};
    const parsedElement = element as ParsedElement;
    const textAlignClass =
      parsedElement.direction === 'rtl' ? 'text-right' : '';
    switch (type) {
      case Element.heading1: {
        const id = options.useHeadingIds
          ? wordify(parsedElement.text)
          : undefined;
        props = {
          id,
          className: 'headingL md:headingXL',
        };
        return React.createElement(
          'h1',
          propsWithUniqueKey(props, key),
          children
        );
      }
      case Element.heading2: {
        const id = options.useHeadingIds
          ? wordify(parsedElement.text)
          : undefined;
        props = { id, className: 'headingMHeavy md:headingL' };
        return React.createElement(
          'h2',
          propsWithUniqueKey(props, key),
          children
        );
      }
      case Element.heading3: {
        props = {
          id: wordify(parsedElement.text),
          className: 'headingSHeavy md:headingMHeavy',
        };
        return React.createElement(
          'h3',
          propsWithUniqueKey(props, key),
          children
        );
      }
      case Element.heading4:
        props = { className: 'bodyHeavyFont md:headingSHeavy' };
        return React.createElement(
          'h4',
          propsWithUniqueKey(props, key),
          children
        );
      case Element.heading5:
        props = { className: 'headingS md:headingM' };
        return React.createElement(
          'h5',
          propsWithUniqueKey(props, key),
          children
        );
      case Element.heading6:
        props = { className: 'bodyFont' };
        return React.createElement(
          'p',
          propsWithUniqueKey(props, key),
          children
        );
      case Element.paragraph:
        props = { className: `bodyFont ${textAlignClass}` };
        return React.createElement(
          'p',
          propsWithUniqueKey(props, key),
          children
        );
      // case Element.span:
      //   props = { className: s.rt_span };
      //   return React.createElement(
      //     'span',
      //     propsWithUniqueKey(props, key),
      //     children
      //   );
      // case Element.label:
      //   props = { className: s.rt_label };
      //   return React.createElement(
      //     'label',
      //     propsWithUniqueKey(props, key),
      //     children
      //   );
      // case Element.preformatted:
      //   props = { className: s.rt_pre} };
      //   return React.createElement(
      //     'pre',
      //     propsWithUniqueKey(props, key),
      //     children
      //   );
      case Element.hyperlink: {
        const parsedLink = (element as RTLinkNode).data as Link;

        let targetAttr;
        let relAttr;
        if (parsedLink.target) {
          targetAttr = parsedLink.target;
          relAttr = { rel: 'noopener' };
        } else {
          targetAttr = {};
          relAttr = {};
        }
        props = Object.assign(
          {
            className: 'font-bold underline visited:text-SnapBlue',
            href: parsedLink.url || linkResolver(parsedLink),
          },
          targetAttr,
          relAttr
        );
        return React.createElement(
          'a',
          propsWithUniqueKey(props, key),
          children
        );
      }
      // case Element.image:
      //   props = {
      //     className: s.rt_img,
      //     src: element.url,
      //     alt: element.alt || '',
      //   };
      //   return React.createElement('img', propsWithUniqueKey(props, key));
      case Element.list:
        props = { className: 'bodyFont ml-[12px] p-0' };
        return React.createElement(
          'ul',
          propsWithUniqueKey(props, key),
          children
        );
      case Element.oList:
        return (
          <ol
            className="[&>*]:bodyFont ml-[14px]"
            style={{ listStyleType: 'number' }}
          >
            {children}
          </ol>
        );
      case Element.listItem:
        props = { className: 'bodyFont' };
        return React.createElement(
          'li',
          propsWithUniqueKey(props, key),
          children
        );
      // case Element.oList:
      //   props = { className: s.rt_ol };
      //   return React.createElement(
      //     'ol',
      //     propsWithUniqueKey(props, key),
      //     children
      //   );
      // case Element.oListItem:
      //   props = { className: s.rt_oli };
      //   return React.createElement(
      //     'li',
      //     propsWithUniqueKey(props, key),
      //     children
      //   );
      // case Element.embed:
      //   props = { className: s.rt_embed };
      //   return React.createElement(
      //     'embed',
      //     propsWithUniqueKey(props, key),
      //     children
      //   );
      // case Element.strong:
      //   props = { className: s.rt_strong };
      //   return React.createElement(
      //     'strong',
      //     propsWithUniqueKey(props, key),
      //     children
      //   );
      // case Element.em:
      //   props = { className: s.rt_em };
      //   return React.createElement(
      //     'em',
      //     propsWithUniqueKey(props, key),
      //     children
      //   );

      // Return null to stick with the default behavior
      default:
        return null;
    }
  };

  return _richTextSerializer;
};

// Default rendering for prismic rich text field
export const richTextSerializer: JSXFunctionSerializer = function (
  type,
  element,
  content,
  children,
  key
) {
  const htmlSerializer = richTextSerializerWithOptions({ useHeadingIds: true });
  return htmlSerializer(type, element, content, children, key);
};
