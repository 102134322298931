import { colors } from '@snap/colors';
import { iconSize, IconMenuProps } from '../constants';

export function IconLinkedIn({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      data-testid="icon-linkedin"
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 44 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.76914 4.53054C9.76914 7.00155 7.82763 8.94306 5.35662 8.94306C2.8856 8.94306 0.944092 7.00155 0.944092 4.53054C0.944092 2.05952 2.8856 0.118011 5.35662 0.118011C7.82763 0.118011 9.76914 2.05952 9.76914 4.53054ZM9.76914 12.4731H0.944092V40.7133H9.76914V12.4731ZM23.8892 12.4731H15.0642V40.7133H23.8892V25.8872C23.8892 17.5916 34.4793 16.8856 34.4793 25.8872V40.7133H43.3043V22.8866C43.3043 8.94306 27.5957 9.47257 23.8892 16.3561V12.4731Z"
        fill={iconColor ?? colors.SnapWhite}
      />
    </svg>
  );
}
