const capitalize = (word: string) => {
  return word[0]?.toUpperCase() + word?.slice(1, word.length)?.toLowerCase();
};

export const formatAddress = (address: string) => {
  const splitAddressArray = address.split(',');
  const splitAddressArrayLength = splitAddressArray.length;
  const initialAddress = splitAddressArray.slice(
    0,
    splitAddressArrayLength - 1
  );

  const initialAddressArray = initialAddress.map((sentence) => {
    const sentenceSplitArray = sentence.split(' ');

    const reformedSentence = sentenceSplitArray
      .map((word) => {
        if (word.length) {
          return capitalize(word);
        }
        return null;
      })
      .join(' ');

    return reformedSentence;
  });

  const cityPincode = splitAddressArray
    .slice(splitAddressArrayLength - 1, splitAddressArrayLength)
    .join('')
    .toUpperCase();

  const formattedAddress = initialAddressArray.join(',') + ',' + cityPincode;

  return formattedAddress;
};
