export const iconSize = {
  xxl: '160',
  xl: '96',
  lg: '64',
  md: '48',
  sm: '32',
  xs: '24',
  xxs: '16',
  xxxs: '12',
};

export interface IconMenuProps {
  iconColor?: string;
  className?: string;
  size?: 'xs' | 'xxs' | 'xxxs';
}

export interface IconProps {
  iconColor?: string;
  className?: string;
  size?: 'xxxs' | 'xxl' | 'xl' | 'lg' | 'md' | 'sm';
}
