import { FormControl, styled } from '@mui/material';

export const FormControlStyled = styled(FormControl)(() => ({
  '& .MuiInputBase-input': {
    padding: '16px !important',
  },
  '& .MuiSelect-icon': {
    top: 'calc(50% - 12px)',
  },
}));
