import { colors } from '@snap/colors';
import { IconMenuProps } from '../constants';

export function IconMenuOmnichannel({ iconColor, className }: IconMenuProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-omnichannel"
    >
      <path
        d="M6.10352e-05 8.23077L1.23083 1H18.9231L19.604 5L14.9001 5C13.2911 5 12.0001 6.30734 12.0001 7.90353L12.0001 18H9.00006V10H5.00006V18H1.00006V10H6.10352e-05V8.23077Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0001 7.90353C14.0001 7.40452 14.403 7 14.9001 7H23.1001C23.5971 7 24.0001 7.40452 24.0001 7.90353V22.0965C24.0001 22.5955 23.5971 23 23.1001 23H14.9001C14.403 23 14.0001 22.5955 14.0001 22.0965V7.90353ZM22.0001 11H16.0001V10H22.0001V11ZM20.0001 14H16.0001V13H20.0001V14ZM19.0001 22C19.5523 22 20.0001 21.5523 20.0001 21C20.0001 20.4477 19.5523 20 19.0001 20C18.4478 20 18.0001 20.4477 18.0001 21C18.0001 21.5523 18.4478 22 19.0001 22Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
    </svg>
  );
}
