import { useCallback } from 'react';
import { useCreateCode } from '../api/account-takeover/useCreateCode';
import { ErrorType } from './types';
import { createCommandHook } from '@ee-monorepo/shared/utilities/hooks';

export const useCreateCodeCommand = createCommandHook(() => {
  const { createCode, createCodeLoading } = useCreateCode();

  const execute = useCallback(
    async (params: { token: string; value: string; optin: boolean }) => {
      const response = await createCode(
        params.token,
        params.value,
        params.optin
      );
      const statusCode = response?.status;

      let errors: ErrorType[] = [];
      switch (statusCode) {
        case 200:
          errors = [];
          break;
        case 400:
          errors = [ErrorType.INVALID_DATA];
          break;
        case 412:
          errors = [ErrorType.TOKEN_INVALID];
          break;
        case 500:
        case 502:
          errors = [ErrorType.APPLICATION_ERROR];
          break;
        default:
          errors = [ErrorType.CONNECTIVITY_ISSUE];
      }
      return {
        statusCode,
        errors,
        data: {
          ...(response?.data || {}),
        },
      };
    },
    [createCode]
  );

  return {
    execute,
    executing: createCodeLoading,
  };
});
