import { Button } from '@ee-monorepo/shared/ui/button';
import { FieldControl } from '@ee-monorepo/shared/ui/field-control';
import { FormattedTextField } from '@ee-monorepo/shared/ui/text-field';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import { useForm } from 'react-hook-form';
import {
  OTPFormSchema,
  OTPFormSchemaData,
  ResendDialogFormSchemaData,
} from './schema';
import { useEffect, useState } from 'react';
import ResendOtpDialog from './resend-dialog';
import { formatPhone } from '@ee-monorepo/shared/utilities/functions';
import { useAuth } from '../auth-provider/auth-provider';
import router from 'next/router';
import { useValidateCodeCommand } from '../../commands/useValidateCodeCommand';
import { useCreateCodeCommand } from '../../commands/useCreateCodeCommand';
import { useAppMessage } from '@ee-monorepo/shared/ui/app-message';
import {
  useFormFieldsKeyPress,
  useHandHeld,
} from '@ee-monorepo/shared/utilities/hooks';
import { useFlowContext } from '@ee-monorepo/shared/context/flows';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { ErrorType } from '../../commands/types';

export const VerifyOTPForm = () => {
  const t = useTranslations('account_takeover_form');
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const { user, setUserData } = useAuth();
  const validateCode = useValidateCodeCommand();
  const createCode = useCreateCodeCommand();
  const { showErrorMessage, closeMessage } = useAppMessage();
  const isMobile = useHandHeld();
  const [pageLoading, setPageLoading] = useState(false);
  const [apiErrorOnSubmit, setApiErrorOnSubmit] = useState(false);
  const [resendApiErrorOnSubmit, setResendApiErrorOnSubmit] = useState(false);
  const { next } = useFlowContext();
  const { tracking } = useTrackingContext();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<any>({
    defaultValues: {
      otp: '',
    },
    mode: 'all',
    resolver: yupResolver(OTPFormSchema),
  });

  const openResendModal = () => {
    tracking?.logClickEvent({
      click_type: 'link',
      placement: 'body',
      click_value: 'resend code',
    });
    setIsResendModalOpen(true);
    tracking?.logContentViewedEvent({
      content_name: 'resend code modal',
      content_value: "didn't get the code",
    });
  };

  const closeModal = () => {
    setIsResendModalOpen(false);
  };

  const updateResendApiError = (newValue: boolean) => {
    setResendApiErrorOnSubmit(newValue);
  };

  const showErrorBanner = (message: string) => {
    showErrorMessage({
      message,
      showLabel: false,
      autoHideDuration: 30000,
      customStyle: { top: '2rem' },
      showCloseIcon: false,
      customAlertStyle: { width: isMobile ? '382px' : '528px', height: '48px' },
    });
  };

  const formattedPhone =
    user.type === 'PHONE' ? formatPhone(user.phoneNumber!) : '';

  const onSubmitHandler = async (data: OTPFormSchemaData) => {
    tracking?.logClickEvent({
      click_type: 'cta',
      placement: 'body',
      click_value: 'verify',
    });
    const response = await validateCode.execute({
      token: user.token,
      code: data.otp,
    });
    if (response.success) {
      setApiErrorOnSubmit(false);
      next({ valid: true });
    } else if (response.errors?.includes(ErrorType.INCORRECT_DATA)) {
      setApiErrorOnSubmit(true);
      showErrorBanner(t('try_again'));
    } else {
      next({ valid: false });
    }
    router.events.on('routeChangeStart', () => setPageLoading(true));
    router.events.on('routeChangeComplete', () => setPageLoading(false));
  };

  const onKeyPressed = () => {
    setApiErrorOnSubmit(false);
  };
  const { formRef } = useFormFieldsKeyPress(onKeyPressed);

  const resendOtpSubmit = async (data: ResendDialogFormSchemaData) => {
    tracking?.logClickEvent({
      click_type: 'cta',
      placement: 'resend code modal',
      click_value: 'resend code',
    });
    let valueToUpdate = '';
    let isOptin = false;
    if (user.type === 'PHONE') {
      valueToUpdate = data.phone!;
      isOptin = true;
    } else if (user.type === 'EMAIL') {
      valueToUpdate = data.email!;
      isOptin = false;
    }
    if (valueToUpdate) {
      setUserData({
        ...user,
        [user.type === 'PHONE' ? 'phoneNumber' : 'emailId']: valueToUpdate,
      });

      const response = await createCode.execute({
        token: user.token,
        value: valueToUpdate,
        optin: isOptin,
      });

      if (response.success) {
        setResendApiErrorOnSubmit(false);
        closeModal();
      } else if (response.errors?.includes(ErrorType.INVALID_DATA)) {
        setResendApiErrorOnSubmit(true);
        const invalidToasterMessage = user.type === 'EMAIL'
          ? t('invalidEmailError')
          : user.type === 'PHONE' ?
            t('invalidPhoneError') : t('try_again');
        showErrorBanner(invalidToasterMessage);
      } else {
        // All other errors, redirecting to the error page
        closeMessage();
        next({ valid: false });
      }
    }
  };

  useEffect(() => {
    closeMessage();
  }, [watch('otp')]);

  return (
    <div>
      <h3>{t('verifyOTPTitle')}</h3>
      {user.phoneNumber || user.emailId ? (
        <p>
          {t('sendOTPToMessage', {
            otpSentTo: user.type === 'PHONE' ? formattedPhone : user.emailId,
          })}
        </p>
      ) : null}
      <form
        className="mt-10"
        ref={formRef}
        data-testid="verify-otp-form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div>
          <FieldControl
            fieldName="otp"
            control={control}
            render={(field, invalid) => (
              <FormattedTextField
                {...field}
                fullWidth
                label={t('otpPlaceholder')}
                mask="000000"
                isPassword={false}
                maskedValue={false}
                error={invalid}
                data-testid="otpField"
              />
            )}
          />
        </div>
        <div className="flex flex-row justify-start">
          <Button
            type="submit"
            variant="link"
            className="cursor-pointer"
            onClick={openResendModal}
            data-testid="resend-otp"
          >
            {t('resendCodeLinkText')}
          </Button>
        </div>
        <div className="flex flex-col md:flex-row justify-end mt-10">
          <Button
            loading={
              validateCode.executing || createCode.executing || pageLoading
            }
            type="submit"
            disabled={
              !isValid ||
              validateCode.executing ||
              createCode.executing ||
              pageLoading ||
              apiErrorOnSubmit
            }
            data-testid="verify-otp-submit"
          >
            {t('verifyBtnText')}
          </Button>
        </div>
      </form>
      <div>
        {isResendModalOpen && (
          <ResendOtpDialog
            closeModal={closeModal}
            type={user.type!}
            updateApiError={updateResendApiError}
            apiErrorOnSubmit={resendApiErrorOnSubmit}
            onSubmit={resendOtpSubmit}
            closeToast={closeMessage}
          />
        )}
      </div>
    </div>
  );
};
export default VerifyOTPForm;
