import {
  ConditionalsArgs,
  FlowModel,
  FlowRouter,
  NextConditions,
} from './types';
import { resolveNextRoutingConditions } from './route-resolver-conditions';
import { pushRoute } from './utils';

export function resolveRouting(
  stepName: string,
  model: FlowModel,
  router: FlowRouter,
  direction: 'next' | 'back' = 'next',
  args?: ConditionalsArgs
) {
  const currentStep = model.steps[stepName];

  if (!currentStep) {
    throw new Error(`Current step "${stepName}" not found in flow model`);
  }

  if (direction === 'next') {
    routeNext(stepName, model, router, args);
  } else {
    routeBack(stepName, model, router);
  }
}

export function routeNext(
  stepName: string,
  model: FlowModel,
  router: FlowRouter,
  args: ConditionalsArgs | undefined
) {
  const nextStepName = model.steps[stepName]?.next ?? '';

  _routeNext(
    stepName,
    model.steps[stepName]?.nextConditions,
    nextStepName,
    model,
    router,
    args
  );
}

function routeBack(stepName: string, model: FlowModel, router: FlowRouter) {
  const prevStepName = model.steps[stepName]?.previous ?? '';

  const prevRoute = model.steps[prevStepName]?.route;

  if (prevStepName && !prevRoute) {
    throw new Error(`Previous step "${prevStepName}" not found in flow model`);
  }

  if (prevStepName && prevRoute) {
    pushRoute(prevRoute, router, false);
  }
}

export function routeLaunch(
  model: FlowModel,
  router: FlowRouter,
  args?: ConditionalsArgs
) {
  const nextStepName = model.launch.next ?? '';

  _routeNext(
    'launch',
    model.launch?.nextConditions,
    nextStepName,
    model,
    router,
    args
  );
}

function _routeNext(
  stepName: string,
  nextConditions: NextConditions | undefined,
  nextStepName: string,
  model: FlowModel,
  router: FlowRouter,
  args: ConditionalsArgs | undefined
) {
  const nextRoute = model.steps[nextStepName]?.route;
  const preserveParams = model.steps[nextStepName]?.preserveParams;

  if (nextStepName && !nextRoute) {
    throw new Error(`Next step "${nextStepName}" not found in flow model`);
  }
  const hasNextRoute = !!nextStepName && !!nextRoute;

  if (args && nextConditions && nextConditions.length) {
    if (
      resolveNextRoutingConditions(
        stepName,
        model,
        router,
        nextConditions,
        hasNextRoute,
        args
      )
    ) {
      return;
    }
  } else if (!hasNextRoute) {
    throw new Error(
      `Next conditions not found for step "${stepName}" in flow model`
    );
  }

  return pushRoute(nextRoute as string, router, preserveParams as boolean);
}
