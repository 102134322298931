import { ReactNode, HTMLAttributes, forwardRef, ForwardedRef } from 'react';
import s from './shared-ui-card.module.scss';
import cn from 'classnames';

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  children: ReactNode;
  borderOnHover?: boolean;
}

export const Card = forwardRef(
  (props: CardProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, active, borderOnHover = true, ...htmlProps } = props;
    return (
      <div
        ref={ref}
        {...htmlProps}
        className={cn(
          s.card,
          'p-4',
          { [s.active]: active },
          { [s.hover]: borderOnHover },
          props.className
        )}
      >
        {children}
      </div>
    );
  }
);

export function SolidCard(props: CardProps) {
  const { children, active, borderOnHover = false, ...htmlProps } = props;
  return (
    <div
      {...htmlProps}
      className={cn(
        s.solidCard,
        'bg-SnapGrey100',
        'p-4',
        { [s.active]: active },
        { [s.hover]: borderOnHover },
        props.className
      )}
    >
      {children}
    </div>
  );
}
