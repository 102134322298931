import { SvgIconProps, SvgIcon } from '@mui/material';

export interface IconCloseProps extends SvgIconProps {
  iconColor: string;
}

export function IconClose({ iconColor, ...rest }: IconCloseProps) {
  return (
    <SvgIcon
      {...rest}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      data-testid="icon-close"
    >
      <g clipPath="url(#clip0_112_1276)">
        <path
          d="M18.295 7.115C18.6844 6.72564 18.6844 6.09436 18.295 5.705C17.9056 5.31564 17.2744 5.31564 16.885 5.705L12 10.59L7.115 5.705C6.72564 5.31564 6.09436 5.31564 5.705 5.705C5.31564 6.09436 5.31564 6.72564 5.705 7.115L10.59 12L5.705 16.885C5.31564 17.2744 5.31564 17.9056 5.705 18.295C6.09436 18.6844 6.72564 18.6844 7.115 18.295L12 13.41L16.885 18.295C17.2744 18.6844 17.9056 18.6844 18.295 18.295C18.6844 17.9056 18.6844 17.2744 18.295 16.885L13.41 12L18.295 7.115Z"
          fill={iconColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_112_1276">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
