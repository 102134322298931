import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

/**
 * Load fingerprint data from third party
 *
 * @returns
 */
export function useFingerprintJS(options?: { immediate: boolean }) {
  const { isLoading, error, data, getData } = useVisitorData(
    { extendedResult: true },
    { immediate: options?.immediate !== false }
  );

  useEffect(() => {
    if (
      error &&
      process.env.NEXT_PUBLIC_SENTRY_DSN &&
      ['qa', 'production'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT)
    ) {
      Sentry.captureMessage(`FingerprintJS service failed: ${error}`, 'fatal');
    }
  }, [error]);

  return {
    /**
     * Get fingerprint with imperative approach calling the function,
     * however getData function reference is inconsistent, be careful when passing through useEffect deps or array of deps
     * it returns a promise
     */
    getFingerprint: getData,
    /**
     * Fingerprint returned by the hook
     */
    fingerprint: data,
    /**
     * loading flag
     */
    isLoading,
  };
}
