import { IconMenuProps } from '../constants';

export function IconMenuOnline({ iconColor, className }: IconMenuProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-online"
    >
      <path
        d="M2 4V2H5.27L6.21 4H21C21.55 4 22 4.45 22 5C22 5.17 21.96 5.34 21.88 5.48L18.3 11.97C17.96 12.59 17.3 13 16.55 13H9.1L8.2 14.63L8.17 14.75C8.17 14.89 8.28 15 8.42 15H20V17H8C6.9 17 6 16.1 6 15C6 14.65 6.09 14.32 6.25 14.04L7.6 11.59L4 4H2Z"
        fill={iconColor}
      />
      <path
        d="M6.01001 20C6.01001 18.9 6.90001 18 8.00001 18C9.10001 18 10 18.9 10 20C10 21.1 9.10001 22 8.00001 22C6.90001 22 6.01001 21.1 6.01001 20Z"
        fill={iconColor}
      />
      <path
        d="M16.01 20C16.01 18.9 16.9 18 18 18C19.1 18 20 18.9 20 20C20 21.1 19.1 22 18 22C16.9 22 16.01 21.1 16.01 20Z"
        fill={iconColor}
      />
    </svg>
  );
}
