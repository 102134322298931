import { IconMenuProps } from '../constants';

export function IconEyeOpen({ iconColor, className }: IconMenuProps) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 20 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>D6210E89-6AD0-44BA-8D9B-28B3D008168F@1.00x</title>
      <defs>
        <path
          d="M10,5.41666667 C13.1583333,5.41666667 15.975,7.19166667 17.35,10 C15.975,12.8083333 13.1583333,14.5833333 10,14.5833333 C6.84166667,14.5833333 4.025,12.8083333 2.65,10 C4.025,7.19166667 6.84166667,5.41666667 10,5.41666667 L10,5.41666667 Z M10,3.75 C5.83333333,3.75 2.275,6.34166667 0.833333333,10 C2.275,13.6583333 5.83333333,16.25 10,16.25 C14.1666667,16.25 17.725,13.6583333 19.1666667,10 C17.725,6.34166667 14.1666667,3.75 10,3.75 Z M10,7.91666667 C11.15,7.91666667 12.0833333,8.85 12.0833333,10 C12.0833333,11.15 11.15,12.0833333 10,12.0833333 C8.85,12.0833333 7.91666667,11.15 7.91666667,10 C7.91666667,8.85 8.85,7.91666667 10,7.91666667 L10,7.91666667 Z M10,6.25 C7.93333333,6.25 6.25,7.93333333 6.25,10 C6.25,12.0666667 7.93333333,13.75 10,13.75 C12.0666667,13.75 13.75,12.0666667 13.75,10 C13.75,7.93333333 12.0666667,6.25 10,6.25 Z"
          id="show-icon"
        ></path>
      </defs>
      <g
        id="Mobile-User-flow"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-Assets" transform="translate(-56.000000, -81.000000)">
          <g
            id="icon/action/visibility_24px"
            transform="translate(56.000000, 78.000000)"
          >
            <mask id="mask-2" fill="white">
              <use xlinkHref="#show-icon"></use>
            </mask>
            <use
              fillOpacity="0.54"
              fill="#000000"
              fillRule="evenodd"
              xlinkHref="#show-icon"
            ></use>
          </g>
        </g>
      </g>
    </svg>
  );
}
