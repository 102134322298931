import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';
import { ReactNode, useEffect } from 'react';
import s from './shared-ui-dialog.module.scss';

export interface DialogTitleProps {
  title?: string;
  titleAlignment?: 'center' | 'start' | 'end';
  onClose(event: unknown, reason: string): void;
  hideCloseButton?: boolean;
}

export function DialogTitle(props: DialogTitleProps) {
  const { title, titleAlignment, onClose, hideCloseButton } = props;
  useEffect(() => {
    if (hideCloseButton === true) {
      setTimeout((e: unknown) => onClose(e, 'closeButton'), 3000);
    }
  }, [hideCloseButton, onClose]);

  return (
    <MuiDialogTitle className="pl-4 md:pl-6 md:pr-0 py-0 p-0 mr-2">
      <div className="flex items-center md:justify-between">
        {title && (
          <span className={cn('block pl-0', `text-${titleAlignment}`, s.title)}>
            {title}
          </span>
        )}
        {hideCloseButton === false ? (
          <IconButton
            className="snap-color-grey-500 ml-auto !py-3 !mr-3"
            data-testid="close-button"
            aria-label="close"
            onClick={(e) => onClose(e, 'closeButton')}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
}

export type SnapDialogProps = {
  title?: string;
  titleAlignment?: 'center' | 'start' | 'end';
  id: string;
  children: ReactNode;
  fullScreenOnMobile?: boolean;
  centered?: boolean;
  hideCloseButton?: boolean;
  dialogContentClasses?: string;
  dialogPaperClasses?: string;
} & DialogProps;

export function SnapDialog({
  title,
  titleAlignment = 'center',
  id,
  children,
  fullScreenOnMobile = false,
  centered = false,
  hideCloseButton = false,
  dialogPaperClasses,
  dialogContentClasses,
  ...dialogProps
}: SnapDialogProps) {
  const isMobile = useHandHeld();
  return (
    <Dialog
      data-testid="snap-dialog"
      {...dialogProps}
      classes={
        isMobile
          ? {
              paper: cn(
                s.radius,
                !fullScreenOnMobile ? s.paper : s.fullscreen,
                !centered ? s.fixed_below : ''
              ),
            }
          : { paper: dialogPaperClasses }
      }
    >
      <DialogTitle
        title={title}
        data-testid="dialog-title"
        hideCloseButton={hideCloseButton}
        titleAlignment={titleAlignment}
        onClose={dialogProps.onClose}
      />

      <DialogContent
        data-testid="dialog-content"
        classes={{
          root: cn('px-6 pb-6 pt-0', dialogContentClasses),
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}
