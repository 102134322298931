// ResendOtpDialog.js
import { SnapDialog } from '@ee-monorepo/shared/ui/dialog';
import React, { useEffect } from 'react';
import { Button } from '@ee-monorepo/shared/ui/button';
import { FieldControl } from '@ee-monorepo/shared/ui/field-control';
import {
  FormattedTextField,
  TextField,
} from '@ee-monorepo/shared/ui/text-field';
import { useTranslations } from 'next-intl';
import {
  useFormFieldsKeyPress,
  useHandHeld,
} from '@ee-monorepo/shared/utilities/hooks';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ImageWrapper } from '@ee-monorepo/prismic/shared/ui';
import { ResendOTPFormSchema } from './schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';

interface ResendDialogProps {
  closeModal: () => void;
  type: string;
  apiErrorOnSubmit: boolean;
  updateApiError: (apiError: boolean) => void;
  onSubmit: (data: any) => void;
  closeToast: () => void;
}
const ResendOtpDialog = ({
  closeModal,
  type,
  apiErrorOnSubmit,
  updateApiError,
  onSubmit,
  closeToast,
}: ResendDialogProps) => {
  const t = useTranslations('account_takeover_form');
  const isMobile = useHandHeld();
  const { tracking } = useTrackingContext();

  const inputLabel = type === 'PHONE' ? 'phoneLabel' : 'emailLabel';
  const inputTestId = type === 'PHONE' ? 'phoneInput' : 'emailInput';
  const submitTestId =
    type === 'PHONE' ? 'change-phone-submit' : 'change-email-submit';

  const dialogStyles = {
    bottom: isMobile ? 0 : 'auto',
    marginBottom: isMobile ? 0 : 'initial',
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<any>({
    defaultValues: {
      email: '',
      phone: '',
      type,
    },
    mode: 'all',
    resolver: yupResolver(ResendOTPFormSchema),
  });

  const onCloseModal = () => {
    tracking?.logClickEvent({
      click_type: 'button',
      placement: 'resend code modal',
      click_value: 'close',
    });
    closeModal();
  };

  const onKeyPressed = () => {
    updateApiError(false);
  };
  const { formRef } = useFormFieldsKeyPress(onKeyPressed);

  useEffect(() => {
    closeToast();
  }, [watch('email'), watch('phone')]);

  return (
    <SnapDialog
      title=""
      hideCloseButton={false}
      id="resend-otp-dialog"
      data-testid="resend-dialog-form"
      open={true}
      fullScreenOnMobile={false}
      centered={true}
      onClose={onCloseModal}
      PaperProps={{
        sx: {
          maxWidth: !isMobile && '660px',
          width: !isMobile && '65%',
        },
        style: dialogStyles,
      }}
    >
      <div className={`flex mb-4`}>
        <ImageWrapper
          src="/assets/icons/account-takeover/sms_failed.svg"
          alt={t('error_icon_alt')}
          width={40}
          height={40}
        />
      </div>
      <h3 className="mb-2">{t('resendDialogTitle')}</h3>
      <p>
        {type === 'PHONE' ? t('newPhoneDescription') : t('newEmailDescription')}
      </p>
      <form
        className="mt-10"
        ref={formRef}
        data-testid="resend-otp-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-1 mt-10">
          <div>
            <FieldControl
              fieldName={type.toLowerCase()}
              control={control}
              render={(field, invalid) =>
                type === 'PHONE' ? (
                  <FormattedTextField
                    {...field}
                    fullWidth
                    label={t(inputLabel)}
                    error={invalid}
                    data-testid={inputTestId}
                    mask="(000) 000-0000"
                    maskedValue={false}
                    isPassword={true}
                  />
                ) : (
                  <TextField
                    type="text"
                    {...field}
                    fullWidth
                    label={t(inputLabel)}
                    inputProps={{
                      pattern: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
                    }}
                    error={invalid}
                    data-testid={inputTestId}
                  />
                )
              }
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-end mt-10 mb-4">
          <Button
            type="submit"
            disabled={!isValid || apiErrorOnSubmit}
            data-testid={submitTestId}
          >
            {t('resendCodeBtnText')}
          </Button>
        </div>
      </form>
    </SnapDialog>
  );
};

export default ResendOtpDialog;
