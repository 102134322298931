import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconCarAudio({ iconColor, size = 'lg', className }: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-car-audio"
      className={className}
    >
      <path
        d="M136.494 28.4148C134.959 20.62 128.124 15 120.18 15H39.5063C31.5225 15 24.6656 20.6745 23.1724 28.5174L17.6176 57.693H5.77572C3.38361 57.693 1.38735 59.5195 1.17523 61.9021L0.44739 70.0776C0.206856 72.7794 2.33538 75.1059 5.04788 75.1059L86.3303 75.1066C86.9385 74.1043 87.2824 72.9406 87.2824 71.7063C87.2824 69.4095 86.0917 67.3573 84.2229 66.1338L81.713 64.4905L84.9996 59.4707L87.5095 61.114C90.9667 63.3775 93.2824 67.2636 93.2824 71.7063C93.2824 72.8856 93.1193 74.0257 92.8149 75.1066H99.2103C99.365 73.9947 99.4448 72.86 99.4448 71.7072C99.4448 65.7068 97.2821 60.1932 93.6509 55.8518L91.7262 53.5506L96.3286 49.7012L98.2533 52.0024C102.742 57.3691 105.445 64.2302 105.445 71.7072C105.445 72.856 105.381 73.9902 105.257 75.1066H112.369C112.474 73.9889 112.528 72.8547 112.528 71.7068C112.528 62.1143 108.756 53.4844 102.727 47.349L100.624 45.2092L104.904 41.0039L107.006 43.1437C114.125 50.3884 118.528 60.5234 118.528 71.7068C118.528 72.8517 118.482 73.9857 118.392 75.1066L154.951 75.1059C157.664 75.1059 159.792 72.7794 159.552 70.0776L158.824 61.9021C158.612 59.5195 156.616 57.693 154.224 57.693H142.259L136.494 28.4148Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M112.369 75.1066C111.593 83.329 108.034 90.6634 102.727 96.0645L100.624 98.2043L104.904 102.41L107.006 100.27C113.396 93.7669 117.598 84.935 118.392 75.1066L145.693 75.1068L145.692 141.305C145.692 143.346 144.038 145 141.997 145H127.218C125.177 145 123.523 143.346 123.523 141.305V126.85H36.4769V141.305C36.4769 143.346 34.8226 145 32.7819 145H18.0022C15.9615 145 14.3072 143.346 14.3072 141.305V75.1068L86.3303 75.1066C85.8068 75.9694 85.0873 76.7129 84.2229 77.2788L81.713 78.9221L84.9996 83.9419L87.5095 82.2986C90.0489 80.636 91.9725 78.0977 92.8149 75.1066H99.2103C98.5597 79.7828 96.5845 84.0553 93.6509 87.5627L91.7262 89.8639L96.3286 93.7133L98.2533 91.4121C102.052 86.8699 104.572 81.2571 105.257 75.1066H112.369Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M76 101.76L54.9583 83.9151H42V59.4955H54.9586L76 41.6505V101.76Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
    </svg>
  );
}
