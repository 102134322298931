import React from 'react';
import { Element } from '@prismicio/helpers';

import s from './story-serializer.module.scss';
import { richTextSerializerWithOptions } from './rich-text-serializer';
import { JSXFunctionSerializer } from '@prismicio/react';

// -- Function to add unique key to props
const propsWithUniqueKey = (props = {}, key: string) => {
  return { ...props, key };
};

// Default rendering for prismic rich text field
export const storySerializer: JSXFunctionSerializer = function (
  type,
  element,
  content,
  children,
  key
) {
  let props = {};
  switch (type) {
    case Element.list:
      props = { className: '-ml-3' };
      return React.createElement(
        'ul',
        propsWithUniqueKey(props, key),
        children
      );
    case Element.listItem:
      props = { className: 'headingSHeavy text-SnapBlue' };
      return React.createElement(
        'li',
        propsWithUniqueKey(props, key),
        children
      );

    // Return defaults
    default: {
      const htmlSerializer = richTextSerializerWithOptions({
        useHeadingIds: false,
      });
      return htmlSerializer(type, element, content, children, key);
    }
  }
};
