import { MerchantMarkerInterface } from '@ee-monorepo/shared/utilities/types';
import { storeCookie, removeCookie } from './cookies';

export const FOURTEEN_DAYS = 1.21e9;

/**
 * This functions are used to track the user selection
 * This are then going to be used to display banners to the user
 */

export const storeSelectedIndustryCookie = (industry) => {
  if (industry !== 'ALL') {
    storeCookie('last-visited-category', industry, FOURTEEN_DAYS, false);
  } else {
    removeCookie('last-visited-category', false);
  }
};

export const storeSelectedMerchantCookie = (
  merchantInformation: MerchantMarkerInterface
) => {
  if (
    merchantInformation &&
    merchantInformation.id &&
    merchantInformation.dbaName &&
    merchantInformation.industry
  ) {
    storeCookie(
      'selected-merchant',
      {
        id: merchantInformation.id,
        merchantName: merchantInformation.dbaName,
        category: merchantInformation.industry,
      },
      FOURTEEN_DAYS,
      true
    );
    removeCookie('last-visited-category', false);
  }
};
