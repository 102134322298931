import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { ImageWrapper } from '../image-wrapper';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { richTextSerializer } from '@ee-monorepo/prismic/shared/serializers';
import { useTranslations } from 'next-intl';
import { PrismicRichText } from '@prismicio/react';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';

export interface QRCodeDownloadSectionProps {
  variation?: string;
  promote_mobile_title: PrismicFields.RichTextField;
  qr_logo: PrismicFields.Image;
  display_qr_on_left?: boolean;
  apple_store_logo: PrismicFields.Image;
  apple_store_link: string;
  google_store_logo: PrismicFields.Image;
  google_store_link: string;
  classes?: string;
  showMobileScanText?: boolean;
}

export function QRCodeDownloadSection(props: QRCodeDownloadSectionProps) {
  const router = useRouter();
  const translate = useTranslations();
  const isMobile = useHandHeld();
  return (
    <div
      className={cn(
        props.variation === 'callToActionWithRoundImg' && isMobile && 'mt-6'
      )}
    >
      {props.variation === 'default-slice' && (
        <div
          className={cn(
            'md:flex justify-center bg-SnapWhite p-4 mb-4 rounded',
            props.classes === 'forCallToAction'
              ? '[&>p]:headingSHeavy'
              : 'border border-solid border-SnapGrey500 [&>p]:headingSHeavy md:[&>p]:headingMHeavy',
            props.classes
          )}
          data-testid="qr-code-download-section"
        >
          <div className="md:mr-6">
            <div
              className="mb-4 text-center text-SnapGrey500"
              data-testid="qr-code-download-section-title"
            >
              <PrismicRichText
                field={props.promote_mobile_title}
                components={richTextSerializer}
              />
            </div>
            <div className="flex justify-center md:justify-start">
              <ImageWrapper
                className="hidden md:inline mr-4 cursor-pointer"
                src={props.apple_store_logo?.url as string}
                alt={props.apple_store_logo?.alt as string}
                width={props.apple_store_logo?.dimensions?.width}
                height={props.apple_store_logo?.dimensions?.height}
                onClick={() => router.push(props.apple_store_link)}
                data-testid="qr-code-download-section-button"
              />
              <ImageWrapper
                className="hidden md:inline mr-4 cursor-pointer"
                src={props.google_store_logo?.url as string}
                alt={props.google_store_logo?.alt as string}
                width={props.google_store_logo?.dimensions?.width}
                height={props.google_store_logo?.dimensions?.height}
                onClick={() => router.push(props.google_store_link)}
                data-testid="qr-code-download-section-button"
              />
              <ImageWrapper
                className="inline md:hidden mr-6 h-auto cursor-pointer"
                src={props.apple_store_logo?.mobile?.url as string}
                alt={props.apple_store_logo?.mobile?.alt as string}
                width={props.apple_store_logo?.mobile?.dimensions?.width}
                height={props.apple_store_logo?.mobile?.dimensions?.height}
                onClick={() => router.push(props.apple_store_link)}
              />
              <ImageWrapper
                className="inline md:hidden h-auto cursor-pointer"
                src={props.google_store_logo?.mobile?.url as string}
                alt={props.google_store_logo?.mobile?.alt as string}
                width={props.google_store_logo?.mobile?.dimensions?.width}
                height={props.google_store_logo?.mobile?.dimensions?.height}
                onClick={() => router.push(props.google_store_link)}
              />
            </div>
          </div>
          <div className="hidden md:inline pl-4 [&>img]:rounded-lg">
            <ImageWrapper
              src={props.qr_logo?.url as string}
              alt={props.qr_logo?.alt as string}
              width={props.qr_logo?.dimensions?.width}
              height={props.qr_logo?.dimensions?.height}
              data-testid="qr-code-picture"
            />
            {props.showMobileScanText && (
              <div
                className={cn(
                  'text-center',
                  'tagSmallFont',
                  'mt-1',
                  'text-SnapDarkBlue'
                )}
              >
                {translate('shared.mobile_app_scanner_text')}
              </div>
            )}
          </div>
        </div>
      )}
      {props.variation === 'callToActionWithRoundImg' && (
        <div
          className={cn(
            'md:flex mb-4',
            'items-center',
            props.classes === 'forCallToAction'
              ? '[&>p]:headingSHeavy'
              : 'border border-solid border-SnapGrey500 [&>p]:headingSHeavy md:[&>p]:headingMHeavy',
            props.classes
          )}
          data-testid="qr-code-download-section"
        >
          {!props.display_qr_on_left && (
            <div
              className="hidden mr-5 md:inline [&>img]:rounded-lg w-[73px] h-[73px] [&>img]:max-h-full"
              data-testid="qr-code-picture-bottom"
            >
              <ImageWrapper
                src={props.qr_logo?.url as string}
                alt={props.qr_logo?.alt as string}
                width={props.qr_logo?.dimensions?.width}
                height={props.qr_logo?.dimensions?.height}
              />
              {props.showMobileScanText && (
                <div
                  className={cn(
                    'text-center',
                    'tagSmallFont',
                    'mt-1',
                    'text-SnapDarkBlue'
                  )}
                >
                  {translate('shared.mobile_app_scanner_text')}
                </div>
              )}
            </div>
          )}
          <div className="md:mr-6">
            <div className="flex justify-center md:justify-start">
              <ImageWrapper
                className="hidden md:inline mr-4 cursor-pointer"
                src={props.apple_store_logo?.url as string}
                alt={props.apple_store_logo?.alt as string}
                width={props.apple_store_logo?.dimensions?.width}
                height={props.apple_store_logo?.dimensions?.height}
                onClick={() => router.push(props.apple_store_link)}
                data-testid="qr-code-download-section-button"
              />
              <ImageWrapper
                className="hidden md:inline mr-4 cursor-pointer"
                src={props.google_store_logo?.url as string}
                alt={props.google_store_logo?.alt as string}
                width={props.google_store_logo?.dimensions?.width}
                height={props.google_store_logo?.dimensions?.height}
                onClick={() => router.push(props.google_store_link)}
                data-testid="qr-code-download-section-button"
              />
              <ImageWrapper
                className="inline md:hidden mr-6 h-auto cursor-pointer"
                src={props.apple_store_logo?.mobile?.url as string}
                alt={props.apple_store_logo?.mobile?.alt as string}
                width={props.apple_store_logo?.mobile?.dimensions?.width}
                height={props.apple_store_logo?.mobile?.dimensions?.height}
                onClick={() => router.push(props.apple_store_link)}
              />
              <ImageWrapper
                className="inline md:hidden h-auto cursor-pointer"
                src={props.google_store_logo?.mobile?.url as string}
                alt={props.google_store_logo?.mobile?.alt as string}
                width={props.google_store_logo?.mobile?.dimensions?.width}
                height={props.google_store_logo?.mobile?.dimensions?.height}
                onClick={() => router.push(props.google_store_link)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
