import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconMedicalDevices({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-medical-devices"
      className={className}
    >
      <path
        d="M32 34V43"
        stroke={iconColor ?? colors.SnapLightGreen}
        strokeWidth="8"
      />
      <path
        d="M127 34V43"
        stroke={iconColor ?? colors.SnapLightGreen}
        strokeWidth="8"
      />
      <path
        d="M10 54C10 46.8203 15.8203 41 23 41H149V140H10V54Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M80 127C99.8823 127 116 110.882 116 91C116 71.1177 99.8823 55 80 55C60.1177 55 44 71.1177 44 91C44 110.882 60.1177 127 80 127Z"
        fill={iconColor ?? colors.SnapWhite}
      />
      <path
        d="M105 45V33C105 27.4772 100.523 23 95 23H65C59.4772 23 55 27.4772 55 33V45"
        stroke={iconColor ?? colors.SnapDarkBlue}
        strokeWidth="8"
      />
      <path
        d="M74 68H86V114H74V68Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
      <path
        d="M103 85V97H57V85H103Z"
        fill={iconColor ?? colors.SnapLightGreen}
      />
    </svg>
  );
}
