import { FlowRouter } from '@ee-monorepo/shared/context/flows';
import { createTranslations } from '@ee-monorepo/shared/utilities/functions';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

export * from './lib/verify/verify';
export * from './lib/change-phone/change-phone';
export * from './lib/change-email/change-email';
export * from './lib/verify-otp/verify-otp';
export * from './lib/update-success/update-success';
export * from './lib/error/error';
export * from './lib/change-email/email-validation-dialog';
export * from './lib/error/invalid-link';
export * from './lib/auth-provider/auth-provider';
export * from './modals/account-takeover.model';

export const getTranslations = createTranslations(
  'account_takeover_form',
  (locale) => {
    return require(`./i18n/${locale}.json`);
  }
);

export function useFlowRouter() {
  const { locale = 'en-US', push, query } = useRouter();
  const flowRouter: FlowRouter = useMemo(
    () => ({ push, query, locale }),
    [push, query, locale]
  );
  return flowRouter;
}
