import { useState } from 'react';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { PrismicLink } from '../link/prismic-link';
import cn from 'classnames';
import { PartnerModal } from '@ee-monorepo/shared/ui/partner-modal';
import { Button, ButtonProps } from '@ee-monorepo/shared/ui/button';
import {
  useMerchantShortFormContext,
  useMerchantLongFormContext,
} from '@ee-monorepo/shared/context/merchant-form';
import { ApplyButton } from '@ee-monorepo/shared/ui/apply-button';

export type PrismicButtonProps = {
  className?: string;
  buttonClassName?: string;
  label: string | React.ReactNodeArray | React.ReactElement;
  link?: PrismicFields.Link;
  showButton?: boolean;
  actionType?: PrismicFields.ButtonAction;
  variant?: PrismicFields.ButtonVariant;
  'data-testid'?: string;
  inverseMode?: boolean;
  onClick?: () => void;
} & Omit<ButtonProps, 'variant'>;

const PrismicButtonWithLink = ({
  label,
  link,
  actionType,
  inverseMode = false,
  variant,
  className,
  buttonClassName,
  ...rest
}: PrismicButtonProps) => {
  return (
    <PrismicLink
      link={link}
      className={cn('text-decoration-none', className)}
      render={({ className, ...renderProps }) => (
        <Button
          className={cn(className, buttonClassName)}
          data-testid={`prismicButtonWithLink${rest['data-testid'] ?? ''}`}
          inverseMode={inverseMode}
          variant={variant}
          {...rest}
          {...renderProps}
        >
          {label}
        </Button>
      )}
    />
  );
};

const PrismicButtonWithAction = ({
  actionType,
  onClick,
  inverseMode = false,
  variant,
  ...props
}: PrismicButtonProps) => {
  const [show, setShow] = useState(false);
  const { setShow: setShowShortFormDialog } = useMerchantShortFormContext();
  const { setShow: setShowLongFormDialog } = useMerchantLongFormContext();

  if (actionType === 'apply') {
    return (
      <ApplyButton
        data-testid={`prismicButtonWithAction${props['data-testid'] ?? ''}`}
        inverseMode={inverseMode}
        variant={variant}
        onClick={onClick}
        {...props}
      >
        {props.label}
      </ApplyButton>
    );
  }

  return (
    <>
      <Button
        onClick={() => {
          onClick?.();
          switch (actionType) {
            case 'shortForm':
              setShowShortFormDialog(true);
              break;
            case 'partner':
              setShow(true);
              break;
            case 'longForm':
              setShowLongFormDialog(true);
              break;
          }
        }}
        data-testid={`prismicButtonWithAction${props['data-testid'] ?? ''}`}
        inverseMode={inverseMode}
        variant={variant}
        {...props}
      >
        {props.label}
      </Button>
      <PartnerModal show={show} handleClose={() => setShow(false)} />
    </>
  );
};

export const PrismicButton = ({
  link,
  actionType,
  showButton,
  ...rest
}: PrismicButtonProps) => {
  if (!showButton) return null;

  const buttonProps = {
    ...rest,
    link,
    actionType,
  };

  if (['partner', 'apply', 'longForm', 'shortForm'].includes(actionType)) {
    return <PrismicButtonWithAction {...buttonProps} />;
  }

  if (link && link.link_type !== 'Any') {
    return <PrismicButtonWithLink {...buttonProps} />;
  }

  return null;
};
