import Moment from 'moment';

export function formatDate(value: string): string {
  const date = new Date(value);
  const newDate = date.toDateString().split(' ');
  return newDate.slice(1).join(', ').replace(',', '');
}

export function formatDateToSaveinDB(value: string): string {
  return Moment(value, 'MM/DD/YYYY').format('YYYY-MM-DD');
}

export function formatDateToDisplay(value: string): string {
  return Moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY');
}
