import { colors } from '@snap/colors';
import { iconSize, IconProps } from '../constants';

export function IconMattresses({
  iconColor,
  size = 'lg',
  className,
}: IconProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-mattresses"
      className={className}
    >
      <path
        d="M16 25C16 13.9543 24.9543 5 36 5L124 5C135.046 5 144 13.9543 144 25L144 56H16V25Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path d="M16 64V68H144V64H16Z" fill={iconColor ?? colors.SnapDarkBlue} />
      <path
        d="M144 72H16L16 135C16 146.046 24.9543 155 36 155H124C135.046 155 144 146.046 144 135V72Z"
        fill={iconColor ?? colors.SnapDarkBlue}
      />
      <path
        d="M28 23.1783C28 19.7661 30.7661 17 34.1783 17H69.8217C73.2339 17 76 19.7661 76 23.1783V43.8217C76 47.2339 73.2339 50 69.8217 50L34.1783 50C30.7661 50 28 47.2339 28 43.8217V23.1783Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
      <path
        d="M84 23.1783C84 19.7661 86.7661 17 90.1783 17H125.822C129.234 17 132 19.7661 132 23.1783V43.8217C132 47.2339 129.234 50 125.822 50L90.1783 50C86.7661 50 84 47.2339 84 43.8217V23.1783Z"
        fill={iconColor ?? colors.SnapLightBlue}
      />
    </svg>
  );
}
