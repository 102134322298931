import {
  Snackbar,
  SnackbarCloseReason,
  AlertTitle,
  IconButton,
} from '@mui/material';
import { AlertColor } from '@mui/material';
import {
  Report as ReportIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import {
  ReactNode,
  SyntheticEvent,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Button } from '@ee-monorepo/shared/ui/button';
import { CustomAlert } from './app-message-styles';
import { useTranslations } from 'next-intl';

export interface AppMessageContextProps {
  showSuccessMessage: (params: AppMessageParameters) => void;
  showErrorMessage: (params: AppMessageParameters) => void;
  showWarningMessage: (params: AppMessageParameters) => void;
  showInfoMessage: (params: AppMessageParameters) => void;
  closeMessage: () => void;
}

export const AppMessageContext = createContext<AppMessageContextProps>({
  showSuccessMessage: () => {},
  showErrorMessage: () => {},
  showWarningMessage: () => {},
  showInfoMessage: () => {},
  closeMessage: () => {},
});

export function useAppMessage() {
  return useContext(AppMessageContext);
}

interface AppMessageParameters {
  alertHead?: string;
  message: string;
  textLink?: string;
  link?: string;
  showLabel?: boolean;
  autoHideDuration?: number | null;
  customStyle?: object;
  showCloseIcon?: boolean;
  customAlertStyle?: object;
}

export interface AppMessageState {
  open: boolean;
  type: AlertColor;
  alertHead?: string;
  message: string;
  textLink?: string;
  link?: string;
  showLabel?: boolean;
  customStyle?: object;
  autoHideDuration?: number | null;
  showCloseIcon?: boolean;
  customAlertStyle?: object;
}

export function AppMessage({
  className,
  children,
}: {
  children: ReactNode;
  className?: string;
}) {
  const t = useTranslations();
  const [messageState, setMessageState] = useState<AppMessageState>({
    open: false,
    type: 'info',
    alertHead: '',
    message: '',
    textLink: '',
    link: '',
    showLabel: true,
    autoHideDuration: null,
    showCloseIcon: true,
  });

  const closeMessage = () => {
    setMessageState((state: AppMessageState) => ({ ...state, open: false }));
  };

  const handleClose = (e: Event | SyntheticEvent, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessageState((state) => ({ ...state, open: false }));
  };

  const showSuccessMessage = useCallback((params: AppMessageParameters) => {
    setMessageState({
      type: 'success',
      open: true,
      ...params,
    });
  }, []);

  const showErrorMessage = useCallback((params: AppMessageParameters) => {
    setMessageState({
      type: 'error',
      open: true,
      ...params,
    });
  }, []);

  const showWarningMessage = useCallback((params: AppMessageParameters) => {
    setMessageState({
      type: 'warning',
      open: true,
      ...params,
    });
  }, []);

  const showInfoMessage = useCallback((params: AppMessageParameters) => {
    setMessageState({
      type: 'info',
      open: true,
      ...params,
    });
  }, []);

  const action = useMemo(
    () => (
      <>
        {messageState.link ? (
          <Button
            href={messageState.link}
            variant="link"
            size="compact"
            style={{ fontSize: '14px' }}
          >
            <strong>{messageState.textLink}</strong>
          </Button>
        ) : null}

        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </>
    ),
    [messageState]
  );

  const icon = useMemo(
    () =>
      ({
        error: <ReportIcon />,
        success: <CheckCircleIcon />,
        warning: <WarningIcon />,
        info: <InfoIcon />,
      }[messageState.type]),
    [messageState]
  );

  return (
    <AppMessageContext.Provider
      value={{
        showSuccessMessage,
        showErrorMessage,
        showWarningMessage,
        showInfoMessage,
        closeMessage,
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={messageState.autoHideDuration}
        open={messageState.open}
        onClose={handleClose}
        className={className}
        sx={messageState.customStyle}
      >
        <CustomAlert
          icon={icon}
          onClose={messageState.showCloseIcon ? handleClose : undefined}
          severity={messageState.type}
          data-testid="app-message"
          action={messageState.showCloseIcon ? action : null}
          sx={messageState.customAlertStyle}
        >
          {messageState.open && (
            <>
              {messageState.showLabel && (
                <AlertTitle style={{ textTransform: 'capitalize' }}>
                  <strong>
                    {messageState.alertHead ||
                      t(`shared.alertHead.${messageState.type}`)}
                  </strong>
                </AlertTitle>
              )}
              {messageState.message}
            </>
          )}
        </CustomAlert>
      </Snackbar>
    </AppMessageContext.Provider>
  );
}
