import { useCallback, useMemo } from 'react';

export interface ExecuteReturn<D, E> {
  errors: E[]; // read errors inline after calling execute
  data: D;
}

export interface CommandReturn<D, E> extends ExecuteReturn<D, E> {
  success: boolean;
  hasErrors: boolean;
}

export interface CommandHookReturn<P, D, E> {
  execute: (params: P) => Promise<ExecuteReturn<D, E>> | ExecuteReturn<D, E>;
  executing: boolean; // loading status
}

export function createCommandHook<P, D, E>(
  useCommandHook: () => CommandHookReturn<P, D, E>
) {
  return function useCommand() {
    const { execute, executing } = useCommandHook();

    const executeMemo = useCallback(
      async (params: P) => {
        const executeReturn = await execute(params);
        return {
          ...executeReturn,
          success: !executeReturn?.errors?.length,
          hasErrors: executeReturn?.errors?.length > 0,
        } as CommandReturn<D, E>;
      },
      [execute]
    );

    return useMemo(
      () => ({ execute: executeMemo, executing }),
      [executeMemo, executing]
    );
  };
}
