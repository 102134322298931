import { createTheme } from '@mui/material';
import { colors } from '@snap/colors';

export const theme = createTheme({
  components: {
    MuiAlert: {
      styleOverrides: {
        outlinedError: {
          borderColor: colors.SnapError,
          backgroundColor: colors.SnapLightError,
        },
        outlinedInfo: {
          borderColor: colors.SnapNeutral,
          backgroundColor: colors.SnapLightNeutral,
        },
        outlinedSuccess: {
          borderColor: colors.SnapSuccess,
          backgroundColor: colors.SnapLightSuccess,
        },
        outlinedWarning: {
          borderColor: colors.SnapWarning,
          backgroundColor: colors.SnapLightWarning,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            height: 19,
          },
          '& .MuiInputBase-input:hover': {
            backgroundColor: colors.SnapGrey100,
          },
          '& .MuiInputBase-input .MuiFilledInput-input, & .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input':
            {
              padding: '27px 12px 10px',
            },
          '& input::placeholder': {
            opacity: 1,
          },
          '&.MuiFilledInput-input': {
            padding: '27px 15px 10px !important',
          },
          '&.MuiAutocomplete-paper': {
            borderColor: colors.SnapDarkBlue,
          },
          '& .MuiOutlinedInput-input': {
            padding: '25.5px 14px 3.5px !important',
          },
          '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 16px) scale(1)',
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 6px) scale(0.75)',
          },
          '& .MuiOutlinedInput-notchedOutline legend span': {
            display: 'none',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper': {
            maxHeight: '270px',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'filled',
      },
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
          paddingTop: 22,
          paddingLeft: 16,
        },

        icon: {
          '& path': {
            fill: colors.SnapGrey400,
          },
          '&.Mui-disabled path': {
            fill: colors.SnapLavender,
          },
          '&.MuiSelect-iconFilled': {
            right: `1px !important`,
            top: 16,
            marginRight: '16px',
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled',
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expandrite:ed:before': {
            opacity: '1 !important',
          },
          '&.MuiAccordionSummary-content.Mui-expanded': {
            marginTop: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            marginLeft: '16px',
          },
          '&.MuiAccordion-root.Mui-expanded': {
            marginTop: 0,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: colors.SnapGrey500,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.SnapGrey100,
          height: 56,
          '&:hover': {
            backgroundColor: `${colors.SnapGrey100} !important`,
          },
          '&.Mui-focused': {
            backgroundColor: colors.SnapGrey100,
          },
          '&.MuiFilledInput-underline:before': {
            borderBottomColor: colors.SnapMediumBlue,
          },
          '&.MuiFilledInput-underline:hover:before': {
            borderBottom: `2px solid ${colors.SnapMediumBlue} !important`,
          },
          '&.MuiFilledInput-underline:after': {
            borderBottomColor: colors.SnapBlue,
          },
          '& input::placeholder': {
            color: colors.SnapGrey400,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.SnapBlue,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: 'none',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: colors.SnapBlue,
          },
          '&.Mui-disabled': {
            color: colors.SnapLavender,
          },
          // date picker placeholder
          '& + .MuiInputBase-root > input[type="date"]': {
            color: colors.SnapGrey400,
            textTransform: 'uppercase',
            paddingLeft: '15px !important',
          },
          // date picker filled value
          '&.MuiFormLabel-filled + .MuiInputBase-root > input[type="date"]': {
            color: colors.SnapGrey500,
          },
          '&.MuiInputLabel-filled': {
            transform: 'translate(16px, 16px) scale(1)',
          },
          '&.MuiInputLabel-filled.MuiInputLabel-shrink': {
            transform: 'translate(16px, 10px) scale(0.75)',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          '&.Mui-disabled, &.Mui-disabled .MuiFormControlLabel-label': {
            color: `${colors.SnapLavender} !important`,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.SnapGrey500,
          '& .MuiIconButton-label': {
            borderRadius: 4,
            transition: '.4s box-shadow',
          },
          '&.Mui-disabled': {
            color: `${colors.SnapLavender} !important`,
          },
          '&.Mui-checked': {
            color: `${colors.SnapBlue} !important`,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:hover, &.Mui-checked:hover': {
            color: colors.SnapMediumBlue,
            '& .MuiIconButton-label': {
              boxShadow: '0px 0px 0px 1px',
            },
          },
          '&:active, &.Mui-checked:active': {
            color: colors.SnapBlue,
            '& .MuiIconButton-label': {
              boxShadow: '0px 0px 0px 1px',
            },
          },
        },
      },
    },
    MuiTouchRipple: {
      defaultProps: {
        hidden: true,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.SnapGrey500,
          '& .MuiIconButton-label': {
            borderRadius: '50%',
            transition: '.4s box-shadow',
          },
          '&.Mui-disabled': {
            color: `${colors.SnapLavender} !important`,
          },
          '&.Mui-checked': {
            color: `${colors.SnapBlue} !important`,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&:hover, &.Mui-checked:hover': {
            color: colors.SnapMediumBlue,
            '& .MuiIconButton-label': {
              boxShadow: '0px 0px 0px 1px',
            },
          },
          '&:active, &.Mui-checked:active': {
            color: colors.SnapBlue,
            '& .MuiIconButton-label': {
              boxShadow: '0px 0px 0px 1px',
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.SnapWhite,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: colors.SnapGrey400,
          color: colors.SnapGrey400,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          color: colors.SnapGrey500,
          '&.Mui-error': {
            color: colors.SnapGrey500,
          },
          '&.MuiInputLabel-shrink': {
            color: colors.SnapGrey400,
          },
          '&.MuiInputLabel-shrink.Mui-focused': {
            color: colors.SnapBlue,
          },
          '&.MuiInputLabel-outlined.Mui-focused': {
            color: colors.SnapBlue,
          },
          '&.MuiInputLabel-shrink.Mui-error': {
            color: colors.SnapError,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '&.Mui-error': {
            color: colors.SnapError,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&': {
            padding: 0,
            backgroundColor: colors.SnapGrey100,
          },
          '&.MuiFilledInput-underline:before': {
            borderBottomColor: colors.SnapMediumBlue,
          },
          '&.MuiFilledInput-underline:after': {
            borderBottomColor: colors.SnapBlue,
          },
          '&.MuiFilledInput-underline.Mui-error:after': {
            borderBottomColor: colors.SnapError,
            borderBottomWidth: 2,
          },
          '&.MuiFilledInput-underline.Mui-disabled:after, &.MuiFilledInput-underline.Mui-disabled:before':
            {
              borderBottomColor: colors.SnapLavender,
              borderBottomStyle: 'solid',
            },
          '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
            {
              borderColor: colors.SnapError,
            },
          '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
            {
              borderColor: colors.SnapLavender,
            },
          '& .error-icon': {
            color: colors.SnapError,
          },
          '&.Mui-disabled, &.MuiFilledInput-root.Mui-disabled': {
            backgroundColor: colors.SnapGrey100,
          },
          '&.MuiAutocomplete-inputRoot': {
            padding: '0 !important',
          },
          '& input[type="date"]::-webkit-calendar-picker-indicator': {
            transform: `translateY(-8px)`,
            height: 20,
            width: 20,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.SnapGreen,
    },
    secondary: {
      main: colors.SnapGreen,
      contrastText: colors.SnapGreen,
    },
    text: {
      primary: colors.SnapGrey500,
    },
    info: {
      main: colors.SnapGrey400,
    },
  },
  typography: {
    fontFamily: ['Aeonik', 'sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 991,
      lg: 1199,
      xl: 1439,
    },
  },
});
