import { createContext, ReactNode, useContext, useState } from 'react';

export const ModernNavBarContext = createContext<
  [boolean, (enabled: boolean) => void]
>([false, () => {}]);

export const ModernNavBarProvider = ({ children }: { children: ReactNode }) => {
  const [modernNavBarEnabled, setModernNavBarEnabled] = useState(false);
  return (
    <ModernNavBarContext.Provider
      value={[modernNavBarEnabled, setModernNavBarEnabled]}
    >
      {children}
    </ModernNavBarContext.Provider>
  );
};

export const useModernNavBarContext = () => {
  const [modernNavBarEnabled, setModernNavBarEnabled] =
    useContext(ModernNavBarContext);
  return {
    modernNavBarEnabled,
    setModernNavBarEnabled,
  };
};
