import { FieldControl } from '@ee-monorepo/shared/ui/field-control';
import { useForm } from 'react-hook-form';
import { VerifyFormSchemaData, formSchema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedTextField } from '@ee-monorepo/shared/ui/text-field';
import { Button } from '@ee-monorepo/shared/ui/button';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { useVerifyDobSsnCommand } from '../../commands/useVerifyDobSsnCommand';
import { ErrorType } from '../../commands/types';
import { useAuth } from '../auth-provider/auth-provider';
import {
  useHandHeld,
  useFormFieldsKeyPress,
} from '@ee-monorepo/shared/utilities/hooks';
import dayjs from 'dayjs';
import router from 'next/router';
import { useAppMessage } from '@ee-monorepo/shared/ui/app-message';
import { useFlowContext } from '@ee-monorepo/shared/context/flows';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';

export function VerifyForm() {
  const t = useTranslations('account_takeover_form');
  const { showErrorMessage, closeMessage } = useAppMessage();
  const dobSsnVerify = useVerifyDobSsnCommand();
  const { user, setUserData } = useAuth();
  const isMobile = useHandHeld();
  const [pageLoading, setPageLoading] = useState(false);
  const [apiErrorOnSubmit, setApiErrorOnSubmit] = useState(false);
  const onKeyPressed = () => {
    setApiErrorOnSubmit(false);
  };
  const { formRef } = useFormFieldsKeyPress(onKeyPressed);

  const { next } = useFlowContext();
  const { tracking } = useTrackingContext();

  const logSubmitEvent = () => {
    tracking?.logClickEvent({
      click_type: 'cta',
      placement: 'page',
      click_value: 'confirm',
    });
  };

  const handleSuccess = (resultType: 'EMAIL' | 'PHONE') => {
    setApiErrorOnSubmit(false);
    setUserData({ ...user, type: resultType });
    next({ type: resultType });
  };

  const handleIncorrectDataError = () => {
    setApiErrorOnSubmit(true);
    showErrorMessage({
      message: t('try_again'),
      showLabel: false,
      autoHideDuration: 30000,
      customStyle: { top: '2rem', width: '100%' },
      customAlertStyle: { width: isMobile ? '382px' : '528px', height: '48px' },
      showCloseIcon: false,
    });
  };

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm<VerifyFormSchemaData>({
    defaultValues: {
      birthdate: user.birthdate?.toString() || '',
      ssn: user.ssn || '',
    },
    mode: 'all',
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    closeMessage();
  }, [watch('birthdate'), watch('ssn')]);

  const onSubmitHandler = async (data: VerifyFormSchemaData) => {
    logSubmitEvent();
    setUserData({ ...user, birthdate: data.birthdate, ssn: data.ssn });
    const response = await dobSsnVerify.execute({
      token: user.token,
      dob: dayjs(data.birthdate).format('YYYY-MM-DD'),
      ssn: data.ssn.replaceAll('-', ''),
    });
    if (response.success) {
      handleSuccess(response.data['result']);
    } else if (response.errors.includes(ErrorType.INCORRECT_DATA)) {
      handleIncorrectDataError();
    } else {
      //all other errors, redirecting to the error page
      closeMessage();
      next({ type: 'NULL' });
    }
    router.events.on('routeChangeStart', () => setPageLoading(true));
    router.events.on('routeChangeComplete', () => setPageLoading(false));
  };

  return (
    <>
      <h3>{t('identityTitle')}</h3>
      <p className="my-2">{t('identitySubtitle')}</p>
      <form
        className="mt-10"
        ref={formRef}
        data-testid="verify-form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="grid grid-cols-1">
          <div>
            <FieldControl
              fieldName="birthdate"
              control={control}
              render={(field, invalid) => (
                <FormattedTextField
                  {...field}
                  fullWidth
                  label={t('dob')}
                  error={invalid}
                  data-testid="birthdate"
                  mask="00/00/0000"
                  maskedValue={true}
                  isPassword={true}
                />
              )}
            />
          </div>
          <div className="-mt-1">
            <FieldControl
              fieldName="ssn"
              control={control}
              render={(field, invalid) => (
                <FormattedTextField
                  {...field}
                  fullWidth
                  label={t('ssn')}
                  error={invalid}
                  data-testid="ssn"
                  mask="000-00-0000"
                  isPassword={true}
                  maskedValue={true}
                />
              )}
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-end mt-10">
          <Button
            loading={dobSsnVerify.executing || pageLoading}
            disabled={
              !isValid ||
              dobSsnVerify.executing ||
              pageLoading ||
              apiErrorOnSubmit
            }
            type="submit"
            data-testid="verify-identity"
          >
            {t('continue')}
          </Button>
        </div>
      </form>
    </>
  );
}

export default VerifyForm;
