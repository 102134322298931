import { IconMenuProps } from '../constants';

export function IconLock({ iconColor, className }: IconMenuProps) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill={iconColor}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 4.66667H9.33268V3.33333C9.33268 1.49333 7.83935 0 5.99935 0C4.15935 0 2.66602 1.49333 2.66602 3.33333V4.66667H1.99935C1.26602 4.66667 0.666016 5.26667 0.666016 6V12.6667C0.666016 13.4 1.26602 14 1.99935 14H9.99935C10.7327 14 11.3327 13.4 11.3327 12.6667V6C11.3327 5.26667 10.7327 4.66667 9.99935 4.66667ZM3.99935 3.33333C3.99935 2.22667 4.89268 1.33333 5.99935 1.33333C7.10602 1.33333 7.99935 2.22667 7.99935 3.33333V4.66667H3.99935V3.33333ZM9.99935 12.6667H1.99935V6H9.99935V12.6667ZM5.99935 10.6667C6.73268 10.6667 7.33268 10.0667 7.33268 9.33333C7.33268 8.6 6.73268 8 5.99935 8C5.26602 8 4.66602 8.6 4.66602 9.33333C4.66602 10.0667 5.26602 10.6667 5.99935 10.6667Z"
        fill={iconColor}
      />
    </svg>
  );
}
