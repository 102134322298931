import React from 'react';
import { Element } from '@prismicio/helpers';
import s from './rich-text-serializer.module.scss';
import { wordify } from '@ee-monorepo/shared/utilities/functions';
import { richTextSerializer } from './rich-text-serializer';
import { JSXFunctionSerializer } from '@prismicio/react';
import { RTTextNodeBase } from '@prismicio/types';

// -- Function to add unique key to props
const propsWithUniqueKey = (props = {}, key: string) => {
  return { ...props, key };
};

// Default rendering for prismic rich text field
export const richTextSerializerHeading1Override: JSXFunctionSerializer =
  function (type, element, content, children, key) {
    let props = {};
    const parsedElement = element as RTTextNodeBase;
    switch (type) {
      case Element.heading1: {
        props = {
          id: wordify(parsedElement.text),
          className: 'headingL md:headingXL',
        };
        return React.createElement(
          'h2',
          propsWithUniqueKey(props, key),
          children
        );
      }
      default: {
        return richTextSerializer(type, element, content, children, key);
      }
    }
  };
