import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuFurniture({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-furniture"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0123 0.20105H16.3873V2.88963H16.0498C14.6414 2.88963 13.4998 4.03386 13.4998 5.44534C13.4998 6.84614 14.6242 7.98373 16.0179 8.00085H3.99976C2.3429 8.00085 0.999756 9.344 0.999756 11.0009V13.9999H22.9998V8.00085H19.3817C20.7753 7.98373 21.8998 6.84614 21.8998 5.44534C21.8998 4.03386 20.7581 2.88963 19.3497 2.88963H19.0123V0.20105ZM13.4998 10.9999C13.4998 11.8283 12.8282 12.4999 11.9998 12.4999C11.1713 12.4999 10.4998 11.8283 10.4998 10.9999C10.4998 10.1715 11.1713 9.49988 11.9998 9.49988C12.8282 9.49988 13.4998 10.1715 13.4998 10.9999Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9998 14.9999H0.999756V21.0009H2.99976V24.0009H4.7439L5.99976 21.0009H17.9998L19.2555 24.0009H20.9998V21.0009H22.9998V14.9999ZM13.4998 17.9999C13.4998 18.8283 12.8282 19.4999 11.9998 19.4999C11.1713 19.4999 10.4998 18.8283 10.4998 17.9999C10.4998 17.1715 11.1713 16.4999 11.9998 16.4999C12.8282 16.4999 13.4998 17.1715 13.4998 17.9999Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
