import * as yup from 'yup';

const REQUIRED = 'shared.forms.validations.required';
const INVALID_EMAIL = 'shared.forms.validations.email';

export interface ChangeEmailFormSchemaData {
    email: string;
}

export const formSchema: yup.SchemaOf<ChangeEmailFormSchemaData> = yup.object().shape({
    email: yup.string().required(REQUIRED).email(INVALID_EMAIL)
});

export const VerifyEmailFormSchema = yup.object().shape({
    email: yup.string().required(REQUIRED).email(INVALID_EMAIL),
    }) as yup.SchemaOf<VerifyEmailSchemaData>;

  export interface VerifyEmailSchemaData {
    email?: string | null;
  }
  




