import { iconSize, IconMenuProps } from '../constants';
import { colors } from '@snap/colors';

export function IconMenuPowerTools({
  iconColor,
  className,
  size = 'xs',
}: IconMenuProps) {
  return (
    <svg
      width={iconSize[size]}
      height={iconSize[size]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid="icon-menu-power-tools"
    >
      <path
        d="M11 17H23V19.2373C23 20.2108 22.1046 21 21 21H13C11.8954 21 11 20.2108 11 19.2373V17Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M8 3H21.0102C22.1092 3 23 3.8857 23 4.97826V8.02174C23 9.1143 22.1092 10 21.0102 10H8V3Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6H6V7H1.5L1 6Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M4 5.30588C4 4.32947 4.72022 3.4991 5.69589 3.35062L8 3V10L5.69588 9.64938C4.72022 9.5009 4 8.67053 4 7.69412V5.30588Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 3V10H19V3H21Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M18 11H12V11.7327C12 12.4326 12.5808 13 13.2973 13H16.7027C17.4191 13 18 12.4326 18 11.7327V11Z"
        fill={iconColor ?? colors.SnapGreen}
      />
      <path
        d="M19 11H14L15.704 15.4006C15.9853 16.174 15.433 17 14.6344 17H14V20H21L21 17L19 11Z"
        fill={iconColor ?? colors.SnapGreen}
      />
    </svg>
  );
}
